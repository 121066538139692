import React, { useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaComments, FaFile } from "react-icons/fa";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BiSolidFileExport } from "react-icons/bi";
import {
  Box,
  CssBaseline,
  Typography,
  TextField,
  Tooltip,
  Modal,
  Button,
} from "@mui/material";
import "../Tickets.css";
import ViewModal from "./ViewModal";
import Cookies from "js-cookie";

export default function UnsolvedTicket() {
  const userInfo = JSON.parse(Cookies.get("backendinfo"));
  const { name, role } = userInfo;
  // console.log(name, role, "name role")
  const pdfRef = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [backendDepartmentData, setBackendDepartmentData] = useState([]);
  const [backendAssigneeData, setBackendAssigneeData] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState({});

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null); // State to store selected row data
  const [refresh, setRefresh] = useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const handleSelectionModelChange = (newSelection) => {
    setSelectedRows(newSelection);
  };

  const handleTrigger = () => {
    setIsOpenModal(!isOpenModal);
  };
  const handleRowClick = (params) => {
    setSelectedRow(params.row); // Store the selected row data
    handleTrigger(); // Open the modal
  };

  useEffect(() => {
    // Fetch ticket data
    axios
      .post(`https://vliv.app/ticket/get`)
      .then((res) => {
        // to get only open and inprogress data
        const data = res.data.filter((elem) => {
          return elem.status != "Closed";
        });
        // console.log(data, "get non closed data");
        // To get data for admin different and for user different
        if (role == "admin") {
          setData(data);
        } else if (role == "user") {
          const filteredData = data.filter((elem) => {
            return elem.backendassignee == name;
          });
          setData(filteredData);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));

    // Fetch backend user data
    axios
      .post(`https://vliv.app/backend/get`)
      .then((res) => {
        const backendData = res.data;

        // Extract unique departments
        const uniqueDepartments = [
          ...new Set(backendData.map((item) => item.department)),
        ];

        // Create a map of departments to users
        const departmentToUsersMap = uniqueDepartments.reduce(
          (acc, department) => {
            acc[department] = backendData.filter(
              (user) => user.department === department
            );
            return acc;
          },
          {}
        );

        setBackendDepartmentData(uniqueDepartments);
        setBackendAssigneeData(departmentToUsersMap);
      })
      .catch((err) => console.log(err));
  }, [refresh]);

  // Handle department change for a specific row
  const handleDepartmentChange = (id, department) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.id === id
          ? { ...item, backenddepartment: department, backendassignee: "" }
          : item
      )
    );
    setSelectedDepartment((prevState) => ({
      ...prevState,
      [id]: department,
    }));

    // for department API call

    axios
      .post(`https://vliv.app/ticket/update-depart`, { id, department })
      .then((res) => {
        // alert(res.data.message);
        setRefresh(!refresh);
      })
      .catch((err) => console.log(err));
  };

  // Handle assignee change for a specific row
  const handleAssigneeChange = (id, assignee) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, backendassignee: assignee } : item
      )
    );

    // for assignee API call
    axios
      .post(`https://vliv.app/ticket/update-assignee`, { id, assignee })
      .then((res) => {
        // alert(res.data.message);
        setRefresh(!refresh);
      })
      .catch((err) => console.log(err));
  };

  // To update status

  const handleStatusChange = (id, status) => {
    // for assignee API call
    axios
      .post(`https://vliv.app/ticket/update-status`, { id, status })
      .then((res) => {
        // alert(res.data.message);
        setRefresh(!refresh);

        // console.log(res.data, "status response");

      })
      .catch((err) => console.log(err));
  };

  const columns = [
    { field: "id", headerName: "Ticket ID", flex: 1 },
    { field: "date", headerName: "Date", flex: 1 },
    { field: "company", headerName: "Organization Name", flex: 1 },
    {
      field: "priority",
      headerName: "Priority",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            color:
              params.value === "High"
                ? "red"
                : params.value === "Low"
                ? "green"
                : "orange",
          }}
        >
          {params.value}
        </div>
      ),
    },
    { field: "subject", headerName: "Subject", flex: 1 },
    {
      field: "attachment",
      headerName: "Attachment",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title="View Attachment" onClick={()=>handleTrigger}>
         
            <FaFile style={{ color: "#007bff", cursor: "pointer" }} />
          
        </Tooltip>
      ),
    },
    {
      field: "backenddepartment",
      headerName: "Department",
      flex: 1,
      renderCell: (params) => {
        return (
          <select
            value={params.row.backenddepartment || ""}
            onClick={(e) => e.stopPropagation()} // Stop event propagation
            onChange={(e) =>
              handleDepartmentChange(params.row.id, e.target.value)
            }
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
            disabled={role == "user"}
          >
            <option value="">Select Department</option>
            {backendDepartmentData.map((dept, index) => (
              <option key={index} value={dept}>
                {dept}
              </option>
            ))}
          </select>
        );
      },
    },
    {
      field: "backendassignee",
      headerName: "Assign To",
      flex: 1,
      renderCell: (params) => {
        const currentDepartment =
          selectedDepartment[params.row.id] || params.row.backenddepartment;
        const assignees = backendAssigneeData[currentDepartment] || [];

        return (
          <select
            value={params.row.backendassignee || ""}
            onClick={(e) => e.stopPropagation()} // Stop event propagation
            onChange={(e) =>
              handleAssigneeChange(params.row.id, e.target.value)
            }
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
            disabled={!currentDepartment || role == "user"}
          >
            <option value="">Select Assignee</option>
            {assignees.map((user, index) => (
              <option key={index} value={user.name}>
                {user.name}
              </option>
            ))}
          </select>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <select
            value={params.row.status || ""}
            onClick={(e) => e.stopPropagation()} // Stop event propagation
            onChange={(e) => handleStatusChange(params.row.id, e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              color:
                params.row.status === "Closed"
                  ? "red"
                  : params.row.status === "Inprogress"
                  ? "green"
                  : "orange",
            }}
          >
            <option value="">Select</option>
            <option value="Open">Open</option>
            <option value="Inprogress">Inprogress</option>
            <option value="Closed">Closed</option>
          </select>
        );
      },
    },
  ];

  const filterData = () => {
    return data.filter((item) =>
      searchValue
        ? Object.values(item).some((value) =>
            value
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchValue.toLowerCase().trim())
          )
        : true
    );
  };

  const rows = filterData();

    // Handle Solve button click to update status of selected rows to Closed
    const handleSolveClick = () => {
      // console.log(selectedRows, "selectedRows")
      if (selectedRows.length > 0) {
        axios
          .post(`https://vliv.app/ticket/update-status`, {
            id: selectedRows,
            status: "Closed",
          })
          .then(() => setRefresh(!refresh))
          .catch((err) => console.log(err));
      } else {
        alert("Please select at least one row.");
      }
    };

 
    // console.log(data, "ticket data")

  return (
    <>
      <CssBaseline />
      <Box maxWidth="xl">
        <Typography variant="h6" gutterBottom></Typography>
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}
        >
          <TextField
            label="Search"
            variant="outlined"
            size="medium"
            sx={{ marginBottom: 2, width: 400 }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          {/* <DownloadTableExcel
            filename="Ticket"
            sheet="Ticket"
            currentTableRef={pdfRef.current}
          >
            <BiSolidFileExport
              style={{
                marginTop: "3px",
                width: "30px",
                height: "30px",
                cursor: "pointer",
                color: "#062C41",
              }}
            />
          </DownloadTableExcel> */}
          <Button
            sx={{
              border: "solid 1px grey",
              background: "#37668f",
              color: "#fff",
              width: "100px",
              height:"43px",
              borderRadius: "5px",
            }}
            onClick={handleSolveClick}
          >
            Solve
          </Button>
        </div>
        <div ref={pdfRef}>
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            onRowClick={handleRowClick}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(newSelection) => {
              handleSelectionModelChange(newSelection);
            }}
          />
        </div>
      </Box>

      <Modal
        open={isOpenModal}
        onClose={handleTrigger}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 200,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: "100vh",
            height: "90vh",
          }}
        >
          <ViewModal handleTrigger={handleTrigger} rowData={selectedRow} />
        </Box>
      </Modal>
    </>
  );
}
