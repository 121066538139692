import React, { useState } from "react";
import SocialMediaDrawer from "./SocialMediaDrawer";
import { Button } from "@mui/material";

function SocialMedia() {
  const [openChatDrawer, setOpenChatDrawer] = useState(false);

  const handleOpenChatDrawer = (rowData) => {
    setOpenChatDrawer(true);

  };

  return (
   <>
   <Button onClick={handleOpenChatDrawer}> Social</Button>
    <SocialMediaDrawer
      open={openChatDrawer}
      handleClose={() => setOpenChatDrawer(false)}
    />
    </>
  );
}

export default SocialMedia;
