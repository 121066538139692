import { useEffect, useRef, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Drawer,
  Grid,
  TextField,
  Button,
  Card,
  Skeleton,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import SendIcon from "@mui/icons-material/Send";
import Comment from "./Comment";
import { AiFillLike, AiOutlineLike } from "react-icons/ai";
import { FaRegCommentDots } from "react-icons/fa";
import { CiFileOn } from "react-icons/ci";
import { CiImageOn } from "react-icons/ci";
import { GrUploadOption } from "react-icons/gr";
import { MdCancel } from "react-icons/md";
import ReactPlayer from "react-player";

const SocialMediaDrawer = ({ open, handleClose }) => {
  // For image upload input ref
  const inputRef = useRef(null);
  const commentInputRef = useRef(null);

  const companyInfoCookie = Cookies.get("userinfo");
  const companyInfo = companyInfoCookie ? JSON.parse(companyInfoCookie) : {};
  const companyid = companyInfo.clientemail;
  const company = companyInfo.company;
  const userid = companyInfo.userid;
  const id = companyInfo.companyid;
  const companyEmail = companyInfo.clientemail;
  // console.log(id, "companyInfo");

  const [postData, setPostData] = useState([
    // {
    //   id: 1,
    //   caption: "Hi",
    //   media:
    //     "https://images.thequint.com/thequint%2F2023-11%2F021d3e01-ee28-4b0e-ac36-7367c8c6acae%2Fshah_rukh_khan_srk_file_pti.jpg?rect=0%2C0%2C760%2C428",
    //   postby: "user",
    //   date: "04-05-2024",
    //   time: "4pm",
    //   like: 4,
    //   comment: [
    //     {
    //       user: "salman",
    //       date: "04-05-2024",
    //       time: "5pm",
    //       text: "Superb pic sahrukh bhai",
    //     },
    //     {
    //       user: "salman",
    //       date: "04-05-2024",
    //       time: "5pm",
    //       text: "aag laga dala aag🔥🔥",
    //     },
    //   ],
    //   user: "sahrukh khan",
    //   userid: "01",
    //   company: "google",
    //   companyid: "cm1",
    // },
    // {
    //   id: 2,
    //   caption: "Hi",
    //   media:
    //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-vsIRYl8HRJ_hA7NU4bF_9de2FEwb1tbQB2NyGNYrXg&s",
    //   postby: "user",
    //   date: "04-05-2024",
    //   time: "4pm",
    //   like: 4,
    //   comment: [
    //     {
    //       user: "salman",
    //       date: "04-05-2024",
    //       time: "5pm",
    //       text: "Wow ek dum kadak",
    //     },
    //   ],
    //   user: "sahrukh khan",
    //   userid: "01",
    //   company: "google",
    //   companyid: "cm1",
    // },
    // {
    //   id: 3,
    //   caption: "Hi",
    //   media: "https://cdn.siasat.com/wp-content/uploads/2023/05/srk-1.jpg",
    //   postby: "user",
    //   date: "04-05-2024",
    //   time: "4pm",
    //   like: 4,
    //   comment: [
    //     {
    //       user: "salman",
    //       date: "04-05-2024",
    //       time: "5pm",
    //       text: "King khan 💪",
    //     },
    //   ],
    //   user: "sahrukh khan",
    //   userid: "01",
    //   company: "google",
    //   companyid: "cm1",
    // },
  ]);
  const [data, setData] = useState({
    caption: "",
    media: "",
    postby: userid,
    date: new Date().toLocaleDateString(),
    time: new Date().toLocaleTimeString(),
    like: [],
    comment: [],
    user: userid,
    userid: userid,
    company: company,
    companyid: companyid,
  });

  // To open comment input

  const [openComment, setOpenComment] = useState(false);
  const [currentPostId, setCurrentPostId] = useState(null);
  const [newCommentText, setNewCommentText] = useState("");
  const [referesh, setReferesh] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [filePreviewData, setFilePreviewData] = useState("");
  const [loading, setLoading] = useState(true); // State for loading indicator
  const [previewLoading, setPreviewLoading] = useState(true);
  // "@" user suggestion usestate start
  const [allUsers, setAllUsers] = useState([]); // Replace with your actual list of users
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  // "@" user suggestion usestate end
  // console.log(allUsers, "allUsers")
  // Get data
  // useEffect(() => {
  //   axios
  //     .post(`https://vliv.app/social/get`, { companyid: companyid })
  //     .then((res) => {
  //       setPostData(res.data);
  //       setLoading(false); // Set loading to false after data is fetched

  //       // console.log(res.data, "post data");
  //     })
  //     .catch((err) => console.log(err));
  // }, [newCommentText, referesh]);

  // for comment enter click add start
  useEffect(() => {
    // Get All user api
    // axios.post(`https://vliv.app/user/username`, { companyid: id, companyemail: companyEmail })
    //   .then((res) => {
    //     // console.log(res.data.data, "get all user data")
    //     setAllUsers(res.data.data)
    //   })
    //   .catch((err) => console.log(err))


    const handleKeyPress = (event) => {
      // Check if the pressed key is the Enter key
      if (event.key === "Enter") {
        // Add the comment
        // console.log(commentInputRef.current.value, "enter button add check")
        const commentData = {
          user: userid, // Assuming the user's name
          date: new Date().toLocaleDateString(),
          time: new Date().toLocaleTimeString(),
          text: commentInputRef.current.value,
        };
        axios
          .post(`https://vliv.app/social/comment`, {
            id: currentPostId,
            comment: commentData,
          })
          .then((res) => {
            // console.log(res, "comment data response");
            setNewCommentText("");
          })
          .catch((err) => console.log(err));
        // setPostData(commentData);
        // console.log(commentData, "comment")
      }
    };
    // for comment enter click add end

    // Attach the event listener to the input field
    if (commentInputRef.current) {
      commentInputRef.current.addEventListener("keypress", handleKeyPress);
    }

    // Cleanup: Remove the event listener when component unmounts
    return () => {
      if (commentInputRef.current) {
        commentInputRef.current.removeEventListener("keypress", handleKeyPress);
      }
    };



  }, [currentPostId]);

  const handleChange = (e) => {
    const value = e.target.value;
    // setSearchTerm(value);
    if (value.startsWith("@")) {
      const searchTermWithoutAt = value.substring(1);
      const filteredUsers = allUsers.length > 0 && allUsers.filter((user) =>
        user.toLowerCase().includes(searchTermWithoutAt.toLowerCase())
      );
      setSuggestions(filteredUsers);
    } else {
      setSuggestions([]);
    }

    setData({
      ...data,
      [e.target.name]: e.target.value, // Update the corresponding field in data state
    });
  };
  // for suggetion click to store that suggestion
  const handleSuggestionClick = (username) => {
    // setSearchTerm(`@${username}`);

    setData({
      ...data,
      caption: `@${username}`, // Update the corresponding field in data state
    });
    setSuggestions([]);
  };
  // console.log(data, "data ");

  const handleUploadPost = () => {
    if (data.caption == "") {
      return alert("Please write something");
    }

    axios
      .post(`https://vliv.app/social/add`, data)
      .then((res) => {
        // console.log(res.data, "uplaod post response");
        setData({
          caption: "",
          media: "",
          postby: userid,
          date: new Date().toLocaleDateString(),
          time: new Date().toLocaleTimeString(),
          like: [],
          comment: [],
          user: userid,
          userid: userid,
          company: company,
          companyid: companyid,
        });
        setReferesh(!referesh);
        setShowPreview(false);
      })
      .catch((err) => console.log(err));
  };

  const handleComment = (id) => {
    setCurrentPostId(id);
    setOpenComment(true); // Toggle the openComment state
  };

  const handleCloseComment = () => {
    setOpenComment(!openComment); // Toggle the openComment state
    setCurrentPostId(null);
    setNewCommentText("");
  };

  const handleAddComment = (id) => {
    if (newCommentText.trim() !== "") {
      const commentData = {
        user: userid, // Assuming the user's name
        date: new Date().toLocaleDateString(),
        time: new Date().toLocaleTimeString(),
        text: newCommentText,
      };
      axios
        .post(`https://vliv.app/social/comment`, {
          id,
          comment: commentData,
        })
        .then((res) => {
          // console.log(res, "comment data response");
          setNewCommentText("");
        })
        .catch((err) => console.log(err));
      // setPostData(commentData);
      // console.log(commentData, "comment")
    }
  };

  // For likes
  const handleLike = (id) => {
    axios
      .post(`https://vliv.app/social/like`, { id, userid })
      .then((res) => {
        // console.log(res.data, "likes");
        setReferesh(!referesh);
      })
      .catch((err) => console.log(err));
  };

  // For Image upload
  const handleUploadImg = (event) => {
    const formData = new FormData();
    formData.append("image", event.target.files[0]); // Assuming input type is file and accepts only one image

    axios
      .post("https://vliv.app/social/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        // console.log(res.data, "image upload");
        // Now you can update your data state with the uploaded image URL
        setData({
          ...data,
          media: res.data.file, // Assuming your API returns the URL of the uploaded image
        });
        setShowPreview(true);
        setFilePreviewData(res.data.file);
        setPreviewLoading(false);
      })
      .catch((err) => console.log(err));
  };

  // For close preview
  const handleClosePreview = () => {
    setFilePreviewData("");
    setShowPreview(false);
    setData({
      ...data,
      media: "",
    });
  };
  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <Container maxWidth="sm">
        {/* <Button onClick={handleClose}>Close</Button> */}
        <MdCancel
          onClick={handleClose}
          style={{ marginLeft: "-20px", width: "20px", height: "20px" }}
        />
        <Typography variant="h6" gutterBottom>
          Vliv Communication
        </Typography>{" "}
        {/* Adjusted Container width */}
        <Box sx={{ textAlign: "center", padding: "10px" }}>
          <Box>
            <Box sx={{ display: "flex", gap: "5px" }}>
              {/* <TextField
                label="Post"
                variant="outlined"
                fullWidth
                multiline
                name="caption"
                value={data.caption}
                onChange={handleChange}
                // sx={{ height:"20px" }}
              /> */}
              <TextField
                label="Post"
                variant="outlined"
                fullWidth
                multiline
                name="caption"
                value={data.caption}
                onChange={handleChange}
                onFocus={() => setInputFocused(true)}
                onBlur={() => setInputFocused(false)}
                size={inputFocused ? "medium" : "small"}
              />

              <Typography
                sx={{ textAlign: "center", marginTop: "10px" }}
                onClick={handleUploadPost}
              >
                <GrUploadOption style={{ width: "25px", height: "25px" }} />
              </Typography>

              <Box>
                {suggestions.length > 0 && (
                  <ul>
                    {suggestions.map((username, index) => (
                      <p
                        key={index}
                        onClick={() => handleSuggestionClick(username)}
                        style={{ cursor: "pointer", background: "green", width: "50px", height: "20px", border: "solid 1px", color: "white" }}
                      >
                        {username}
                      </p>
                    ))}
                  </ul>
                )}
              </Box>


            </Box>

            {showPreview && (
              <Box
                sx={{
                  display: "flex",
                  width: "600px",
                  height: "200px",
                  padding: "15px",
                }}
              >
                {filePreviewData && filePreviewData.endsWith(".mp4") ? (
                  <ReactPlayer
                    controls
                    url={filePreviewData}
                    style={{
                      maxWidth: "400px",
                      maxHeight: "200px",
                      border: "dotted 1px",
                      boxShadow:
                        "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                      borderRadius: "5px",
                    }}
                  />
                ) : (
                  <img
                    src={filePreviewData}
                    style={{
                      maxWidth: "100%",
                      height: "200px",
                      border: "dotted 1px",
                      boxShadow:
                        "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                      borderRadius: "5px",
                    }}
                  />
                )}
                {/* 
                <img
                  src={filePreviewData}
                  style={{
                    maxWidth: "100%",
                    height: "200px",
                    border: "dotted 1px",
                    boxShadow:
                      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                    borderRadius: "5px",
                  }}
                /> */}
                <MdCancel onClick={handleClosePreview} />
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                justifyContent: "flex-end",
                marginRight: "25px",
              }}
            >
              <Card sx={{ width: "25px", height: "25px" }}>
                {/* <CiImageOn style={{ width: "25px", height: "25px", cursor:"pointer" }} onClick={handleUploadImg} /> */}

                <input
                  type="file"
                  accept="image/*,video/*"
                  onChange={handleUploadImg}
                  style={{ display: "none" }}
                  ref={inputRef} // Ref for accessing the input element
                />
                <CiImageOn
                  style={{ width: "25px", height: "25px", cursor: "pointer" }}
                  onClick={() => inputRef.current.click()} // Clicking the hidden input element
                />
              </Card>
              {/* <Card sx={{ width: "25px", height: "25px" }}>
                <CiFileOn
                  style={{ width: "25px", height: "25px", cursor: "pointer" }}
                />
              </Card> */}
            </Box>
          </Box>
          <hr></hr>
          <Card
            sx={{
              // border: "solid 1px",
              marginTop: "1rem",
              overflowY: "scroll",
              maxHeight: "calc(100vh - 200px)", // Adjusted height for scroll
            }}
          >
            {loading ? ( // Display skeleton loading UI when loading is true
              // Skeleton loading for each post
              <>
                <Skeleton animation="wave" height={100} width={500} />
                <Skeleton animation="wave" height={100} width={500} />
                <Skeleton animation="wave" height={100} width={500} />
                <Skeleton animation="wave" height={100} width={500} />
                <Skeleton animation="wave" height={100} width={500} />
                <Skeleton animation="wave" height={100} width={500} />
              </>
            ) : (
              postData.map((item, index) => (
                <Box mt={2} key={index}>
                  <Box
                    sx={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <Card
                        sx={{
                          width: "50px",
                          height: "50px",
                        }}
                      >
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src="https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                        />
                      </Card>
                      <Typography
                        key={index}
                        sx={{
                          marginBottom: 1,
                          paddingBottom: 1,
                          textAlign: "start",
                        }}
                      >
                        <u style={{ fontSize: "12px" }}>
                          <b>{item.user}</b>,<span>{item.date}</span>,
                          <span>{item.time}</span>
                        </u>
                      </Typography>
                    </Box>
                    <Typography variant="h6" gutterBottom>
                      {item.caption}
                    </Typography>

                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item xs={12}>
                        {item.media && item.media.endsWith(".mp4") ? (
                          <ReactPlayer
                            controls
                            url={item.media}
                            style={{ maxWidth: "100%", height: "auto" }}
                          />
                        ) : (
                          <img
                            src={item.media}
                            style={{ maxWidth: "100%", height: "auto" }}
                          />
                        )}
                        {/* <img
                        src={item.media}
                        // alt="SRK"
                        style={{ maxWidth: "100%", height: "auto" }}
                      /> */}
                        {/* <ReactPlayer controls url={item.media} /> */}
                      </Grid>
                    </Grid>

                    <Box
                      container
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "50px",
                        //
                      }}
                    >
                      <Box item xs={12}>
                        <p
                          onClick={() => handleLike(item.id)}
                          style={{ cursor: "pointer" }}
                        >
                          {item.like.includes(userid) ? (
                            <AiFillLike />
                          ) : (
                            <AiOutlineLike />
                          )}

                          {item.like.length}
                        </p>
                      </Box>
                      <Box
                        item
                        xs={12}
                        onClick={() => handleComment(item.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <p>
                          <FaRegCommentDots />
                          {item.comment.length}
                        </p>
                      </Box>
                    </Box>
                  </Box>
                  {/* Render comment section only for the selected post */}
                  <Box sx={{ padding: "0 40px 0 40px" }}>
                    {openComment && currentPostId === item.id && (
                      <Box
                        mt={2}
                        sx={{
                          border: "solid 1px",
                          borderRadius: "10px",
                          padding: "17px",

                          boxShadow:
                            "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                        }}
                      >
                        {item.comment.map((cmnt, index) => (
                          <Typography
                            key={index}
                            sx={{
                              marginBottom: 1,
                              borderBottom: "1px solid #ccc",
                              paddingBottom: 1,
                              textAlign: "start",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                              padding: "9px",
                            }}
                          >
                            <u style={{ fontSize: "12px" }}>
                              <b>{cmnt.user}</b>,<span>{cmnt.date}</span>,
                              <span>{cmnt.time}</span>
                            </u>

                            <p>{cmnt.text}</p>
                          </Typography>
                        ))}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "5px",
                            alignItems: "center",
                          }}
                        >
                          <TextField
                            label="Add a comment"
                            variant="outlined"
                            size="small" // Make the input field smaller
                            // multiline
                            value={newCommentText}
                            onChange={(e) => setNewCommentText(e.target.value)}
                            inputRef={commentInputRef} // Add the ref to the input field
                          />
                          <SendIcon onClick={() => handleAddComment(item.id)} />
                          <MdCancel onClick={handleCloseComment} />

                          {/* <Button onClick={handleCloseComment}>Cancel</Button> */}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              ))
            )}
          </Card>
        </Box>
      </Container>
    </Drawer>
  );
};

export default SocialMediaDrawer;
