import React, { useEffect, useRef, useState } from 'react';
import darkheroimage from "../../assets/Hero-image-dark.webp";
import lightheroimage from "../../assets/Hero Image.webp";
import cloudImage from "../../assets/white-small-cloud.webp";
import threadImage from "../../assets/thread.png";
import darkThreadImage from "../../assets/darkThread.png"
import { useSelector } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import hrmsImg1 from "../../assets/hrms1.webp";
import hrmsImg2 from "../../assets/hrms2.webp";
import hrmsImg3 from "../../assets/hrms3.webp";
import kmsImg1 from "../../assets/kms1.webp";
import kmsImg2 from "../../assets/kms2.webp";
import kmsImg3 from "../../assets/kms3.webp";
import lmsImg1 from "../../assets/lms1.webp";
import lmsImg2 from "../../assets/lms2.webp";
import lmsImg3 from "../../assets/lms3.webp";
import invImg1 from "../../assets/if1.webp";
import invImg2 from "../../assets/if2.webp";
import invImg3 from "../../assets/if3.webp";
import thumbImg from "../../assets/thumbIcon.png";
import EfficiencyImg from "../../assets/Efficiency.png";
import StartImg from "../../assets/Star.png";
import card1Img from "../../assets/card1.webp";
import card2Img from "../../assets/card2.webp";
import card3Img from "../../assets/card3.webp";
import nightCard1Img from "../../assets/night-card1.webp";
import nightCard2Img from "../../assets/night-card2.webp";
import nightCard3Img from "../../assets/night-card3.webp";
import vilivVideo from "../../assets/VLIV video.mp4"
import { useNavigate } from 'react-router-dom';
import BusinessTransformSection from './BusinessTransformSection';


function Home() {
    //Redux
    const theme = useSelector((state) => state.themeReducer.theme);

    //state
    const [expanded, setExpanded] = useState('pannel1');

    //Navigate
    const navigate = useNavigate();

    const videoRef = useRef(null);

    useEffect(() => {
        // const videoElement = videoRef.current;
        // let playState = false;
        // let timeoutId = null;

        // const debouncePlayPause = (callback, delay) => {
        //     return (...args) => {
        //         clearTimeout(timeoutId);
        //         timeoutId = setTimeout(() => {
        //             callback(...args);
        //         }, delay);
        //     };
        // };

        // const handlePlayPause = debouncePlayPause((entries) => {
        //     entries.forEach((entry) => {
        //         if (entry.isIntersecting && !playState) {
        //             videoElement.play();
        //             playState = true;
        //         } else if (!entry.isIntersecting && playState) {
        //             videoElement.pause();
        //             playState = false;
        //         }
        //     });
        // }, 200);

        // const observer = new IntersectionObserver(handlePlayPause, { threshold: 0.5 });

        // observer.observe(videoElement);

        // return () => {
        //     clearTimeout(timeoutId);
        //     observer.unobserve(videoElement);
        // };


    }, []);

    const [accordianData, setAccordianData] = useState(
        [
            {
                id: 1,
                panel: 'pannel1',
                title: 'Human Resource Management System (HRMS)',
                desc: [
                    {
                        title: "Automated Payroll",
                        desc: "Streamline payroll processes with AI-powered automation",
                    },
                    {
                        title: "Employee Self-Service",
                        desc: "Empower employees with self-service portals for leave requests, benefits, and more.",
                    },
                    {
                        title: "Performance Tracking",
                        desc: "Monitor and evaluate employee performance with comprehensive analytics.",
                    }
                ],
                images: [{ img1: hrmsImg1, img2: hrmsImg2, img3: hrmsImg3 }]
            },
            {
                id: 2,
                panel: 'pannel2',
                title: 'Knowledge Management System (KMS)',
                desc: [
                    {
                        title: "Centralized Knowledge Repository",
                        desc: "Keep all your essential information in one easily accessible place.",
                    },
                    {
                        title: "Smart Search",
                        desc: "Find the information you need quickly with AI-enhanced search capabilities.",
                    },
                    {
                        title: "Collaborative Tools",
                        desc: "Enhance teamwork with integrated communication and collaboration features.",
                    }
                ],
                images: [{ img1: kmsImg1, img2: kmsImg2, img3: kmsImg3 }]
            },
            {
                id: 3,
                panel: 'pannel3',
                title: 'Learning Management System (LMS)',
                desc: [
                    {
                        title: "Personalized Learning Paths",
                        desc: "Create customized training programs tailored to individual needs",
                    },
                    {
                        title: "Interactive Content",
                        desc: "Engage learners with multimedia-rich content and interactive quizzes.",
                    },
                    {
                        title: "Progress Tracking",
                        desc: "Monitor learning progress and completion rates with detailed reports.",
                    }
                ],
                images: [{ img1: lmsImg1, img2: lmsImg2, img3: lmsImg3 }]
            },
            {
                id: 4,
                panel: 'pannel4',
                title: 'Innovative Functionalities',
                desc: [
                    {
                        title: "AI Insights",
                        desc: "Leverage AI to gain actionable insights and make smarter decisions.",
                    },
                    {
                        title: "Automation",
                        desc: "Automate repetitive tasks to free up time for more strategic work.",
                    },
                    {
                        title: "Seamless Integration",
                        desc: "Integrate Vliv with your existing tools for a smooth transition.",
                    }
                ],
                images: [{ img1: invImg1, img2: invImg2, img3: invImg3 }]
            },

        ]
    );

    const [benefits, setBenefits] = useState(
        [

            {
                title: "Streamline Workflows",
                desc: "Centralize your HR, knowledge, and learning management in one platform.",
            },
            {
                title: "Boost Productivity",
                desc: "Automate routine tasks and focus on what matters most.",
            },
            {
                title: "Enhance User Experience:",
                desc: "Provide a unified and intuitive interface for all your workforce needs.",
            }

        ]
    );

    const [benefits2, setBenefit2] = useState(
        [

            {
                title: "HR Professionals",
                desc: "Simplify administrative tasks, improve employee satisfaction, and gain deeper insights into workforce metrics.",
            },
            {
                title: "Knowledge Managers",
                desc: "Ensure knowledge is captured, shared, and utilized effectively across the organization.",
            },
            {
                title: "Trainers and Educators",
                desc: "Deliver impactful training programs, track progress, and foster continuous learning.",
            }

        ]
    );

    const [testimonial, setTestimonial] = useState(
        [

            {
                id: 1,
                image: StartImg,
                desc: "Vliv has transformed our HR operations. The automation features are a game-changer!",
                name: "-Sarah T, HR Director"
            },
            {
                id: 2,
                image: StartImg,
                desc: "Our team collaboration has improved significantly thanks to Vliv's integrated KMS.",
                name: "— John M, Knowledge Manager"
            },
            {
                id: 3,
                image: StartImg,
                desc: "Training our employees has never been easier. Vliv's LMS makes learning engaging and effective.",
                name: "— Emily R,Trainer"
            },
            {
                id: 4,
                image: StartImg,
                desc: "Boosted productivity and streamlined information sharing. An indispensable tool for our team!",
                name: "— Sarah L, Project Manager"
            },

        ]
    )

    const [realWorldImpact, setRealWlorldImpact] = useState(
        [
            {
                id: 1,
                image: { lightImg: card1Img, darkImg: nightCard1Img },
                title: "Boost HR Efficiency with Streamlined Employee Lifecycle Management",
                desc: "Maximize HR productivity by managing the entire employee lifecycle from recruitment to offboarding. Benefit from seamless integration of background checks, onboarding, performance evaluations, and succession planning."
            },
            {
                id: 2,
                image: { lightImg: card2Img, darkImg: nightCard2Img },
                title: "Enhance Knowledge Sharing with Collaborative Learning Solutions",
                desc: "Foster a culture of continuous learning and collaboration with our Knowledge Management System. Easily create, store, and share knowledge resources while enabling peer-to-peer learning and expertise exchange within your organization."
            },
            {
                id: 3,
                image: { lightImg: card3Img, darkImg: nightCard3Img },
                title: "Accelerate Skill Development with Personalized Learning Paths",
                desc: "Empower your employees with tailored learning experiences through our Learning Management System. Design personalized learning paths that align with career goals and business objectives, and track progress with sophisticated analytics tools."
            },
        ]
    )

    // only one time auto play and again auto play after refresh
    // useEffect(() => {
    //     const videoElement = videoRef.current;

    //     const handleScroll = () => {
    //         if (videoElement && videoElement.paused) {
    //             videoElement.play().catch(error => {
    //                 console.error('Error attempting to play the video:', error);
    //             });
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll, { once: true });

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);


    //function
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleContactForm = () => {
        navigate("/contactus");
    };

    return (
        <>
            {/* hero section start */}
            <section className={`section-hero ${theme == "light" ? "light" : "dark"}`} >
                <div className={`container hero-container ${theme == "dark" && "dark"}`}>
                    <h1 className='hero-heading' >Revolutionize Your Workforce with <span className={`common-heading ${theme == "dark" && "dark"}`}>Vliv</span></h1>
                    <h2 className='hero-subheading'>Simplify, Automate, Empower</h2>
                    <div className='hero-btn-container'><button className={`commonBtn ${theme == "light" ? "light" : "dark"} hero-btn`} onClick={handleContactForm}>Enquire Now</button></div>
                    <div className='hero-images--container' data-aos="fade-up">
                        <img src={theme === "light" ? lightheroimage : darkheroimage} alt="Image not Available" className='hero-img' />
                        <img src={cloudImage} alt="Cloud image not available" className='hero-cloud--img' />
                        <img src={theme === "light" ? threadImage : darkThreadImage} alt="Thread image not available" className='hero-thread--img' />
                    </div>
                </div>
            </section>
            {/* hero section end */}

            {/* why vlib start */}
            <section className={`section-why--vlib ${theme == "light" ? "light" : "dark"}`} data-aos="fade-up">
                <div className={`container grid grid-two--cols ${theme == "dark" && "dark"}`}>
                    <div>
                        <h1 className={`common-heading ${theme == "dark" && "dark"}`}>Why Vliv ?</h1>
                        <p className='common-para'>Vliv combines the power of {theme == "light" ? <strong className='strong_tag_color'>HRMS</strong> : <b>HRMS</b>}, {theme == "light" ? <strong className='strong_tag_color'>KMS</strong> : <b>KMS</b>}, and {theme == "light" ? <strong className='strong_tag_color'>LMS</strong> : <b>LMS</b>} into one seamless platform, transforming how businesses manage their teams and knowledge. Experience unparalleled efficiency and growth with our AI-driven, cloud-based solution.</p>
                        <div className='hero-btn-container'><button className={`commonBtn ${theme == "light" ? "light" : "dark"} hero-btn`} onClick={handleContactForm}>Contact us!</button></div>
                    </div>
                    <div className="video-container">
                        <video ref={videoRef} width="600" controls autoPlay muted className="video-player">
                            <source src={vilivVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </section>
            {/* why vlib end */}

            {/* key feature start */}
            <section className={`section-key--feature ${theme == "light" ? "light" : "dark"}`} data-aos="fade-up">
                <div className='container'>
                    <h2 className={`common-title ${theme == "light" ? "light" : "dark"}`}>Key Features</h2>
                    <div className={`grid grid-two--cols key-feature--container ${theme == "dark" && "dark"}`}>
                        <div className='accordianDesc'>
                            <div className={`accordian-container ${theme == "light" ? "light" : "dark"}`}>
                                {
                                    accordianData.map((item, index) => {
                                        return (
                                            <Accordion key={item.id} expanded={expanded === item.panel} onChange={handleChange(item.panel)} className={`accordianPannel ${theme == "light" ? "light" : "dark"}`}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id={`${item.panel}-header`}
                                                    className='pannel-head'
                                                >
                                                    <Typography className='pannel-title'>{item.title}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography className='pannel-desc'>
                                                        {item.desc.map((descItem, idx) => (
                                                            <Typography key={idx} className='panel-desc'>
                                                                <span className='title'>{descItem.title}: </span> <span className='desc'>{descItem.desc}</span>
                                                            </Typography>
                                                        ))}
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        );

                                    })
                                }
                            </div>
                        </div>
                        <div className="grid grid-two--cols gallery-container">
                            {accordianData.map((item) => (
                                expanded === item.panel && (
                                    <React.Fragment key={item.id}>
                                        {item.images.map((itemData, index) => (
                                            <React.Fragment key={index}>
                                                <div className='first-gallary-container'>
                                                    <img src={itemData.img1} alt="Image not available" />
                                                    <img src={itemData.img2} alt="Image not available" className='gallaryimg' />
                                                </div>
                                                <div className='second-gallary-container'>
                                                    <img src={itemData.img3} alt="Image not available" />
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                )
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            {/* key feature end */}

            {/* Benefits section start */}
            <section className={`section-benefits ${theme == "light" ? "light" : "dark"}`} data-aos="fade-up">
                <div className='container'>
                    <h2 className={`common-title ${theme == "light" ? "light" : "dark"}`}>Benefits</h2>
                    <div className={`grid grid-two--cols benefits-container ${theme == "dark" && "dark"}`}>
                        <div className='efficency-container'>
                            <div className='imgContainer'>
                                <img src={EfficiencyImg} alt="Efficency Img not available" />
                            </div>

                            <div className='benefits-para--container'>
                                <h2 className='benefits-title'>Unlock New Levels of Efficiency</h2>

                                {benefits.map((descItem, index) => (
                                    <Typography key={index} className='panel-desc'>
                                        <span className='title'>{descItem.title}: </span> <span className='desc'>{descItem.desc}</span>
                                    </Typography>
                                ))}
                            </div>
                        </div>
                        <div className='efficency-container'>
                            <div className='imgContainer'>
                                <img src={thumbImg} alt="Efficency Img not available" />
                            </div>

                            <div className='benefits-para--container'>
                                <h2 className='benefits-title'>Tailored Advantages for Every User Group</h2>

                                {benefits2.map((descItem, index) => (
                                    <Typography key={index} className='panel-desc'>
                                        <span className='title'>{descItem.title}: </span> <span className='desc'>{descItem.desc}</span>
                                    </Typography>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Benefits section end */}

            {/* Testimonials section start */}
            <section className={`section-testimonials ${theme == "light" ? "light" : "dark"}`} data-aos="fade-up">
                <div className='container'>
                    <h2 className={`common-title ${theme == "light" ? "light" : "dark"}`}>Testimonials</h2>
                    <div className={`grid grid-two--cols testimonial-container ${theme == "dark" && "dark"}`}>

                        {testimonial.map((item, index) => (
                            <div className='efficency-container' key={item.id}>
                                <div className='imgContainer'>
                                    <img src={item.image} alt="Image not available" />
                                </div>
                                <div className='benefits-para--container'>
                                    <h2 className='desc'>{item.desc}<span className='title'>{item.name}</span></h2>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section>
            {/* Testimonials section end */}

            {/* Real World Impact section start */}
            <section className={`section-realWorldImpact ${theme == "light" ? "light" : "dark"}`} data-aos="fade-up">
                <div className='container'>
                    <h2 className={`common-title ${theme == "light" ? "light" : "dark"}`}>Real-World Impact</h2>
                    <div className={`grid grid-three--cols realWorldImpact-container ${theme == "dark" && "dark"}`}>
                        {
                            realWorldImpact.map((item, index) => {
                                return (
                                    <div className='realWorldImpactCard' key={item.id}>
                                        <h2 className='benefits-title'>{item.title}</h2>
                                        <img src={theme === "light" ? item.image.lightImg : item.image.darkImg} alt="Onboarding Image not available" className='cardImage' />
                                        <p className='cardPara'>{item.desc}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            {/* Real World Impact section end */}

            {/* Transform business start */}
            <BusinessTransformSection theme={theme} />
            {/* Transform business end */}
        </>
    )
}

export default Home