import React, { useState, useEffect, useCallback } from "react";
import {
  TextField,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
  FormControl,
} from "@mui/material";
import debounce from "lodash.debounce";
import Script from "react-load-script";

const googleMapSearchApiKey = "AIzaSyB3Mbhn-OA-Xd_CIQ0MtgzqgpX5Q3-RF54"; // api key

const LocationSearch = ({ location, onLocationSelect,setPlaceId,setRefference }) => {
  const [query, setQuery] = useState(location || "");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");

  const fetchSuggestions = useCallback(
    debounce((input) => {
      if (!input || !scriptLoaded) return;

      setLoading(true);
      setError(null);

      const service = new window.google.maps.places.AutocompleteService();

      service.getPlacePredictions({ input }, (predictions, status) => {
        setLoading(false);
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          predictions
        ) {
          setSuggestions(predictions);
        } else {
          setError("Failed to fetch suggestions");
        }
      });
    }, 500),
    [scriptLoaded]
  );

  useEffect(() => {
    if (query && scriptLoaded && query !== selectedLocation) {
      fetchSuggestions(query);
    } else {
      setSuggestions([]);
    }
  }, [query, fetchSuggestions, scriptLoaded, selectedLocation]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSelect = (place) => {
    setQuery(place.description); // Keep the selected location in the TextField
    onLocationSelect(place.description); // Call the parent with selected location
    setSelectedLocation(place.description); // Store the selected location
    setPlaceId(place.place_id);
    setRefference(place.reference)
    setSuggestions([]); // Clear suggestions after selecting
  };

  const handleScriptLoad = () => {
    setScriptLoaded(true);
  };

  const handleScriptError = () => {
    setError("Failed to load Google Maps script");
  };

  console.log(suggestions,"suggestions");

  return (
    <div>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${googleMapSearchApiKey}&libraries=places`}
        onLoad={handleScriptLoad}
        onError={handleScriptError}
      />
      <FormControl fullWidth>
        <TextField
          label="Search Location"
          variant="outlined"
          fullWidth
          value={query}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: loading ? <CircularProgress size={20} /> : null,
          }}
        />

        {error && <Typography color="error">{error}</Typography>}

        <List>
          {suggestions.map((place) => (
            <ListItem
              button
              key={place.place_id}
              onClick={() => handleSelect(place)}
            >
              <ListItemText primary={place.description} />
            </ListItem>
          ))}
        </List>
      </FormControl>
    </div>
  );
};

export default LocationSearch;
