import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { IoPersonSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { cardData } from './BlogData';
import axios from 'axios';
import { notifyError, notifySuccess } from '../../../constants/toastAlerts';
import { reloadPage } from '../../../redux/actions/load.action';
import Loader from '../../../constants/Loader';
import { formattedDate } from '../../../constants/formatedDate';
// sahid jhsfj wfisdjfoiru8
function Blogs() {
  //Redux
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.themeReducer.theme);
  const loadData = useSelector((state) => state.loadReducer);

  const navigate = useNavigate();

  const blogtext = ['Unlock the full potential of VLIV with our insightful knowledge hub.']
  const [text] = useTypewriter({
    words: blogtext,
    loop: 0, // Infinite loop
    delaySpeed: 2000,
  });
  const [data, setData] = useState([]);
  const [email, setEmail] = useState("");

  //function
  const handlleCardItem = (id) => {
    navigate(`/blog/${id}`)
  }

  const emailStyling = {
    width: "25%"
  }

  useEffect(() => {
    axios
      .post(`https://vliv.app/blog/get`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleGetUpdates = async () => {
    dispatch(reloadPage(true));
    if (email == "") {
      setTimeout(() => {
        dispatch(reloadPage(false));
        return notifyError("Please enter your email")
      }, 1000)

    } else {
      const res = await axios.post("/user/sendmailtoblog", { email: email });

      if (res.data.message == 'Blog subscribe successfully!') {
        setTimeout(() => {
          dispatch(reloadPage(false));
          notifySuccess(res.data.message);
        }, 1000)
      }
      else {
        setTimeout(() => {
          dispatch(reloadPage(false));
          notifySuccess(res.data.message);
        }, 1000)
      }
      setEmail("")
    }


  }

  return (
    <>
      {/* Blogs hero section start */}
      <section className={`section-blog--hero ${theme == "light" ? "light" : "dark"}`}>
        <div className={`container blog`}>
          <h1 className='blog-heading'>
            {text}
            <Cursor className="cursor" />
          </h1>
          <h2 className='blog-subheading'>Explore in-depth articles, guides, use cases and harness the power of VLIV through our comprehensive resources.</h2>
          <div className={`subscription-form`}>
            <div className='emailContainer' style={emailStyling}>
              <input type="email" name='email' placeholder='Email address' value={email} className='form-feild' onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className='buttonContainer'>
              <button type='submit' className='commonBtn' onClick={handleGetUpdates}>{loadData ? <Loader /> : "Get Updates"}</button>
            </div>
          </div>
        </div>
      </section>
      {/* Blogs hero section end */}
      {/* Blogs section start */}
      <section className={`section-blogs ${theme == "light" ? "light" : "dark"}`} data-aos="fade-up">
        <div className='container'>
          <h2 className={`common-title ${theme == "light" ? "light" : "dark"}`}>Blogs</h2>
          <div className={`grid grid-three--cols  ${theme == "dark" && "dark"}`}>
            {
              data.map((item, index) => {
                const {
                  id,
                  cardimage,
                  cardtitle,
                  description,
                  username,
                  postedDate,
                  updatedAt
                } = item;
                return (
                  <div className="card" key={id} onClick={() => handlleCardItem(id)}>
                    <img src={cardimage} alt="Image not available" className='cardimg' />
                    <div className='card-details'>
                      <div className='card-title-container'>
                        <span className='card-title'>{cardtitle}</span>
                      </div>
                      {description.slice(0, 1).map((item, index) =>
                        item.type === "text" && (
                          <div className='card-description--container' key={index}>
                            <span className='card-description' dangerouslySetInnerHTML={{ __html: item.content }} />

                          </div>
                        )
                      )}
                      <div className='card-user--container'>
                        <p>
                          {/* <span className='usericon'><IoPersonSharp /></span>
                          <span className='username'>{username}</span> */}
                        </p>
                        <span className='postdate'>
                          <span>{formattedDate(updatedAt)}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                )

              })
            }
          </div>
        </div>
      </section>
      {/* Blogs section end */}
    </>
  )
}

export default Blogs;