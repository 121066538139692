import React from "react";
// import { useSelector } from "react-redux";
import { Navigate} from "react-router-dom";
import Cookies from "js-cookie";
import BackendLayout from "../Components/Sidebar/BackendLayout";

function BackendProtectRoute() {
  const backendData = Cookies.get("backendinfo");
  const email = backendData && JSON.parse(backendData)?.email || ""
  //  const isLoggedIn = true;
  // console.log(email,"doubt")

  if (!email || email == "") {
    return <Navigate to="/backend/login" />;
  }else{
    return <BackendLayout/>
  }

  
}

export default BackendProtectRoute;
