import React, { useState } from "react";
import Cookies from "js-cookie";
import { Box, Button, Divider } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import userimg from "../../../assets/profile_image.jpg";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import noimage from "../../../assets/no-profile.png"
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";



const Profile = () => {
  const { id,
    email,
    status,
    userid,
    username,
    reportmanager,
    userphone,
    userrole,
    userprofile,
    companyemail,
    companyid,
    clientimage,
    gender,
    country,
    state,
    pincode,
    city,
    address,
    address2,
    typeofdocument,
    documentvalue,
    language,
    timezone,
    signature,
    designation,
    pan,
    twitter,
    linkedin,
    clientname,
    clientemail,
    companyname,
    companyimage,
    employeeid,
    pf,
    companygst,
    companyregno,
    countrycode,
    countryaddress,
    tan,
    un,
    esi,
  } = useSelector((state) => state.userDataReducer.userData || {})
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1, fontSize: "1.5rem" }}>
      <Grid
        container
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <Grid item md={5}>
          <div style={{ display: "flex", alignItems: "center", gap: 7 }}>
            <div
              id="profileImageContainer"
              style={{
                width: "90px",
                height: "90px",
                borderRadius: "50%",
                overflow: "hidden",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={!clientimage ? noimage : clientimage}
                alt="Profile Image"
                id="profileImage"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            <div>
              <h3 style={{ marginBottom: "5px" }}>
                {userrole == "masteradmin" ? username : username}
              </h3>
              <p> {userrole == "masteradmin" ? clientemail : email}</p>
            </div>
          </div>
        </Grid>
        <Grid item md={5}>
          <div style={{ display: "flex", alignItems: "center", gap: 7 }}>
            <div
              id="profileImageContainer"
              style={{
                width: "90px",
                height: "90px",
                borderRadius: "50%",
                overflow: "hidden",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={!companyimage ? noimage : companyimage}
                alt="Profile Image"
                id="profileImage"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            <div>
              <h3 style={{ marginBottom: "5px" }}>
                {companyname ? companyname : "N/A"}
              </h3>
            </div>
          </div>
        </Grid>
        <Grid item md={2}>
          <div>
            <Button
              variant="outlined"
              endIcon={<ModeEditIcon />}
              sx={{ borderRadius: "10px", color: "#0B4A6F", fontSize: "1.2rem" }}
              onClick={() => navigate("/frontend/profile/edit")}
            >
              Edit
            </Button>
          </div>
        </Grid>
      </Grid>
      <Divider />
      <Grid container sx={{ padding: "10px", margin: "5px" }}>
        <Grid item md={6} sm={6} xs={6}>
          <h4 style={{ marginBottom: "5px" }}>First Name</h4>
          <p>{username?.split(" ")[0] || "-"}</p>
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <h4 style={{ marginBottom: "5px" }}>Last Name</h4>
          <p>{username?.split(" ")[1] || "-"}</p>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "10px", margin: "5px" }}>
        <Grid item md={6} sm={6} xs={12}>
          <h4 style={{ marginBottom: "5px" }}>Gender</h4>
          <p>{gender ? gender : "-"}</p>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <h4 style={{ marginBottom: "5px" }}>Organisation Name</h4>
          <p>{companyname ? companyname : "-"}</p>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "10px", margin: "5px" }}>
        <Grid item md={6} sm={6} xs={12}>
          <h4 style={{ marginBottom: "5px" }}>Country</h4>
          <p>{country ? country : "-"}</p>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <h4 style={{ marginBottom: "5px" }}>State</h4>
          <p>{state ? state : "-"}</p>
        </Grid>

      </Grid>
      <Grid container sx={{ padding: "10px", margin: "5px" }}>
        <Grid item md={6} sm={6} xs={12}>
          <h4 style={{ marginBottom: "5px" }}>City</h4>
          <p>{city ? city : "-"}</p>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <h4 style={{ marginBottom: "5px" }}>Pincode</h4>
          <p>{pincode ? pincode : "-"}</p>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "10px", margin: "5px" }}>
        <Grid item md={6} sm={6} xs={12}>
          <h4 style={{ marginBottom: "5px" }}>Address</h4>
          <p>{address ? address : "-"}</p>
        </Grid>
        <Grid item md={6} >
          <h4 style={{ marginBottom: "5px" }}>Signature</h4>
          <p>{signature ? signature : "-"}</p>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "10px", margin: "5px" }}>
        <Grid item md={6} sm={6} xs={12}>
          <h4 style={{ marginBottom: "5px" }}>Language</h4>
          <p>{language ? language : "-"}</p>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <h4 style={{ marginBottom: "5px" }}>Time Zone</h4>
          <p>{timezone ? timezone : "-"}</p>
        </Grid>
      </Grid>
      <Divider />
      <Grid container sx={{ padding: "10px", margin: "5px" }}>
        <Grid item md={6} sm={6} xs={12}>
          <h4 style={{ marginBottom: "5px" }}>Email Id</h4>
          <p>{email ? email : "-"}</p>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <h4 style={{ marginBottom: "5px" }}>Employee Id</h4>
          <p>{employeeid ? employeeid : "-"}</p>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "10px", margin: "5px" }}>
        <Grid item md={6} sm={6} xs={12}>
          <h4 style={{ marginBottom: "5px" }}>Phone Number</h4>
          <p>{userphone ? userphone : "-"}</p>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <h4 style={{ marginBottom: "5px" }}>Designation</h4>
          <p>{designation ? designation : "-"}</p>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "10px", margin: "5px" }}>
        <Grid item md={6} sm={6} xs={12}>
          <h4 style={{ marginBottom: "5px" }}>GSTIN</h4>
          <p>{companygst ? companygst : "-"}</p>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <h4 style={{ marginBottom: "5px" }}>PAN</h4>
          <p>{pan ? pan : "-"}</p>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "10px", margin: "5px" }}>
        <Grid item md={6} sm={6} xs={12}>
          <h4 style={{ marginBottom: "5px" }}>Tan</h4>
          <p>{tan ? tan : "-"}</p>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <h4 style={{ marginBottom: "5px" }}>UN</h4>
          <p>{un ? un : "-"}</p>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "10px", margin: "5px" }}>
        <Grid item md={6} sm={6} xs={12}>
          <h4 style={{ marginBottom: "5px" }}>PF</h4>
          <p>{pf ? pf : "-"}</p>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <h4 style={{ marginBottom: "5px" }}>ESI</h4>
          <p>{esi ? esi : "-"}</p>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "10px", margin: "5px" }}>
        <Grid item md={6} sm={6} xs={12}>
          <h4 style={{ marginBottom: "5px" }}>Twitter</h4>
          <p>{twitter ? twitter : "-"}</p>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <h4 style={{ marginBottom: "5px" }}>Linked In</h4>
          <p>{linkedin ? linkedin : "-"}</p>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "10px", margin: "5px" }}>
        <Grid item md={6} sm={6} xs={12}>
          <h4 style={{ marginBottom: "5px" }}>Registration Certificate No</h4>
          <p>{companyregno ? companyregno : "-"}</p>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Profile;
