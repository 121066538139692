import React from 'react'
import loadingAnimation from "../assets/Animation - 1722597592899.gif";

function ButtonLoader() {
  return (
    <div className='smallLoader'>
        <img src={loadingAnimation} alt='Image is not available' />
    </div>
  )
}

export default ButtonLoader