import React from 'react'
import { useSelector } from 'react-redux';
import EmailLink from '../../../constants/Email';

function PrivacyPolicy() {
  //Redux
  const theme = useSelector((state) => state.themeReducer.theme);
  return (
    <>
      {/* TandC section start */}
      < section className={`section-privacypolicy ${theme == "light" ? "light" : "dark"}`}>
        <div className={`container hero-container ${theme == "dark" && "dark"}`}>
          <h1>Privacy Policy </h1>
          <p className='common-paragraph'>
            Venturesathi Business Services LLP ("Venturesathi," "we," "us," or "our") is committed to protecting your
            privacy. This Privacy Policy explains how we collect use disclose and protect your personal information
            when you use our SaaS-based web application product named "Vliv" ("Service") and our website located
            at vliv.app ("Website").
          </p>

          <p className='common-paragraph bold'>By accessing or using the Service or Website, you agree to the terms of this Privacy Policy. </p>

          <ul className='common-list'>
            <li>
              <h2 className='common-defination'>1. Information We Collect </h2>
              <p className='common-paragraph my-2'>We collect information that you provide to us directly, such as when you:</p>
              <ul className='my-2'>
                <li>
                  <b>Create an account:</b> This may include your name, email address, business information and other
                  registration information.
                  videos, messages or other materials uploaded, downloaded or appearing on the Services.
                </li>
                <li>
                  <b>Use the Service:</b> We collect information about how you use the Service, such as the features you
                  access, the data you input, and the actions you take. </li>
                <li>
                  <b>Contact us:</b> If you contact us for support or with any other inquiry, we will collect the information
                  you provide to us such as your name, email address, and the content of your message.</li>
              </ul>
              <p className='common-paragraph my-2'>We may also automatically collect certain information when you visit our Website or use our Service, such
                as: </p>
              <ul className='my-2'>
                <li>
                  <b>Usage Data:</b>  Information about your activity on the Service, such as access times, pages viewed,
                  links clicked, and other actions you take within the Service.
                </li>
                <li>
                  <b>Device Data:</b>  Information about the device you use to access the Service, such as the hardware
                  model, operating system and version, unique device identifiers, and mobile network information.</li>
                <li>
                  <b>Log Data: </b>  Information that your browser or device automatically transmits to us, such as your IP
                  address, referring/exit pages, browser type, operating system, date/time stamp, and clickstream
                  data. </li>
              </ul>
            </li>
            <li>
              <h2 className='common-defination'>2. How We Use Your Information </h2>
              <p className='common-paragraph my-2'>We use the information we collect to:</p>
              <ul className='my-2'>
                <li>Provide and improve the Service.</li>
                <li>Respond to your inquiries and provide customer support.</li>
                <li>Send you technical notices, updates, security alerts, and support and administrative messages. </li>
                <li>Analyze and understand how the Service is used.</li>
                <li>Develop new products and services. </li>
                <li>Protect the rights, property, and safety of Venturesathi, our users, and others. </li>
              </ul>
            </li>
            <li>
              <h2 className='common-defination'>3. Disclosure of Your Information</h2>
              <p className='common-paragraph my-2'>We may disclose your information as follows:</p>
              <ul>
                <li >
                  <b>Service Providers:</b> We may disclose your information to third-party service providers who perform services on our behalf, such as payment processing, data analysis, hosting, and customer support.
                </li>
                <li >
                  <b>Legal Compliance:</b> We may disclose your information to comply with applicable laws, regulations,
                  legal processes, or governmental requests.
                </li>
                <li >
                  <b>Business Transfers:</b> We may disclose your information in connection with a merger, acquisition,
                  financing due diligence, reorganization, bankruptcy, receivership, sale of company assets, or similar
                  transaction.
                </li>
              </ul>
            </li>
            <li>
              <h2 className='common-defination'>4. Data Security</h2>

              <p className='common-paragraph my-2'>We use commercially reasonable measures to protect the security of your information. However, no
                method of transmission over the Internet or method of electronic storage is completely secure.
                Therefore, while we strive to use commercially acceptable means to protect your information, we
                cannot guarantee its absolute security. </p>
            </li>
            <li>
              <h2 className='common-defination'>5. Your Choices </h2>
              <ul>
                <li><b>Account Information:</b> You can access, update, or delete certain account information by logging
                  into your account settings.
                </li>
                <li><b>Cookies:</b>
                  You can set your browser to refuse all or some browser cookies or to alert you when
                  cookies are being sent.
                </li>
              </ul>
            </li>
            <li>
              <h2 className='common-defination'>6. Children's Privacy </h2>
              <p className='common-paragraph my-2'>Our Service is not directed to children under the age of 18, and we do not knowingly collect personal
                information from children under 18.</p>
            </li>
            <li>
              <h2 className='common-defination'>7. Changes to this Privacy Policy </h2>
              <p className='common-paragraph my-2'>We reserve the right to modify this Privacy Policy at  any time. We will post the updated Privacy Policy on
                this page and indicate the date of the last revision. Your continued use of the Service or Website after
                the revised Privacy Policy has been posted will be deemed your acceptance of the revised Privacy Policy.
              </p>
            </li>
            <li>
              <h2 className='common-defination'>8. Contact Us</h2>
              <p className='common-paragraph my-2'>If you have any questions about this Privacy Policy, please contact us at: <EmailLink email="info@vliv.app" /></p>
            </li>
          </ul>
        </div>
      </ section >
      {/* TandC section end */}
    </>
  )
}

export default PrivacyPolicy