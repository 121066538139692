//Monthly
export const basic =
    [
        {
            "color": "blue",
            "planname": "Basic",
            "previousprice": "",
            "monthlyprice": "50",
            "purchased": "",
            "users": 30,
            "additionalusercost": 30,
            "featureslist": ["Includes 30 Users", "Additional User: Rs 30", "Cloud Storage: 05 GB", "Support: Email Only", "Master data managment", "Employee Registration", "Payroll", "Documents Generator- Standard template", "Standard Compliance Management", "HRM Basic Dashboard"],
            "yearlyprice": "420",
            "plansubscription": "Monthly",
            "tax": 100,
            "discount": 0,
            "addonpack":
                [
                    {
                        id: 1,
                        addonpackname: "Recruitment & Onboarding (Upto 3 HR Admins)",
                        addonprice: 360
                    },
                    {
                        id: 2,
                        addonpackname: "AI Enabled Resume Matcher(5 Resume /day)",
                        addonprice: 300
                    },
                    {
                        id: 3,
                        addonpackname: "Performance Management",
                        addonprice: 300
                    },
                    {
                        id: 4,
                        addonpackname: "Employee Self Service",
                        addonprice: 450
                    },
                    {
                        id: 5,
                        addonpackname: "Employee Self Service AI Chatbot",
                        addonprice: 150
                    },
                    {
                        id: 6,
                        addonpackname: "Additional Storage / 5 GB",
                        addonprice: 50
                    },
                    {
                        id: 7,
                        addonpackname: "Customized  Detailed Analysis Report",
                        addonprice: 5000
                    }

                ]
        }
    ]

export const standard =
    [
        {
            color: "yellow",
            planname: "Standard",
            previousprice: "",
            monthlyprice: "70",
            purchased: "",
            users: 100,
            additionalusercost: 50,
            featureslist: ['Includes 100 Users', 'Additional User: Rs 50', 'Cloud Storage: 10 GB', 'Support: Chat & Email', 'Advanced HRM Dashboard', 'KMS: Knowledge Repository', 'Payroll(Reports & Analytics)', 'Documents Generator', 'Advanced Compliance Management', 'Employee Time Booking', 'Leave Management', 'Standard Employee Self Service Portal(ESS)', 'Employee Onboarding & Asset Allocation', 'KMS: Document Creation', 'KMS: MIS Dashboard'],
            yearlyprice: "588",
            plansubscription: "Monthly",
            tax: 100,
            discount: 0,
            addonpack:
                [
                    {
                        id: 1,
                        addonpackname: "Recruitment  (Upto 5 HR Admins)",
                        addonprice: 450
                    },
                    {
                        id: 2,
                        addonpackname: "Performance Management",
                        addonprice: 700
                    },
                    {
                        id: 3,
                        addonpackname: "AI Enabled Resume Matcher (10 Resume /day)",
                        addonprice: 300
                    },
                    {
                        id: 4,
                        addonpackname: "Employee Self Service AI Chatbot",
                        addonprice: 300
                    },
                    {
                        id: 5,
                        addonpackname: "Employee Exit Managment",
                        addonprice: 800
                    },
                    {
                        id: 6,
                        addonpackname: "AI Writer (10 Documents/Day)",
                        addonprice: 600
                    },
                    {
                        id: 7,
                        addonpackname: "Additional Storage / 10 GB",
                        addonprice: 100
                    },
                    {
                        id: 8,
                        addonpackname: "Customized  Detailed Analysis Report",
                        addonprice: 7000
                    },
                ]
        }
    ]

export const premium =
    [
        {
            color: "pink",
            planname: "Premium",
            previousprice: "",
            monthlyprice: "150",
            purchased: "",
            users: 250,
            additionalusercost: 100,
            featureslist: ['Includes 250 Users', 'Additional User: Rs 100', 'Cloud Storage: 25 GB', 'Support: Priority Chat & Email', 'Recruitment Module', 'LMS:Course Creator', 'Payroll(Management Control)', 'Documents Generator', 'Compliance Management (With Assessments & Quiz)', 'Performance Management', 'Advanced Employee Self Service Portal(ESS)', 'Employee Exit Managment', 'KMS: AI Writer (10 Documents/Day)', 'LMS:Enrollment Management', 'LMS:Quiz & Assesment', 'LMS:Certificate Management', 'LMS:Analytics & Reports'],
            yearlyprice: "1260",
            plansubscription: "Monthly",
            tax: 100,
            discount: 0,
            addonpack:
                [
                    {
                        id: 1,
                        addonpackname: "Documents Generator- Customized template request",
                        addonprice: 50
                    },
                    {
                        id: 2,
                        addonpackname: "AI Enabled Quiz Creator (50 Quiz/ Month)",
                        addonprice: 250
                    },
                    {
                        id: 3,
                        addonpackname: "AI Enabled Hepldesk (Customized)",
                        addonprice: 1250
                    },
                    {
                        id: 4,
                        addonpackname: "Additional Storage / 10 GB",
                        addonprice: 100
                    },
                    {
                        id: 5,
                        addonpackname: "Customized  Detailed Analysis Report",
                        addonprice: 10000
                    },

                ]
        }
    ]

//Yearly
export const yearlybasic =
    [
        {
            "color": "blue",
            "planname": "Basic",
            "previousprice": "600",
            "monthlyprice": "50",
            "purchased": "",
            "users": 30,
            "additionalusercost": 30,
            "featureslist": ["Includes 30 Users", "Additional User: Rs 30", "Cloud Storage: 05 GB", "Support: Email Only", "Master data managment", "Employee Registration", "Payroll", "Documents Generator- Standard template", "Standard Compliance Management", "HRM Basic Dashboard"],
            "yearlyprice": "420",
            "plansubscription": "Yearly",
            "tax": 100,
            "discount": 30,
            "addonpack":
                [
                    {
                        id: 1,
                        addonpackname: "Recruitment & Onboarding (Upto 3 HR Admins)",
                        addonprice: 360
                    },
                    {
                        id: 2,
                        addonpackname: "AI Enabled Resume Matcher(5 Resume /day)",
                        addonprice: 300
                    },
                    {
                        id: 3,
                        addonpackname: "Performance Management",
                        addonprice: 300
                    },
                    {
                        id: 4,
                        addonpackname: "Employee Self Service",
                        addonprice: 450
                    },
                    {
                        id: 5,
                        addonpackname: "Employee Self Service AI Chatbot",
                        addonprice: 150
                    },
                    {
                        id: 6,
                        addonpackname: "Additional Storage / 5 GB",
                        addonprice: 50
                    },
                    {
                        id: 7,
                        addonpackname: "Customized  Detailed Analysis Report",
                        addonprice: 5000
                    }

                ]
        }
    ]

export const yearlystandard =
    [
        {
            color: "yellow",
            planname: "Standard",
            previousprice: "840",
            monthlyprice: "70",
            purchased: "",
            users: 100,
            additionalusercost: 50,
            featureslist: ['Includes 100 Users', 'Additional User: Rs 50', 'Cloud Storage: 10 GB', 'Support: Chat & Email', 'Advanced HRM Dashboard', 'KMS: Knowledge Repository', 'Payroll(Reports & Analytics)', 'Documents Generator', 'Advanced Compliance Management', 'Employee Time Booking', 'Leave Management', 'Standard Employee Self Service Portal(ESS)', 'Employee Onboarding & Asset Allocation', 'KMS: Document Creation', 'KMS: MIS Dashboard'],
            yearlyprice: "588",
            plansubscription: "Yearly",
            tax: 100,
            discount: 30,
            addonpack:
                [
                    {
                        id: 1,
                        addonpackname: "Recruitment  (Upto 5 HR Admins)",
                        addonprice: 450
                    },
                    {
                        id: 2,
                        addonpackname: "Performance Management",
                        addonprice: 700
                    },
                    {
                        id: 3,
                        addonpackname: "AI Enabled Resume Matcher (10 Resume /day)",
                        addonprice: 300
                    },
                    {
                        id: 4,
                        addonpackname: "Employee Self Service AI Chatbot",
                        addonprice: 300
                    },
                    {
                        id: 5,
                        addonpackname: "Employee Exit Managment",
                        addonprice: 800
                    },
                    {
                        id: 6,
                        addonpackname: "AI Writer (10 Documents/Day)",
                        addonprice: 600
                    },
                    {
                        id: 7,
                        addonpackname: "Additional Storage / 10 GB",
                        addonprice: 100
                    },
                    {
                        id: 8,
                        addonpackname: "Customized  Detailed Analysis Report",
                        addonprice: 7000
                    },
                ]
        }
    ]

export const yearlypremium =
    [
        {
            color: "pink",
            planname: "Premium",
            previousprice: "1800",
            monthlyprice: "150",
            purchased: "",
            users: 250,
            additionalusercost: 100,
            featureslist: ['Includes 250 Users', 'Additional User: Rs 100', 'Cloud Storage: 25 GB', 'Support: Priority Chat & Email', 'Recruitment Module', 'LMS:Course Creator', 'Payroll(Management Control)', 'Documents Generator', 'Compliance Management (With Assessments & Quiz)', 'Performance Management', 'Advanced Employee Self Service Portal(ESS)', 'Employee Exit Managment', 'KMS: AI Writer (10 Documents/Day)', 'LMS:Enrollment Management', 'LMS:Quiz & Assesment', 'LMS:Certificate Management', 'LMS:Analytics & Reports'],
            yearlyprice: "1260",
            plansubscription: "Yearly",
            tax: 100,
            discount: 30,
            addonpack:
                [
                    {
                        id: 1,
                        addonpackname: "Documents Generator- Customized template request",
                        addonprice: 50
                    },
                    {
                        id: 2,
                        addonpackname: "AI Enabled Quiz Creator (50 Quiz/ Month)",
                        addonprice: 250
                    },
                    {
                        id: 3,
                        addonpackname: "AI Enabled Hepldesk (Customized)",
                        addonprice: 1250
                    },
                    {
                        id: 4,
                        addonpackname: "Additional Storage / 10 GB",
                        addonprice: 100
                    },
                    {
                        id: 5,
                        addonpackname: "Customized  Detailed Analysis Report",
                        addonprice: 10000
                    },

                ]
        }
    ]