import React, { useEffect, useRef, useState } from 'react';
import brandLogo from "../../assets/Vliv-color 1.webp";
import { RiRobot2Line } from "react-icons/ri";
import { LuUser2 } from "react-icons/lu";
import { IoSend } from "react-icons/io5";
import axios from 'axios';
import ButtonLoader from '../../constants/ButtonLoader';
import { IoChatbubbleEllipsesSharp } from "react-icons/io5";
import { MdOutlineExpandLess, MdOutlineExpandMore } from 'react-icons/md';

function ChatBot() {
    const [openChat, setOpenChat] = useState(false)
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [botGreeted, setBotGreeted] = useState(false);
    const [loading, setLoading] = useState(false);
    const textareaRef = useRef(null);

    useEffect(() => {
        if (!botGreeted && openChat) {
            sendBotGreeting("pageloadmessage");
            setBotGreeted(true);
        }
    }, [openChat]);

    const handleChatBot = () => {
        setOpenChat(!openChat)
    }

    const sendBotGreeting = async (message) => {
        setLoading(true);
        if (message === "pageloadmessage") {
            try {
                const response = await axios.post('https://vliv-chat.onrender.com/chat', { user_question: "Who are you ?" });
                const botMessage = { sender: 'bot', text: response.data.bot_message };
                setMessages([...messages, botMessage]);
            } catch (error) {
                console.error('Error sending initial bot greeting', error);
            } finally {
                setLoading(false);
            }
        } else {
            if (input.trim() === '') return;
            const userMessage = { sender: 'user', text: input };
            setMessages([...messages, userMessage]);

            try {
                const response = await axios.post('https://vliv-chat.onrender.com/chat', { user_question: input });
                const botMessage = { sender: 'bot', text: response.data.bot_message };
                setMessages((prevMessages) => [...prevMessages, botMessage]);
            } catch (error) {
                console.error('Error sending message to chatbot API', error);
            } finally {
                setLoading(false);
            }

            setInput('');
            if (textareaRef.current) {
                textareaRef.current.style.height = 'auto';
            }
        }
    };

    const handleSend = () => {
        sendBotGreeting("userMessage")
    };
    // console.log("messages", messages)
    const handleInputChange = (e) => {
        setInput(e.target.value);

        if (textareaRef.current) {

            // Limit the height
            const maxHeight = 98; // Set your desired max height
            if (textareaRef.current.scrollHeight > maxHeight) {
                textareaRef.current.style.height = `${maxHeight}px`;
                textareaRef.current.style.overflowY = 'scroll';
            } else {
                textareaRef.current.style.overflowY = 'hidden';
            }
        }
    };

    // console.log("input", input)

    return (
        <div className='chatbot-page'>
            {openChat && <div className='chatbot-container'>
                <header>
                    <img src={brandLogo} alt="Brand logo " className='brandlogo-image' />
                    <span className='boticon'><RiRobot2Line /></span>
                </header>
                <div className='chatbot-inbox'>
                    <ul className='chatbot-messagebox'>
                        {messages.map((item, index) => (
                            <li key={index} className={item.sender === 'bot' ? 'chat-incoming' : 'chat-outgoing'}>
                                <div className='chat'>
                                    {item.sender === 'bot' ? (
                                        <span className='botmessage'>
                                            <RiRobot2Line />
                                        </span>
                                    ) : null}
                                    <span className='message-text'>
                                        {item.text}
                                    </span>
                                    {item.sender === 'user' ? (
                                        <span className='usericon'>
                                            <LuUser2 />
                                        </span>
                                    ) : null}
                                </div>
                            </li>
                        ))}
                        {loading && (
                            <li className='chat-incoming'>
                                <div className='chat'>
                                    <span className='botmessage'>
                                        <RiRobot2Line />
                                    </span>
                                    <span className='message-text'>
                                        <div className="typing-indicator">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </span>
                                </div>
                            </li>
                        )}
                    </ul>
                    <div className='chatbox-message--container'>
                        <div>
                            <textarea
                                rows="1"
                                placeholder="Type your message..."
                                className='text-feild'
                                onChange={handleInputChange}
                                ref={textareaRef}
                                value={input}
                                disabled={loading}
                                onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && handleSend()}
                            />
                            {!loading ? <span
                                className='send-icon'
                                onClick={handleSend}
                            >
                                <IoSend />
                            </span> :
                                <ButtonLoader />
                            }
                        </div>
                    </div>
                </div>

            </div>}
            <div className={`chatBot-icon ${openChat ? 'open' : 'closed'}`} onClick={handleChatBot}>
                {!openChat ? <IoChatbubbleEllipsesSharp /> : <MdOutlineExpandLess />}
            </div>
        </div>
    )
}

export default ChatBot;