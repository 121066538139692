import React from 'react'
import { useNavigate } from 'react-router-dom';

function BusinessTransformSection({ theme }) {

    //Navigate
    const navigate = useNavigate();

    const handleContactForm = () => {
        navigate("/contactus")
    };

    return (
        <section className={`section-transform-business ${theme == "light" ? "light" : "dark"}`} data-aos="fade-up">
            <div className={`container transform-business ${theme == "light" ? "light" : "dark"}`} >
                <h2 className={`common-title cards ${theme == "light" ? "light" : "dark"}`}> Ready to Transform Your Business?</h2>
                <h3 >Join the Vliv waiting list today and be the first to experience the future of workforce management. Early adopters enjoy exclusive benefits, including discounted pricing and extended trial periods.</h3>
                <div className={`grid form-container ${theme == "dark" && "dark"}`}>
                    {/* <div className='emailContainer'> */}
                        {/* <input type="email" name='email' placeholder='Email address' className='form-feild' /> */}
                    {/* </div> */}
                    <div className='buttonContainer'>
                        <button type='submit' className='commonBtn' onClick={handleContactForm}>Secure Early Bird Benefits</button>
                    </div>
                    {/* <div className='phoneContainer'> */}
                        {/* <input type="phone" name='phone' placeholder='Phone no' className='form-feild' /> */}
                    {/* </div> */}
                </div>
            </div>
        </section>
    )
}

export default BusinessTransformSection;