import * as React from "react";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Box from "@mui/material/Box";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { routes1, routes2, otherroutes1, otherroutes2 } from "./routes";
import { useDispatch, useSelector } from "react-redux";

const Sidebar = () => {
  //redux
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.themeReducer.theme);
  const location = useLocation();
  const navigate = useNavigate();
  const { id,
    email,
    status,
    userid,
    username,
    reportmanager,
    userphone,
    userrole,
    userprofile,
    companyemail,
    companyid,
    clientimage,
    gender,
    country,
    state,
    pincode,
    city,
    address,
    address2,
    typeofdocument,
    documentvalue,
    language,
    timezone,
    signature,
    designation,
    pan,
    twitter,
    linkedin,
    clientname,
    clientemail,
    companyname,
    companyimage,
    employeeid,
    pf,
    companygst,
    companyregno,
    countrycode,
    countryaddress } = useSelector((state) => state.userDataReducer.userData || {})
    let role = userrole && userrole.toLowerCase();
  // console.log("userrole", userrole)

  const isActive = (path) => {
    return location.pathname === path;
  };

  let routesToRender;
  switch (role) {
    case "superadmin":
    case "masteradmin":
    case "admin":
      routesToRender = routes1;
      break;
    default:
      routesToRender = routes2;
  }

  let otherroutes;
  switch (role) {
    case "superadmin":
    case "masteradmin":
    case "admin":
      otherroutes = otherroutes1;
      break;
    default:
      otherroutes = otherroutes2;
  }

  return (
    <>
        <div className={`sidebar ${theme === "light" ? 'light' : 'dark'}`}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent:"space-between", height:"85%"}}>
            <div className="sidebar-container">
              {routesToRender.map((route, index) => {
                return (
                  <NavLink to={route.path} key={index} className={`sidebarlink ${theme === "light" ? 'light' : 'dark'}`}>
                    <div className="sidebaricon">{route.icon}</div>
                    <div
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className={`sidebarlink ${theme === "light" ? 'light' : 'dark'}`}
                    >
                      {route.name}
                    </div>
                  </NavLink>
                );
              })}
            </div>
            <div className="sidebar-container">
              {otherroutes.map((route, index) => {
                return (
                  <NavLink to={route.path} key={index} className={`sidebarlink ${theme === "light" ? 'light' : 'dark'}`}>
                    <div className="sidebaricon">{route.icon}</div>
                    <div
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className={`sidebarlink ${theme === "light" ? 'light' : 'dark'}`}
                    >
                      {route.name}
                    </div>

                  </NavLink>
                );
              })}
            </div>
          </div>
        </div>
    </>
  );
};

export default Sidebar;
