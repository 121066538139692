import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import HRMS from './HRMSTab';
import BusinessTransformSection from '../BusinessTransformSection';
import HRMSTab from './HRMSTab';
import KMSTab from './KMSTab';
import LMSTab from './LMSTab';
import CustomTabPanel, { a11yProps } from '../../CustomTabPanel/CustomTabPanel';

function Feature() {
  //Redux
  const theme = useSelector((state) => state.themeReducer.theme);
  //state
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      {/* Feature section start */}
      <section className={`section-feature--hero ${theme == "light" ? "light" : "dark"}`}>
        <div className='container feature' >
          <div className='tabs-section'>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='tabs-section'>
              <Tab label="HRMS" {...a11yProps(0)} className='tab-button' />
              <Tab label="KMS" {...a11yProps(1)} className='tab-button'/>
              <Tab label="LMS" {...a11yProps(2)} className='tab-button'/>
            </Tabs>
            <CustomTabPanel value={value} index={0}  >
              <HRMSTab
                theme={theme}
                header="HR Management System"
                desc="HR Management System ( HRMS ) provides all the tools you need to help your HR staff and employees stay efficient, up-to-date, and productive, even on the go "
              
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <KMSTab
                theme={theme}
                header="Knowledge Management System"
                desc="Knowledge Management System (KMS) Provides the knowledge base (FAQ, SOP, WORK FLOW) to keep the teams updated about new technologies, procedures, best practices, methods which help them in the individual & organizational growth."
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <LMSTab
                theme={theme}
                header="Learning Management System"
                desc="Learning Management System (LMS) Provides the learning base to keep the teams (Courses, tutorials) updated about new technologies, procedures, best practices, methods which help them in the individual & organizational growth."
              />
            </CustomTabPanel>
          </div>
        </div>
      </section>
      {/* Feature section end */}
      {/* Transform business start */}
      <BusinessTransformSection theme={theme} />
      {/* Transform business end */}
    </>
  )
}

export default Feature