import { Box, Button, Container, Grid, TextField, Typography, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { IoMdSend } from "react-icons/io";
import { AiOutlinePaperClip } from "react-icons/ai"; // Import attachment icon
import axios from "axios";
import io from "socket.io-client";
import { useSelector } from "react-redux";
import { vlivBackendApi } from "../../../../../constants/api";

const socket = io(`${vlivBackendApi}`); // Ensure this is correct

function ChatList() {
  const { id,
    email,
    status,
    userid,
    username,
    reportmanager,
    userphone,
    userrole,
    userprofile,
    companyemail,
    companyid,
    clientimage,
    gender,
    country,
    state,
    pincode,
    city,
    address,
    address2,
    typeofdocument,
    documentvalue,
    language,
    timezone,
    signature,
    designation,
    pan,
    twitter,
    linkedin,
    clientname,
    clientemail,
    companyname,
    companyimage,
    employeeid,
    pf,
    companygst,
    companyregno,
    countrycode,
    countryaddress,
} = useSelector((state) => state.userDataReducer.userData || {});

  const [data, setData] = useState({
    companyname,
    companyid,
    companyemail,
    username,
    userid,
    time: new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    }),
    message: [],
  });

  const [message, setMessage] = useState({
    id: Math.random(),
    time: new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    }),
    type: "frontend",
    date: new Date(),
    message: "",
    file: "",
    user: username,
  });

  useEffect(() => {
    const fetchChatData = async () => {
      try {
        const response = await axios.post("https://vliv.app/chat/get", { companyid });
        const chatData = response.data;

        if (!chatData.message || !Array.isArray(chatData.message)) {
          chatData.message = [];
        }

        setData(chatData);
      } catch (error) {
        console.error("Error fetching chat data:", error);
      }
    };

    fetchChatData();

    socket.on("chat", (data) => {
      setData((prevData) => ({
        ...prevData,
        message: [...prevData.message, ...data.message],
      }));
    });

    return () => {
      socket.off("chat");
    };
  }, [companyid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMessage((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("https://vliv.app/chat/fileupload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const fileUrl = response.data.file;

      setMessage((prev) => ({
        ...prev,
        file: fileUrl,
      }));
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleSend = async () => {
    if (message.message.trim() === "" && !message.file) return;

    const newMessage = {
      id: Math.random(),
      time: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      type: "frontend",
      date: new Date(),
      message: message.message,
      file: message.file,
      user: username,
    };

    const updatedData = {
      ...data,
      message: [...data.message, newMessage],
    };

    try {
      await axios.post("https://vliv.app/chat/add", updatedData);
    } catch (error) {
      console.error("Error sending message:", error);
    }

    setMessage({
      id: Math.random(),
      time: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      type: "frontend",
      date: new Date(),
      message: "",
      file: "",
      user: username,
    });
  };

  return (
    <Container>
      <Grid container sx={{ justifyContent: "space-evenly" }}>
        <Grid item sm={12} md={12} lg={12}>
          <Grid container>
            <Grid
              item
              sm={12}
              md={12}
              lg={12}
              sx={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                padding: 2,
              }}
            >
              <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
                Chatting with Vliv team
              </Typography>
            </Grid>
            <Grid
              item
              sm={12}
              md={12}
              lg={12}
              sx={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                padding: 2,
                maxHeight: "400px", // Adjust this value as needed
                overflowY: "auto", // Add scrolling if there are too many messages
              }}
            >
              {data && data.message && data.message.length > 0 && data.message.map((item, index) => (
                <Grid
                  item
                  sm={12}
                  md={12}
                  lg={12}
                  key={index}
                  sx={{ mb: 1 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: item.user === username ? "end" : "start",
                    }}
                  >
                    <Box
                      sx={{
                        width: "45%",
                        borderRadius: 4,
                      }}
                    >
                      <Typography>
                        {item.user}, {item.time}
                      </Typography>

                      <Box
                        sx={{
                          background: item.user === username ? "#81fcc3" : "#b3b3b3",
                          padding: 2,
                          borderRadius: 4,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "black",
                          }}
                        >
                          {item.message}
                        </Typography>
                        {item.file && (
                          <a href={item.file} target="_blank" rel="noopener noreferrer">
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "blue",
                                textDecoration: "underline",
                                mt: 1
                              }}
                            >
                              View Attachment
                            </Typography>
                          </a>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Grid
              item
              sm={12}
              md={12}
              lg={12}
              sx={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                padding: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  sx={{ width: "50%" }}
                  placeholder="Type a message"
                  onChange={handleChange}
                  name="message"
                  value={message.message}
                />
                <input
                  type="file"
                  id="file-upload"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
                <IconButton
                  color="primary"
                  aria-label="upload file"
                  component="span"
                  onClick={() => document.getElementById("file-upload").click()}
                >
                  <AiOutlinePaperClip style={{ width: "25px", height: "25px" }} />
                </IconButton>
                <Button onClick={handleSend}>
                  <IoMdSend style={{ width: "25px", height: "25px" }} />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ChatList;
