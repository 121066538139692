import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FaCircleCheck } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../constants/Loader';
import axios from 'axios';
import { commaSeparatedPrice } from '../../../../constants/commaSeparatedPrice';
import PaymentStatusPage from './PaymentStatusPage';
import { generatePdf } from '../../../../constants/GeneratePdf';

function PaymentSucess() {
  const navigate = useNavigate();
  //redux
  const theme = useSelector((state) => state.themeReducer.theme);
  const { id,
    email,
    status,
    userid,
    username,
    reportmanager,
    userphone,
    userrole,
    userprofile,
    companyemail,
    companyid,
    clientimage,
    gender,
    country,
    state,
    pincode,
    city,
    address,
    address2,
    typeofdocument,
    documentvalue,
    language,
    timezone,
    signature,
    designation,
    pan,
    twitter,
    linkedin,
    clientname,
    clientemail,
    companyname,
    companyimage,
    employeeid,
    pf,
    companygst,
    companyregno,
    countrycode,
    countryaddress } = useSelector((state) => state.userDataReducer.userData || {})
  // console.log("pan", pan);

  const [params, setParams] = useState({
    transactionid: '',
    merchantid: '',
    date: '',
    amount: '',
    pay: ''
  });
  const [loadData, setLoadData] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const containerRef = useRef(null);

  useEffect(() => {
    const url = new URL(window.location.href);
    const pathParts = url.pathname.split('/');
    const transactionid = pathParts.find(part => part.startsWith('transactionid=')).split('=')[1];
    const merchantid = pathParts.find(part => part.startsWith('merchantid=')).split('=')[1];
    const date = pathParts.find(part => part.startsWith('date=')).split('=')[1];
    const amount = pathParts.find(part => part.startsWith('amount=')).split('=')[1];
    const pay = pathParts.find(part => part.startsWith('pay=')).split('=')[1];
    setParams({ transactionid, merchantid, date, amount, pay });
    fetchData();
  }, []);

  function getTransactionId() {
    const url = new URL(window.location.href);
    const pathParts = url.pathname.split('/');
    return pathParts.find(part => part.startsWith('transactionid=')).split('=')[1];
  }

  const fetchData = async () => {
    try {
      const body = {
        companyid: companyid,
        companyemail: companyemail
      }
      const response = await axios.post('/company/getpaymentdata', body, { withCredentials: true });
      const paymentData = response.data.find((item) => item.transactionId == getTransactionId());
      setPaymentData(paymentData);
    }
    catch (error) {
      console.log("Error in fetching payment status ", error)
    }
  }

  const handleApps = () => {
    setLoadData(true);
    setTimeout(() => {
      setLoadData(false);
      navigate("/frontend/apps")
    }, 1500)

  }

  const getSubTotalAmount = !paymentData?.isadditional
    ? Number(paymentData?.planprice) + Number(paymentData?.additionaluserprice) + Number(paymentData?.addonpackprice)
    : Number(paymentData?.additionalusercost) * Number(paymentData?.extrausers);
  const getTotalAmount = (Number(paymentData?.planprice) + Number(paymentData?.additionaluserprice) + Number(paymentData?.addonpackprice) - Number(paymentData?.coupondetails?.couponamount || 0)) + Number(paymentData?.tax);

  return (
    <div className={`section-payment ${theme}`}>
      <div className='payment-container grid'>
        <span className='payment-icon'><FaCircleCheck /></span>
        <h1 className='payment-status-heading'>Payment Success !</h1>
        <ul className='payment-details'>
          <li className=''>
            <span>Transaction ID:</span>
            <span>{params.transactionid}</span>
          </li>
          <li className=''>
            <span>Merchant ID:</span>
            <span>{params.merchantid}</span>
          </li>
          <li className=''>
            <span>Date:</span>
            <span>{params.date}</span>
          </li>
          <li className=''>
            <span>Payment Mode:</span>
            <span>{params.pay}</span>
          </li>
          <li className=''>
            <span>Amount:</span>
            <span>INR {commaSeparatedPrice(+params.amount)}</span>
          </li>
        </ul>
        <div className='payment-button'>
          {/* <ReactToPrint
            trigger={() => (
              <button className='commonBtn'>Get Invoice</button>
            )}
            content={() => containerRef.current}
          /> */}

          <button className='commonBtn' onClick={() => generatePdf(paymentData.invoiceid, containerRef)}>Get Invoice</button>

          <button className='commonBtn purchased' onClick={handleApps}>{loadData ? <Loader /> : "Go to Apps"}</button>
        </div>
      </div>

      {/* print invoice*/}
      <PaymentStatusPage getTransactionId={getTransactionId} containerRef={containerRef} />
    </div>
  )
}

export default PaymentSucess