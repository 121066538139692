import React, { useEffect } from 'react'
import { basic } from './PlanData';
import Loader from '../../../constants/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { plans } from '../../../redux/actions/plans.action';

function FreeTrial({ theme, handleFreeTrial, loadData }) {
    //Redux
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.loginReducer.token);
    const { id,
        email,
        status,
        userid,
        username,
        reportmanager,
        userphone,
        userrole,
        userprofile,
        companyemail,
        companyid,
        clientimage,
        gender,
        country,
        state,
        pincode,
        city,
        address,
        address2,
        typeofdocument,
        documentvalue,
        language,
        timezone,
        signature,
        designation,
        pan,
        twitter,
        linkedin,
        clientname,
        clientemail,
        companyname,
        companyimage,
        employeeid,
        pf,
        companygst,
        companyregno,
        countrycode,
        countryaddress,
    } = useSelector((state) => state.userDataReducer.userData || {})
    const planData = useSelector((state) => state.planReducer.planData);
    const { purchased, expiredate, daysleft } = planData || "";
    let showFreeTrial = true;
    showFreeTrial = !purchased == "yes"

    useEffect(() => {
        dispatch(plans({ "email": email }));
    }, []);

    return (
        <>
            <div className={`free-trail ${theme}`}>
                <p>Start Your 7-days Free Trial Now</p>
                <button className='commonBtn' onClick={() => handleFreeTrial(basic)}>
                    {
                        loadData ? <Loader /> : "Free-Trial"
                    }
                </button>
            </div>
        </>
    )
}

export default FreeTrial;