import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FaFile } from "react-icons/fa";
import axios from "axios";

function ViewModal({ handleTrigger, rowData }) {
  const [comment, setComment] = useState(rowData.comment);

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleComment = () => {
    axios
      .post(`https://vliv.app/ticket/comment`, {
        id: rowData && rowData.id,
        comment,
      })
      .then((res) => {
        handleTrigger();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Grid container>
      <Grid item sm={12} md={12} lg={12}>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
            Org Id: {rowData && rowData.id}
          </Typography>
        </Grid>
        <Divider />

        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            background: "#F4FEFE",
            padding: 4,
            mt: 1,
            maxHeight: "500px",
            overflowY: "auto",
          }}
        >
          <Grid container sx={{ padding: 1 }}>
            <Grid item sm={12} md={4} lg={4}>
              <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                Date
              </Typography>
            </Grid>
            <Grid item sm={12} md={8} lg={8}>
              <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                {rowData && rowData.date}
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item sm={12} md={4} lg={4}>
              <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                Organization name
              </Typography>
            </Grid>
            <Grid item sm={12} md={8} lg={8}>
              <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                {rowData && rowData.company}
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item sm={12} md={4} lg={4}>
              <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                Priority
              </Typography>
            </Grid>
            <Grid item sm={12} md={8} lg={8}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color:
                    rowData && rowData.priority === "High"
                      ? "red"
                      : rowData && rowData.priority === "Low"
                      ? "green"
                      : "orange",
                }}
              >
                {rowData && rowData.priority}
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item sm={12} md={4} lg={4}>
              <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                Subject
              </Typography>
            </Grid>
            <Grid item sm={12} md={8} lg={8}>
              <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                {rowData && rowData.subject}
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item sm={12} md={4} lg={4}>
              <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                Description
              </Typography>
            </Grid>
            <Grid
              item
              sm={12}
              md={8}
              lg={8}
              sx={{
                height: 250,
                overflowY: "auto",
                padding: 0.5,
                background: "#FFFF",
              }}
            >
              <Typography
                sx={{ fontSize: "14px", fontWeight: "400" }}
                dangerouslySetInnerHTML={{
                  __html: rowData && rowData.description[0],
                }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item sm={12} md={4} lg={4}>
              <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                Attachment
              </Typography>
            </Grid>
            <Grid item sm={12} md={8} lg={8}>
              {rowData?.attachment?.file?.map((file, index) => (
                <Typography key={index} sx={{ fontSize: "14px" }}>
                  <Tooltip title={`View Attachment ${index + 1}`}>
                    <a
                      href={file}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFile
                        style={{ color: "#007bff", cursor: "pointer" }}
                      />{" "}
                      {`Attachment ${index + 1}`}
                    </a>
                  </Tooltip>
                </Typography>
              ))}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item sm={12} md={4} lg={4}>
              <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                Status
              </Typography>
            </Grid>
            <Grid item sm={12} md={8} lg={8}>
              <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                {rowData && rowData.status}
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item sm={12} md={4} lg={4}>
              <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                Assign to
              </Typography>
            </Grid>
            <Grid item sm={12} md={8} lg={8}>
              <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                {rowData && rowData.backendassignee}
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item sm={12} md={4} lg={4}>
              <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                Comment
              </Typography>
            </Grid>
            <Grid item sm={12} md={8} lg={8}>
              <input
                style={{
                  width: "300px",
                  height: "70px",
                  padding: "5px",
                  borderRadius: "5px",
                  border: "solid 1px black",
                }}
                placeholder="Add comment"
                value={comment}
                onChange={handleCommentChange}
              />
              <Button
                sx={{
                  border: "solid 1px grey",
                  backgroundColor: "#37668F",
                  "&:hover": {
                    backgroundColor: "#37668F",
                  },
                  color: "#FFFF",
                  width: "100px",
                  borderRadius: "5px",
                  ml: 1,
                  mt: 0.5,
                }}
                onClick={handleComment}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ViewModal;
