import React, { useEffect, useState } from 'react';
import { MdCancel } from 'react-icons/md';
import { RxCrossCircled } from 'react-icons/rx';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function PaymentFailure() {
  //redux
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useSelector((state) => state.themeReducer.theme);

  const [params, setParams] = useState({
    transactionid: '',
    merchantid: '',
    date: '',
    amount: '',
    pay: ''
  });
  const [loadData, setLoadData] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    const pathParts = url.pathname.split('/');

    const transactionid = pathParts.find(part => part.startsWith('transactionid=')).split('=')[1];
    const merchantid = pathParts.find(part => part.startsWith('merchantid=')).split('=')[1];
    const date = pathParts.find(part => part.startsWith('date=')).split('=')[1];
    const amount = pathParts.find(part => part.startsWith('amount=')).split('=')[1];
    const pay = pathParts.find(part => part?.startsWith('pay='))?.split('=')[1];

    setParams({ transactionid, merchantid, date, amount });
  }, []);

  const handleApps = () => {
    setLoadData(true);
    setTimeout(() => {
      setLoadData(false);
      navigate("/frontend/home")
    }, 1500)
  }

  return (
    <div className={`section-payment ${theme}`}>
      <div className='payment-container grid'>
        <span className='payment-icon failure'><MdCancel /></span>
        <h1 className='payment-status-heading'>Payment Failure !</h1>
        <ul className='payment-details'>
          <li className=''>
            <span>Transaction ID:</span>
            <span>{params.transactionid}</span>
          </li>
          <li className=''>
            <span>Merchant ID:</span>
            <span>{params.merchantid}</span>
          </li>
          <li className=''>
            <span>Date:</span>
            <span>{params.date}</span>
          </li>
          <li className=''>
            <span>Payment Mode:</span>
            <span>{params.pay}</span>
          </li>
          <li className=''>
            <span className='amount'>Amount:</span>
            <span>INR{params.amount}</span>
          </li>
        </ul>
        <div className='payment-button'>
          <button className='commonBtn purchased' onClick={handleApps}>Try again</button>
        </div>
        <span className='payment-failed--terms'>If your payment was debited but the transaction failed, rest assured that the amount will be refunded to your account within 5 business days.</span>
        <span className='payment-failed--terms'>If you have any issues or require further assistance, please contact our support team at <a href='mailto:info@vliv.app' className='contact-us-link'>info@vliv.app</a> with details related to the transaction.</span>

      </div>
    </div>
  )
}

export default PaymentFailure