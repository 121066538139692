import React from 'react'
import { useSelector } from 'react-redux';

function Redirect({ path }) {
    //Redux
    const theme = useSelector((state) => state.themeReducer.theme);
    return (
        <a href={`${path}`} target="_blank" style={{
            color: theme === "light" ? 'var(--linked-color)' : "var(--dark-link-color)",
            textDecoration: 'underline',
            fontSize: "1.6rem"
        }}>
            {path}
        </a>
    );
}

export default Redirect

