import React from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { notifyError } from "../constants/toastAlerts";
import FrontendLayout from "../Components/Sidebar/FrontendLayout";
import { useSelector } from "react-redux";


const FrontendProtectRoute = () => {
  const isLoggedIn = useSelector((state) => state.userDataReducer?.token || '');
  if (!isLoggedIn) {
    return <Navigate to="/frontend/login" />;
  }

  return <FrontendLayout />;
};

export default FrontendProtectRoute;