import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

import {
  Box,
  Button,
  Divider,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import userimg from "../../../assets/profile_image.jpg";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useNavigate } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import axios from "axios";
import { companyUserUpdate, updateProfile } from "../../../constants/api";
import AlertPopUp from "../../../constants/AlertPopUp";
import noimage from "../../../assets/no-profile.png";
import { useDispatch, useSelector } from "react-redux";
import { notifyError, notifySuccess } from "../../../constants/toastAlerts";
import { reloadPage } from "../../../redux/actions/load.action";

const allCountries = Country.getAllCountries();

const EditProfile = () => {
  const dispatch = useDispatch();
  const { id,
    email,
    status,
    userid,
    username,
    reportmanager,
    userphone,
    userrole,
    userprofile,
    companyemail,
    companyid,
    clientimage,
    gender,
    country,
    state,
    pincode,
    city,
    address,
    address2,
    typeofdocument,
    documentvalue,
    language,
    timezone,
    signature,
    designation,
    pan,
    twitter,
    linkedin,
    clientname,
    clientemail,
    companyname,
    companyimage,
    employeeid,
    pf,
    companygst,
    companyregno,
    countrycode,
    countryaddress, tan, un, esi } = useSelector((state) => state.userDataReducer.userData || {})

  const newUserName = username?.split(" ")


  const [data, setData] = useState({
    id: id,
    email: "",
    password: "",
    status: "",
    userid: "",
    username: "",
    reportmanager: "",
    userphone: userphone,
    userrole: "",
    userprofile: "",
    usermodules: [],
    companyemail: "",
    companyid: "",
    clientimage: "",
    gender: gender,
    country: "",
    state: "",
    pincode: pincode,
    city: city,
    address: address,
    language: language,
    timezone: "",
    signature: signature,
    designation: designation,
    pan: pan,
    twitter: twitter,
    linkedin: linkedin,
    clientname: clientname,
    clientemail: clientemail,
    companyname: companyname,
    companyimage: companyimage,
    tan: tan,
    un: un,
    esi: esi,
    employeeid: employeeid,
    pf: pf,
    companygst: companygst,
    companyregno: companyregno,
    countrycode: "",
    firstname: newUserName[0],
    lastname: newUserName[1]

  });
  const [selectedCoutryTimezone, setSelectedCountryTimezone] = useState([]);
  const [selectedCountriesStates, SetSelectedCountriesStates] = useState([]);
  const isProduction = process.env.NODE_ENV === 'production';

  const navigate = useNavigate();
  //for  alert
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  //for alert

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });

    if (name == "country") {
      if (value) {
        setData((prev) => ({ ...prev, timezone: "", state: "" }));
        const selectedCountry = Country.getCountryByCode(value);

        setData((prev) => ({
          ...prev,
          country: selectedCountry.name,
          currency: selectedCountry.currency,
          countrycode: selectedCountry.isoCode,
        }));
        const states = State.getStatesOfCountry(selectedCountry.isoCode) || [];
        SetSelectedCountriesStates((prev) => states);
        setSelectedCountryTimezone((prev) => selectedCountry.timezones);
      } else {
        setData((prev) => ({
          ...prev,
          country: "",
          currency: "",
          countrycode: "",
        }));
        SetSelectedCountriesStates([]);
        setSelectedCountryTimezone([]);
      }
    }
  };

  const handleUpdate = async () => {
    dispatch(reloadPage(true))
    try {

      let res = await axios.post(companyUserUpdate, data, {withCredentials:true});
      
      if (res.data.message === "User updated successfully") {
        const { password, confirmpassword, ...userData } = res.data.data;

        // Get existing cookie data
        const existingCookie = Cookies.get("userinfo");
        let parsedCookie = {};

        if (existingCookie) {
          parsedCookie = JSON.parse(existingCookie); // Parse existing cookie data
        }

        // Filter userData.user to only include keys that exist in the cookie
        const filteredUserData = Object.keys(userData.user).reduce((acc, key) => {
          if (key in parsedCookie) {
            acc[key] = userData.user[key];
          }
          return acc;
        }, {});

        // Merge the filtered data with the existing cookie data
        const updatedCookieData = { ...parsedCookie, ...filteredUserData };
        // console.log("updatedCookieData", updatedCookieData)
        // Update the cookie with merged data
        Cookies.set("userinfo", JSON.stringify(updatedCookieData),
          {
            domain: isProduction ? '.vliv.app' : 'localhost',
            path: '/',
            // secure: isProduction,
            sameSite: isProduction ? 'None' : 'Lax'
          });

        setTimeout(() => {
          notifySuccess(res.data.message)
          navigate("/frontend/profile");
        }, 1000);
      } else {
        notifyError(res.data.message)
      }
      dispatch(reloadPage(false))
    } catch (error) {
      console.log(error);

      notifyError(error.response.data.message);

    }
  };

  // for file uploads

  const handleImageChange = (e) => {
    const files = e.target.files;
    let url = "https://vliv.app/company/clientimage";

    if (e.target.name == "companyimage") {
      url = "https://vliv.app/company/companyimage";
    }

    if (files.length > 0) {
      handleUploadFile(files, url, e.target.name);
    } else {
      setOpen(true);
      setAlertType("error");

      setMsg("Choose an image to upload..");
    }
  };

  const handleUploadFile = async (files, url, name) => {
    try {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
      const response = await axios.post(
        url,

        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      response.data.file
        ? setData((prev) => ({ ...prev, [name]: response.data.file }))
        : setData((prev) => ({ ...prev, [name]: "" }));

    } catch (error) {
      setAlertType("error");
      setOpen(true);
      setMsg("Error in uploading file..");
      console.error("Error uploading file:", error.message);
      throw error;
    }
  };

  //for file uploads end
  useEffect(() => {
    const cookieData = Cookies.get("userinfo");
    let userData = cookieData ? JSON.parse(cookieData) : {};
    // console.log("userData", userData)
    // const splitName = userData.userrole == "masteradmin" ? userData.username ? userData.username.split(" ") : [] : userData.username ? userData.username.split(" ") : []
    // if (splitName.length > 0) {
    //   userData.firstname = splitName[0] || "";
    //   userData.lastname = splitName[1] || "";
    // }
    setData((prev) => ({ ...prev, ...userData }));
  }, []);

  useEffect(() => {
    if (data.countrycode) {
      const allStates = State.getStatesOfCountry(data.countrycode);
      SetSelectedCountriesStates((prev) => allStates);
      const selectedCountry = Country.getCountryByCode(data.countrycode);
      setSelectedCountryTimezone((prev) => selectedCountry.timezones);
    } else {
      SetSelectedCountriesStates([]);
      setSelectedCountryTimezone([]);
    }
  }, [data.countrycode]);

  if (open) {
    return (
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    );
  }

  return (
    <>
      <Box sx={{ flexGrow: 1, fontSize: "1.2rem" }}>
        <Grid
          container
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <Grid item md={5} sm={12} xs={12} lg={6}>
            <div style={{ display: "flex", alignItems: "center", gap: 7 }}>
              <div>
                <input
                  type="file"
                  id="profileImageInput"
                  accept="image/*"
                  style={{ display: "none" }}
                  name="clientimage"
                  onChange={handleImageChange}
                />
                <label htmlFor="profileImageInput">
                  <div
                    id="profileImageContainer"
                    style={{
                      width: "90px",
                      height: "90px",
                      borderRadius: "50%",
                      overflow: "hidden",
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={!data.clientimage ? noimage : data.clientimage}
                      alt="Profile Image"
                      id="profileImage"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        filter: "blur(0.5px)",
                      }}
                    />
                    <div
                      id="uploadIcon"
                      style={{
                        position: "absolute",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                    >
                      <PhotoCameraIcon />
                    </div>
                  </div>
                </label>
              </div>
              <div>
                <h3 style={{ marginBottom: "5px" }}>
                  {data.userrole == "masteradmin" ? username : username}
                </h3>
                <p>{data.userrole == "masteradmin" ? data.clientemail : data.email}</p>
              </div>
            </div>
          </Grid>
          <Grid item md={5} sm={12} xs={12} lg={6}>
            <div style={{ display: "flex", alignItems: "center", gap: 7 }}>
              <div>
                {
                  data.userrole == "masteradmin" ? <input
                    type="file"
                    id="companyImageInput"
                    accept="image/*"
                    style={{ display: "none" }}
                    name="companyimage"
                    onChange={handleImageChange}
                  /> : null
                }
                <label htmlFor="companyImageInput">
                  <div
                    id="companyImageContainer"
                    style={{
                      width: "90px",
                      height: "90px",
                      borderRadius: "50%",
                      overflow: "hidden",
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={!data.companyimage ? noimage : data.companyimage}
                      alt="Company Image"
                      id="companyImage"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        filter: "blur(0.5px)", // Apply blur effect here
                      }}
                    />
                    {
                      data.userrole == "masteradmin" ? <div
                        id="uploadIcon"
                        style={{
                          position: "absolute",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      >
                        <PhotoCameraIcon />
                      </div> : null
                    }
                  </div>
                </label>
              </div>
              <div>
                <h3 style={{ marginBottom: "5px" }}>
                  {data.companyname ? data.companyname : "N/A"}
                </h3>
              </div>
            </div>
          </Grid>
          {/* <Grid item md={2} >
            <div>
              <Button
                variant="outlined"
                endIcon={<ModeEditIcon />}
                sx={{ borderRadius: "10px", color: "#0B4A6F" }}
                disabled
              >
                Edit
              </Button>
            </div>
          </Grid> */}
        </Grid>
        <Divider sx={{ marginY: 1 }} />
        <Grid container sx={{ padding: "5px 10px" }}>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>First Name</h4>
            <TextField
              fullWidth
              className="formInputs"
              value={data.firstname}
              onChange={handleChange}
              name="firstname"
            />
          </Grid>
          <Grid item md={2}></Grid>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>Last Name</h4>
            <TextField
              fullWidth
              className="formInputs"
              value={data.lastname}
              onChange={handleChange}
              name="lastname"
            />
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "5px 10px" }}>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>Gender</h4>
            <FormControl fullWidth className="formInputs">
              <Select
                value={data.gender}
                inputProps={{ className: "selectInput" }}
                onChange={handleChange}
                name="gender"
              >
                <MenuItem value={"Male"}>Male</MenuItem>
                <MenuItem value={"Female"}>Female</MenuItem>
                <MenuItem value={"Others"}>Others</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2}></Grid>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>Organisation Name</h4>
            <TextField
              fullWidth
              className="formInputs"
              value={data.companyname}
              onChange={handleChange}
              name="companyname"
              disabled={data.userrole !== "masteradmin"}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "5px 10px" }}>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>Country</h4>
            <FormControl fullWidth className="formInputs">
              <Select
                value={data.countrycode}
                inputProps={{ className: "selectInput" }}
                onChange={handleChange}
                name="country"
              >
                {allCountries?.map((country, ind) => (
                  <MenuItem
                    value={country.isoCode}
                    key={ind}
                    sx={{ textAlign: "left" }}
                  >
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2}></Grid>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>State</h4>
            <FormControl fullWidth className="formInputs">
              <Select
                value={data.state}
                inputProps={{ className: "selectInput" }}
                name="state"
                onChange={handleChange}
              >
                {selectedCountriesStates?.map((state, ind) => (
                  <MenuItem
                    value={state.name}
                    key={ind}
                    sx={{ textAlign: "left" }}
                  >
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "5px 10px" }}>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>City</h4>
            <TextField
              fullWidth
              className="formInputs"
              value={data.city}
              onChange={handleChange}
              name="city"
            />
          </Grid>
          <Grid item md={2}></Grid>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>Pin Code</h4>
            <TextField
              fullWidth
              className="formInputs"
              value={data.pincode}
              onChange={handleChange}
              name="pincode"
            />
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "5px 10px" }}>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>Address</h4>
            <TextField
              fullWidth
              className="formInputs"
              value={data.address}
              onChange={handleChange}
              name="address"
            />
          </Grid>
          <Grid item md={2}></Grid>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>Signature</h4>
            <TextField
              fullWidth
              className="formInputs"
              value={data.signature}
              onChange={handleChange}
              name="signature"
            />
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "5px 10px" }}>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>Language</h4>
            <FormControl fullWidth className="formInputs">
              <Select
                value={data.language}
                inputProps={{ className: "selectInput" }}
                name="language"
                onChange={handleChange}
              >
                <MenuItem value="English" sx={{ textAlign: "left" }}>
                  English
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2}></Grid>
          <Grid item md={4} sm={12} xs={12} >
            <h4 style={{ marginBottom: "5px" }}>Timezone</h4>
            <FormControl fullWidth className="formInputs">
              <Select
                value={data.timezone}
                inputProps={{ className: "selectInput" }}
                onChange={handleChange}
                name="timezone"
              >
                {selectedCoutryTimezone?.map((timeZone, ind) => (
                  <MenuItem
                    value={timeZone.zoneName}
                    key={ind}
                    sx={{ textAlign: "left" }}
                  >
                    {timeZone.zoneName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Divider sx={{ marginY: 2 }} />
        <Grid container sx={{ padding: "5px 10px" }}>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>E-Mail ID</h4>
            <TextField
              fullWidth
              className="formInputs"
              value={data.userrole == "masteradmin" ? data.clientemail : data.email}
              onChange={handleChange}
              name={data.userrole == "masteradmin" ? "clientemail" : "email"}
            />
          </Grid>
          <Grid item md={2}></Grid>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>Employee ID</h4>
            <TextField
              fullWidth
              className="formInputs"
              value={data.employeeid}
              onChange={handleChange}
              name="employeeid"
              disabled={data.userrole !== "masteradmin"}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "5px 10px" }}>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>Phone Number</h4>
            <TextField
              fullWidth
              className="formInputs"
              value={data.userphone}
              onChange={handleChange}
              name="userphone"
            />
          </Grid>
          <Grid item md={2}></Grid>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>Designation</h4>
            <TextField
              fullWidth
              className="formInputs"
              value={data.designation}
              onChange={handleChange}
              name="designation"
              disabled={data.userrole !== "masteradmin"}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "5px 10px" }}>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>GSTIN</h4>
            <TextField
              fullWidth
              className="formInputs"
              value={data.companygst}
              onChange={handleChange}
              name="companygst"
              disabled={data.userrole !== "masteradmin"}
            />
          </Grid>
          <Grid item md={2}></Grid>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>PAN</h4>
            <TextField
              fullWidth
              className="formInputs"
              value={data.pan}
              onChange={handleChange}
              name="pan"
            />
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "5px 10px" }}>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>TAN</h4>
            <TextField
              fullWidth
              className="formInputs"
              value={data.tan}
              onChange={handleChange}
              name="tan"
            />
          </Grid>
          <Grid item md={2}></Grid>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>UN</h4>
            <TextField
              fullWidth
              className="formInputs"
              value={data.un}
              onChange={handleChange}
              name="un"
            />
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "5px 10px" }}>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>PF</h4>
            <TextField
              fullWidth
              className="formInputs"
              value={data.pf}
              onChange={handleChange}
              name="pf"
              disabled={data.userrole !== "masteradmin"}
            />
          </Grid>
          <Grid item md={2}></Grid>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>ESI</h4>
            <TextField
              fullWidth
              className="formInputs"
              value={data.esi}
              onChange={handleChange}
              name="esi"
              disabled={data.userrole !== "masteradmin"}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "5px 10px" }}>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>Twitter</h4>
            <TextField
              fullWidth
              className="formInputs"
              value={data.twitter}
              onChange={handleChange}
              name="twitter"
            />
          </Grid>
          <Grid item md={2}></Grid>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>Linked In</h4>
            <TextField
              fullWidth
              className="formInputs"
              value={data.linkedin}
              onChange={handleChange}
              name="linkedin"
            />
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "5px 10px" }}>
          <Grid item md={4} sm={12} xs={12}>
            <h4 style={{ marginBottom: "5px" }}>Registration Certificate No</h4>
            <TextField
              fullWidth
              className="formInputs"
              value={data.companyregno}
              onChange={handleChange}
              name="companyregno"
              disabled={data.userrole !== "masteradmin"}
            />
          </Grid>
        </Grid>
        <Divider sx={{ marginY: 2 }} />
        <Grid container sx={{ padding: "5px 10px", margin: "5px" }}>
          <Grid item md={6} sx={{ display: "flex", gap: "0px 5px" }}>
            <Button className="saveBtn" onClick={handleUpdate}>
              Update
            </Button>
            <Button
              className="cancelBtn"
              variant="outlined"
              onClick={() => navigate("/frontend/profile")}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EditProfile;
