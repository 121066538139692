import * as Yup from "yup";

// export const signupSchema = Yup.object({
//   companyname: Yup.string()
//     .min(5)
//     .max(255)
//     .required("Please enter your company name"),
//   email: Yup.string().email().required("Please enter your email"),
//   contactnumber: Yup.string()
//     .min(10)
//     .max(20)
//     .required("Please enter your contact number"),
//   password: Yup.string().min(7).required("Please enter password"),
//   cpassword: Yup.string()
//     .required("Please enter your confirm password")
//     .oneOf([Yup.ref("password"), null], "Password must match"),
//   country: Yup.string().min(3).required("Please enter country"),
//   terms: Yup.boolean()
//     .oneOf([true], "Please check the Terms and Conditions.")
//     .required("Please check the Terms and Conditions."),
// });

// export const loginSchema = Yup.object({
//   userid: Yup.string().required("User ID is required"),
//   password: Yup.string().min(7).required("Password is required"),
//   finalcialyear: Yup.string().required("Select any option")
// });

// export const forgotpasswordSchema = Yup.object({
//   userid: Yup.string().required("User ID is required"),
//   password: Yup.string().min(7).required("Please enter your password"),
//   cpassword: Yup.string().oneOf([Yup.ref("password"), null], "Password must match").required("Please enter your new confirm password"),
// });


export const contactusSchema = Yup.object({
  name: Yup.string().min(4).required('Please enter your name'),
  email: Yup.string().email().required("Please enter your email"),
  mobilenumber: Yup.string()
    .matches(/^[0-9\s\-()+]*$/, 'Phone number is not valid')
    .min(10, 'Phone number must be at least 10 digits')
    .max(15, 'Phone number cannot exceed 15 digits'),
  company: Yup.string().min(3).required("Please enter your company name"),
  companysize: Yup.string().required("Please Select any option")
})

export const billingSchema = Yup.object({
  companyname: Yup.string().required('Company name is required'),
  companygst: Yup.string().required('Company GSTIN is required'),
  billingaddress: Yup.string().required('Billing address is required'),
  emails: Yup.array().of(
    Yup.string().email('Invalid email format').required('Email is required')
  )
})

export const partnershipSchema = Yup.object({
  type: Yup.string().required('Please Select any kind of partnership'),
  name: Yup.string().min(4).required('Please enter name'),
  website: Yup.string().min(4).required('Please enter your company website'),
  size: Yup.string().required("Please Select any option"),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  contactno: Yup.string()
    .matches(/^[0-9\s\-()+]*$/, 'Phone number is not valid')
    .min(10, 'Phone number must be at least 10 digits')
    .max(15, 'Phone number cannot exceed 15 digits'),
  country: Yup.string().min(4).required('Please Select your country'),
  services:Yup.string().min(4).required('Please enter your service'),
})