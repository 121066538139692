import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { notifySuccess } from '../../../constants/toastAlerts';
import AffilateCards from './AffilateCards';
import greenVector from "../../../assets/greenVector.webp";
import goldVector from "../../../assets/goldVector.webp";
import pinkVector from "../../../assets/pinkVector.webp";
import { useNavigate } from 'react-router-dom';

function Partnershipwithus() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
     //Redux
    const theme = useSelector((state) => state.themeReducer.theme);

    const handleClick = () => {
        // notifySuccess("This page is under construction please wait it will comming soon")
        navigate("/partnership-program-form");
    }

    return (
        <>
            {/* Partnership hero section start*/}
            <section className={`section-partnership--hero ${theme}`} data-aos="fade-up">
                <div className={`container hero-container`} >
                    <h1>Partner with Us </h1>
                    <h3>Unlock new revenue streams and grow with us!</h3>
                    <h3>Our partner programs are designed to reward your efforts and provide you with the resources you need to succeed. Whether you want to become an affiliate, refer clients, or be an implementation partner,</h3>
                    <h3>Vliv invites you to join our thriving network</h3>
                    <div className='buttonContainer'>
                        <button type='submit' className='commonBtn' onClick={handleClick}>Join Now !</button>
                    </div>
                </div>
            </section>
            {/* Partnership hero section end*/}

            {/* Partnership affilateprogram section start*/}
            <section className={`section-affiliate--program ${theme}`} data-aos="fade-up">
                <div className={`container hero-container`}>
                    <div className='affiliate-buttonContainer'>
                        <span className='commonBtn'>Affiliate Program</span>
                    </div>
                    <div className='affiliate-text--container'>
                        <h3 className={`affiliate-desc ${theme}`}>Love Vliv? </h3>
                        <h3 className={`affiliate-desc ${theme}`}>Share it with your network and earn lucrative commissions. Our affiliate program is structured to reward your commitment and performance. Join any of our affiliate tiers to earn rewards and benefits, While helping us grow </h3>
                    </div>
                    <div className='affiliate-container'>
                        <h1 className={`common-heading ${theme == "dark" && "dark"}`}>Affiliate Tiers </h1>
                        <div className='grid grid-three--cols affiliate-cards--container'>
                            <AffilateCards
                                cardname="Silver Affiliates"
                                title="Perfect for those just starting."
                                desc="Perfect for those just starting. Gain access to essential promotional tools and start earning commissions with ease. As you grow, so do your benefits. "
                                color="green"
                                image={greenVector}

                            />

                            <AffilateCards
                                cardname="Gold Affiliates"
                                title="Elite status for our top performers. "
                                desc="For affiliates who consistently deliver results. Benefit from competitive commission rates, advanced marketing support, and dedicated assistance to scale your efforts. "
                                color="gold"
                                image={goldVector}
                            />

                            <AffilateCards
                                cardname="Platinum Affiliates"
                                title="For affiliates who consistently deliver results."
                                desc="Elite status for our top performers. Enjoy the highest commission rates, exclusive marketing resources, priority support, and personalized guidance to maximize your earnings. "
                                color="pink"
                                image={pinkVector}
                            />
                        </div>
                    </div>
                    <div>
                        <h1 className={`common-heading ${theme == "dark" && "dark"}`}>Why Partner with Us?  </h1>
                        <div className='grid grid-two--cols why-partnerwithus--card'>
                            <div className='partnerwithus-card'>
                                <p>Generous commissions for every successful referral </p>
                                <p>A wide range of promotional materials and resources </p>
                                <p>Join a vibrant community and contribute to mutual success </p>
                            </div>
                            <div className='partnerwithus-card'>
                                <p>Special incentives for top-tier affiliates </p>
                                <p>Dedicated affiliate management team for support and success tips </p>
                                <p>Real-time tracking and reporting dashboard  </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Partnership affilateprogram section end*/}

            {/* Partnership implementprogram section start*/}
            <section className={`section-implement--program ${theme}`} data-aos="fade-up">
                <div className={`container hero-container`}>
                    <div className='affiliate-buttonContainer'>
                        <span className='commonBtn'>Implementation Partner Program </span>
                    </div>
                    <div className='affiliate-implementation-program'>
                        <h3 className={`affiliate-desc ${theme}`}>Are you an expert in deploying SaaS solutions? </h3>
                        <h3 className={`affiliate-desc ${theme}`}>Partner with us to deliver top-notch implementations of Vliv to businesses around the globe. As an implementation partner, you'll help our clients transition smoothly, ensuring they get the most out of their investment. </h3>
                    </div>
                    <div className=''>
                        <h1 className={`common-heading ${theme == "dark" && "dark"}`}>Benefits of Becoming an Implementation Partner </h1>
                        <div className='grid grid-two--cols why-partnerwithus--card'>
                            <div className='partnerwithus-card'>
                                <p>Comprehensive training and resources to become a Vliv.app implementation expert </p>
                                <p>Close collaboration with our product team for insights and updates </p>
                                <p>Priority technical support access </p>
                            </div>
                            <div className='partnerwithus-card'>
                                <p>Competitive compensation and project referrals </p>
                                <p>Recognition on our website as a trusted implementation partner </p>
                                <p>Marketing support to promote your services to our client base</p>
                            </div>
                        </div>
                    </div>
                    <div className='buttonContainer'>
                        <button type='submit' className='commonBtn' onClick={handleClick}>Get Started Today !</button>
                    </div>
                </div>
            </section>
            {/* Partnership implementprogram section end*/}

            {/* Partnership brand section start*/}
            <section className={`section-partnership--hero ${theme}`} data-aos="fade-up">
                <div className={`container hero-container`}>
                    <h3>Becoming a Vliv partner is straightforward and rewarding. Whether you want to earn through referrals or by implementing our solutions, we provide all the tools and support you need. </h3>
                </div>
            </section>
            {/* Partnership brand section end*/}

            {/* Partnership contact section start*/}
            <section className={`section-affiliate-contactus ${theme}`} data-aos="fade-up">
                <div className='container'>
                    <h1 className={`common-heading ${theme == "dark" && "dark"}`}>Join us now and let's grow together! </h1>
                    <p className='affiliate-contactus-text'>Enter your details below or Email us at <a href='mailto:info@vliv.app' target='_blank' className={`emaillink ${theme}`}>info@vliv.app</a> to sign up and start your journey. </p>
                    <div className='buttonContainer'>
                        <button type='submit' className='commonBtn' onClick={handleClick}>Implementation Partner Program </button>
                    </div>
                </div>
            </section>
            {/* Partnership contact section end*/}
        </>
    )
}

export default Partnershipwithus;