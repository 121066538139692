import React from 'react';
import PagenotfoundImg from "../../src/assets/404 page is not found.webp"

const PageNotFound = () => {
  return (
    <>
    {/* <h1 style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>Page Not Found...</h1>
     */}
     <img src={PagenotfoundImg} alt="page-not-found" style={{width:"100%",height:"auto"}}/>
    </>
  )
}

export default PageNotFound