import {
  Box,
  Button,
  Container,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AddBlog from "./AddBlog/AddBlog";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import EditBlog from "./EditBlog/EditBlog";
import { FaPen, FaRegTrashAlt } from "react-icons/fa";
import NavbarBackend from "../Navbar/NavbarBackend";

import '../Home/AccessManagement/Accessmanagement.css'



function Blog() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState([]);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [id, setId] = useState("");

  const handleTriggerCreateBlog = () => {
    setIsOpenAddModal(!isOpenAddModal);
  };

  const handleTriggerUpdateBlog = (id) => {
    setId(id);
    setIsOpenUpdateModal(!isOpenUpdateModal);
  };

  const handleDelete = (id) => {
    axios
      .post(`https://vliv.app/blog/delete`, { id })
      .then((res) => {
        alert(res.data.message);
        setRefresh(!refresh)
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    axios
      .post(`https://vliv.app/blog/get`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, [refresh]);

  const handleViewBlog = (id) => {
    navigate(`/backend/view/blog/${id}`);
  };

  const columns = [
    {
      field: "cardtitle",
      headerName: "Title",
      flex: 1,
     
    },
    {
      field: "username",
      headerName: "Created By",
      flex: 1,
     
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
     
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleViewBlog(params.row.id)}>
            <IoEyeOutline />
          </IconButton>

          <IconButton onClick={() => handleTriggerUpdateBlog(params.row.id)}>
            <FaPen />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)}>
          <FaRegTrashAlt />
          </IconButton>
        </>
      ),
    },
  ];

  const rows = data;

  return (
    <>
   
        <Box maxWidth="xl" className="blog-section">
          <Typography variant="h6" gutterBottom>
            {/* Blog */}
          </Typography>
          <Box
            style={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}
          >
            <TextField
              // label="Search"
              placeholder='Search'
              variant="outlined"
              size="md"
              sx={{ marginBottom: 2, width: 400}}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <Button
              onClick={handleTriggerCreateBlog}
              sx={{
                background: "#37668F",
                "&:hover": { background: "#37668F" },
                color: "white",
                height: "37px",
              }}
            >
              Add Blog
            </Button>
          </Box>
          <Box>
            <DataGrid
              // sx={{
              //   "& .MuiDataGrid-columnHeaders": {
              //     backgroundColor: "rgb(216, 216, 216)",
              //   },
              //   "& .MuiDataGrid-columnHeaderTitle": {
              //     color: "black",
              //     fontWeight: "bold",
              //   },
              //   "& .MuiDataGrid-columnHeader": {
              //     backgroundColor: "rgb(216, 216, 216)",
              //   },
              //   "& .MuiDataGrid-columnHeader--sortable:hover": {
              //     backgroundColor: "rgb(200, 200, 200)",
              //   },
              // }}
              autoHeight
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
            />
          </Box>
        </Box>
  

      {/* For Create blog */}
      <Modal
        open={isOpenAddModal}
        onClose={handleTriggerCreateBlog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: "150vh",
            height: "90vh",
          }}
        >
          <AddBlog
            refresh={refresh}
            setRefresh={setRefresh}
            handleTriggerCreateBlog={handleTriggerCreateBlog}
          />
        </Box>
      </Modal>

      {/* For Update blog */}
      <Modal
        open={isOpenUpdateModal}
        onClose={handleTriggerUpdateBlog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: "150vh",
            height: "90vh",
          }}
        >
          <EditBlog
            refresh={refresh}
            setRefresh={setRefresh}
            handleTriggerUpdateBlog={handleTriggerUpdateBlog}
            id={id}
          />
        </Box>
      </Modal>
    </>
  );
}

export default Blog;
