import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { HiMiniUserGroup } from "react-icons/hi2";
import { TbBulbFilled } from "react-icons/tb";
import { FaCheckCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { plans } from '../../../redux/actions/plans.action';
import Loader from '../../../constants/Loader';
import { frontendlocaldms, frontendlocalhrms, frontendlocalklms, frontendproductiondms, frontendproductionhrms, frontendproductionklms } from '../../../constants/api';
import PageLoading from '../../../constants/PageLoading';
import { notifySuccess, notifyWarning } from '../../../constants/toastAlerts';
import Cookies from "js-cookie";
import { updateLoginHistory } from '../../../redux/actions/login.action';
import { PiFileDocLight } from "react-icons/pi";

const STATE = {
  HRMS: "HRMS",
  KLMS: "KLMS",
  DMS: "DMS",

}


function Apps() {
  //cokies information
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let loginhistoryid = userData.loginhistoryid ? userData.loginhistoryid : "";

  const isProduction = process.env.NODE_ENV === 'production';
  //redux
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useSelector((state) => state.themeReducer.theme);
  const { id,
    email,
    status,
    userid,
    username,
    reportmanager,
    userphone,
    userrole,
    userprofile,
    companyemail,
    companyid,
    clientimage,
    gender,
    country,
    state,
    pincode,
    city,
    address,
    address2,
    typeofdocument,
    documentvalue,
    language,
    timezone,
    signature,
    designation,
    pan,
    twitter,
    linkedin,
    clientname,
    clientemail,
    companyname,
    companyimage,
    employeeid,
    pf,
    companygst,
    companyregno,
    countrycode,
    countryaddress } = useSelector((state) => state.userDataReducer.userData || {})
  const planData = useSelector((state) => state.planReducer.planData);
  const { purchased, planname, expiredate, daysleft } = planData || "";
  // console.log("userrole", userrole)
  //State
  const [loadData, setLoadData] = useState();

  useEffect(() => {
    dispatch(plans({ "email": email }));
    dispatch(updateLoginHistory({
      loginhistoryid,
      module: "VLIV"
    }));
  }, []);

  let hrmsbuttonName;
  let klmsbuttonName;
  let dmsbuttonName

  if (!planname) {
    hrmsbuttonName = "Buy Plan";
    klmsbuttonName = "Buy Plan";
    dmsbuttonName = "Buy Plan";
  }
  else if (planname != "Basic") {
    hrmsbuttonName = "Launch";
    klmsbuttonName = "Launch";
    dmsbuttonName = "Launch";
  }
  else if (planname == "Basic") {
    hrmsbuttonName = "Launch";
    klmsbuttonName = "Buy Plan";
    dmsbuttonName = "Launch";
  } else {
    hrmsbuttonName = "Buy Plan";
    klmsbuttonName = "Buy Plan";
    dmsbuttonName = "Buy Plan";
  }

  const handleButton = async (launch) => {
    // console.log(purchased)
    if (hrmsbuttonName == "Buy Plan" && launch == "HRMS" && !purchased) {
      navigate("/frontend/home")
    }
    else if (klmsbuttonName == "Buy Plan" && launch == "KLMS" && !purchased) {
      navigate("/frontend/home")
    }
    else if (dmsbuttonName == "Buy Plan" && launch == "DMS" && !purchased) {
      navigate("/frontend/home")
    }
    else if (klmsbuttonName == "Buy Plan" && launch == "KLMS" && purchased) {
      navigate("/frontend/settings");
      notifyWarning("You are on basic plan kindly upgrade plan to launch KLMS")
    }
    else {
      if (launch == "HRMS" && hrmsbuttonName == "Launch") {
        setLoadData(launch);

        const isHistorycreated = await dispatch(updateLoginHistory({
          loginhistoryid,
          module: "HRMS"
        }));

        if (!isHistorycreated) {
          return console.log("login history not created")
        }

        setInterval(() => {
          window.location.href = isProduction ? (userrole == "User" ? `${frontendproductionhrms}/hrm/employeeselfservice/summary` : frontendproductionhrms) : (userrole == "User" ? `${frontendlocalhrms}/hrm/employeeselfservice/summary` : frontendlocalhrms);
        }, 500)
      }

      if (launch == "KLMS" && klmsbuttonName == "Launch") {
        setLoadData(launch)
        const isHistorycreated = await dispatch(updateLoginHistory({
          loginhistoryid,
          module: "KLMS"
        }));

        if (!isHistorycreated) {
          return console.log("login history not created")
        }

        setTimeout(() => {
          window.location.href = isProduction ? `${frontendproductionklms}/kms/dashboard` : `${frontendlocalklms}/kms/dashboard`;
        }, 500)

      }

      if (launch == "DMS" && dmsbuttonName == "Launch") {
        console.log("hello this dms block")
        setLoadData(launch)
        const isHistorycreated = await dispatch(updateLoginHistory({
          loginhistoryid,
          module: "DMS"
        }));

        if (!isHistorycreated) {
          return console.log("login history not created")
        }

        setTimeout(() => {
          window.location.href = isProduction ? `${frontendproductiondms}/dms/document` : `${frontendlocaldms}/dms/document`;
        }, 500)

      }
    }
  }

  return (
    <div className={`section-apps ${theme}`}>
      <h1 className="common-title">Products to try</h1>
      {loadData ? <PageLoading /> :
        <div className='grid grid-two--cols'>
          <>
            <div className='productcard'>
              <div className='product-details'>
                <span className='product-icon'><HiMiniUserGroup /></span>
                <span className='product-title'>Human Resource Management System (HRMS)</span>
              </div>
              <p className='cardPara'>Human Resources Management System (HRMS) is a comprehensive software solution that helps organizations manage various HR functions. It integrates multiple HR processes and systems to ensure efficient management of organization workflow.</p>
              <div className='product-feature'>
                <ul>
                  <li>
                    <span><FaCheckCircle /></span>
                    <span>Employee Data</span>
                  </li>
                  <li>
                    <span><FaCheckCircle /></span>
                    <span>Recruitment</span>
                  </li>
                  <li>
                    <span><FaCheckCircle /></span>
                    <span>Performances</span>
                  </li>
                  <li>
                    <span><FaCheckCircle /></span>
                    <span>Task & Events</span>
                  </li>
                  <li>
                    <span><FaCheckCircle /></span>
                    <span>Payroll</span>
                  </li>
                  <li>
                    <span><FaCheckCircle /></span>
                    <span>Other HR Activities</span>
                  </li>
                </ul>
              </div>

              {/* <span className='plan-viewmore'>View More</span> */}
              <div className='prduct-button--container'>
                {/* <div><button className='commonBtn purchased'><a href="https://vliv.app/">Free Trial</a></button></div> */}
                <div>
                  <button className='commonBtn' onClick={() => handleButton("HRMS")}>
                    {
                      loadData == STATE.HRMS ? <Loader /> : hrmsbuttonName
                    }
                  </button>
                </div>
              </div>
            </div>

            <div className='productcard'>
              <div className='product-details'>
                <span className='product-icon'><TbBulbFilled /></span>
                <span className='product-title'>Knowledge & Learning Management System (KLMS)</span>
              </div>
              <p className='cardPara'>Knowledge and Learning Management System (KLMS) is a sophisticated software platform designed to facilitate the creation, management, distribution, and tracking of learning and knowledge within an organization. It combines both Knowledge Management Systems (KMS) and Learning Management Systems (LMS) to support</p>
              <div className='product-feature'>
                <ul>
                  <li>
                    <span><FaCheckCircle /></span>
                    <span>Course Creation</span>
                  </li>
                  <li>
                    <span><FaCheckCircle /></span>
                    <span>Certifications</span>
                  </li>
                  <li>
                    <span><FaCheckCircle /></span>
                    <span>Document Management</span>
                  </li>
                  <li>
                    <span><FaCheckCircle /></span>
                    <span>Employee Training</span>
                  </li>
                  <li>
                    <span><FaCheckCircle /></span>
                    <span>Other Learning  Activities</span>
                  </li>
                </ul>
              </div>

              {/* <span className='plan-viewmore'>View More</span> */}
              <div className='prduct-button--container'>
                {/* <div><button className='commonBtn purchased'><a href="http://206.189.128.35:6002/">Free Trial</a></button></div> */}
                <div>
                  <button className='commonBtn' onClick={() => handleButton("KLMS")}>
                    {
                      loadData == STATE.KLMS ? <Loader /> : klmsbuttonName
                    }
                  </button>
                </div>
              </div>
            </div>

            <div className='productcard'>
              <div className='product-details'>
                <span className='product-icon'><PiFileDocLight /></span>
                <span className='product-title'>Document Management System (DMS)</span>
              </div>
              <p className='cardPara'>Document Management System (DMS) streamlines the processes of document storage, sharing, and version control. It provides a centralized platform for managing electronic documents, ensuring easy access, improved collaboration, and enhanced security.</p>
              <div className='product-feature'>
                <ul>
                  <li>
                    <span><FaCheckCircle /></span>
                    <span>Document Creation</span>
                  </li>
                  <li>
                    <span><FaCheckCircle /></span>
                    <span>Access Control</span>
                  </li>
                  <li>
                    <span><FaCheckCircle /></span>
                    <span>Folder Creation</span>
                  </li>
                  <li>
                    <span><FaCheckCircle /></span>
                    <span>Group Management</span>
                  </li>
                  <li>
                    <span><FaCheckCircle /></span>
                    <span>Access Management</span>
                  </li>

                </ul>
              </div>

              {/* <span className='plan-viewmore'>View More</span> */}
              <div className='prduct-button--container'>
                {/* <div><button className='commonBtn purchased'><a href="http://206.189.128.35:6002/">Free Trial</a></button></div> */}
                <div>
                  <button className='commonBtn' onClick={() => handleButton("DMS")}>
                    {
                      loadData == STATE.DMS ? <Loader /> : dmsbuttonName
                    }
                  </button>
                </div>
              </div>
            </div>
          </>
        </div>
      }
    </div>
  )
}

export default Apps