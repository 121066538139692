export const responsiveDrawer={
    width: "50vw",
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: "50vw",
      boxSizing: "border-box",
      "@media (max-width: 600px)": {
        width: "90vw", // adjust width for smaller screens
      },
    },
  }