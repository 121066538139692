// src/components/EmailLink.js
import React from 'react';
import { useSelector } from 'react-redux';

const EmailLink = ({ email }) => {
    //Redux
    const theme = useSelector((state) => state.themeReducer.theme);
    return (
        <a href={`mailto:${email}`} target="_blank"  style={{
            color: theme === "light" ? 'var(--linked-color)' : "var(--dark-link-color)",
            textDecoration: 'underline',
            fontSize: "1.6rem"
        }}>
            {email}
        </a>
    );
};

export default EmailLink;
