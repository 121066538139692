import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { Country, State, City } from "country-state-city";
import vliv_logo from "../../../assets/Vliv logo-white.png";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import axios from "axios";

import moment from "moment-timezone";
import Cookies from "js-cookie";
import { GoogleLogin } from "@react-oauth/google";
import {
  notifyError,
  notifySuccess,
} from "../../../../src/constants/toastAlerts";
import { useDispatch, useSelector } from "react-redux";

import OTPInput from "react-otp-input";

import Loader from "../../../constants/Loader";
const allCountries = Country.getAllCountries();

const BackendAuth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isProduction = process.env.NODE_ENV === "production";

  //redux
  const loadData = useSelector((state) => state.loadReducer);

  //  for signin
  const [cred, setCred] = useState({
    email: "",
    password: "",
  });
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const handleClickShowLoginPassword = () =>
    setShowLoginPassword((show) => !show);
  const handleMouseDownLoginPassword = (event) => {
    event.preventDefault();
  };

  const handleLoginChange = (e) => {
    const { name, value } = e.target;

    setCred({ ...cred, [name]: value });
  };

  //for sign up
  const [data, setData] = useState({
    clientname: "",
    clientemail: "",
    company: "",
    industrytype: "",
    country: "India",
    state: "",
    timezone: "",
    currency: "",
    language: "English",
    userid: "",
    password: "",
    confirmpassword: "",
    referencecode: "",
    joindate: "",
    status: "active",
    countrycode: "",
    userrole: "masteradmin",
    usermodules: [],
  });
  const [selectedCoutryTimezone, setSelectedCountryTimezone] = useState([]);
  const [selectedCountriesStates, SetSelectedCountriesStates] = useState([]);
  const [ispwdMissMatch, setIspwdMissMatch] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showEmptyError, setShowEmptyError] = useState(false);

  const handleLogin = async () => {
    if (cred.email == "" || cred.password == "") {
      return notifyError("kindly add Email and password");
    }

    axios
      .post(`https://vliv.app/backend/login`, cred)
      .then((res) => {
       
        if (res.data.message == "Login success") {
          Cookies.set("backendinfo", JSON.stringify(res.data.userData), {
            domain: isProduction ? ".vliv.app" : "localhost",
            path: "/",
            secure: isProduction,
            sameSite: isProduction ? "None" : "Lax",
          });
          notifySuccess(res.data.message);
          navigate("/backend");
        } else {
          notifyError(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Box className="signin-main">
        <Box
          className="signin-left"
          width={"35%"}
          height={"100%"}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box textAlign="center">
            <img src={vliv_logo} alt="logo" className="logo_img" />
            <p style={{ fontSize: "1.8rem" }}>
              💯 Percent secure 0️⃣ percent leakage
            </p>
          </Box>
        </Box>

        <Box
          className="signin-right"
          width={"60%"}
          height={"100%"}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            width="100%"
            maxWidth="430px"
            p={3}
            boxShadow={3}
            borderRadius={4}
            bgcolor="white"
          >
            <Box>
              <Typography component="h1" variant="h3" fontWeight={"600"}>
                Sign in (Backend)
              </Typography>

              <Box>
                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={{ margin: "0.6rem" }}
                  size="small"
                >
                  <FormLabel
                    sx={{ fontSize: "1.45rem", my: 0.7 }}
                    className="form-label"
                  >
                    Email Address
                  </FormLabel>
                  <OutlinedInput
                    name="email"
                    value={cred.email}
                    onChange={handleLoginChange}
                    placeholder="Enter Your Mail Address"
                    sx={{
                      borderRadius: "25px",
                      fontSize: "1.8rem",
                      padding: 0.5,
                    }}
                    error={!cred.email && showEmptyError}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={{ margin: "0.6rem", mb: 2 }}
                >
                  <FormLabel
                    sx={{ fontSize: "1.45rem", my: 0.7 }}
                    className="form-label"
                  >
                    Password
                  </FormLabel>
                  <OutlinedInput
                    size="small"
                    name="password"
                    type={showLoginPassword ? "text" : "password"}
                    value={cred.password}
                    onChange={handleLoginChange}
                    placeholder="Enter Your Password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowLoginPassword}
                          onMouseDown={handleMouseDownLoginPassword}
                        >
                          {showLoginPassword ? (
                            <VisibilityOffOutlined
                              sx={{ fontSize: "1.9rem" }}
                            />
                          ) : (
                            <VisibilityOutlined sx={{ fontSize: "1.9rem" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    sx={{
                      borderRadius: "25px",
                      fontSize: "1.8rem",
                      padding: 0.5,
                    }}
                    error={!cred.password && showEmptyError}
                  />
                </FormControl>
                <div
                  className="button-container"
                  style={{
                    margin: "2rem 0rem 1rem 0rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    className="commonBtn"
                    onClick={handleLogin}
                    style={{
                      width: "70%",
                      textAlign: "center",
                      fontSize: "1.8rem",
                    }}
                  >
                    {loadData ? <Loader /> : "Sign in"}
                  </button>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BackendAuth;
