import { Box, Button, Grid, TextField, Typography, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { IoMdSend } from "react-icons/io";
import { AiOutlinePaperClip } from "react-icons/ai"; // Import attachment icon
import Cookies from "js-cookie";
import axios from "axios";
import io from "socket.io-client";

const socket = io("https://vliv.app"); // Ensure this is correct

function ChatList() {
  const userInfo = JSON.parse(Cookies.get("backendinfo"));
  const { name, role } = userInfo;

  const [customersList, SetCustomersList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [data, setData] = useState({
    companyname: "",
    companyid: "",
    companyemail: "",
    username: "",
    userid: "",
    time: new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    }),
    message: [],
  });
  const [message, setMessage] = useState({
    id: Math.random(),
    time: new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    }),
    type: "backend",
    date: new Date(),
    message: "",
    file: "",
    user: name,
  });

  useEffect(() => {
    axios.post(`https://vliv.app/chat/getall`)
      .then((res) => {
        SetCustomersList(res.data);
      })
      .catch((err) => console.log(err));

    socket.on("chat", (data) => {
      // Handle incoming messages
      setData((prevData) => ({
        ...prevData,
        message: [...prevData.message, ...data.message]
      }));
    });

    return () => {
      socket.off("chat");
    };
  }, []);

  useEffect(() => {
    const fetchChatData = async () => {
      try {
        const response = await axios.post("https://vliv.app/chat/get", {
          companyid: selectedCompany.companyid,
        });
        const chatData = response.data;

        setData(chatData);
      } catch (error) {
        console.error("Error fetching chat data:", error);
      }
    };

    if (selectedCompany) {
      fetchChatData();
    }
  }, [selectedCompany]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMessage((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("https://vliv.app/chat/fileupload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const fileUrl = response.data.file;

      setMessage((prev) => ({
        ...prev,
        file: fileUrl,
      }));
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleSend = async () => {
    if (message.message.trim() === "" && !message.file) return;

    const newMessage = {
      id: Math.random(),
      time: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      type: "backend",
      date: new Date(),
      message: message.message,
      file: message.file,
      user: name,
    };

    const updatedData = {
      ...data,
      message: [...data.message, newMessage],
    };

    try {
      await axios.post("https://vliv.app/chat/add", updatedData);
    } catch (error) {
      console.error("Error sending message:", error);
    }

    setMessage({
      id: Math.random(),
      time: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      type: "backend",
      date: new Date(),
      message: "",
      file: "",
      user: name,
    });
  };

  const handleSelectCompany = (data) => {
    setSelectedCompany(data);
  };

  return (
    <>
      <Grid container sx={{ justifyContent: "space-evenly" }}>
        <Grid
          item
          sm={3.5}
          md={3.5}
          lg={3.5}
          sx={{
            // border: "solid 1px black",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            background: "#F4FEFE",
          }}
        >
          {customersList.map((item, index) => (
            <Grid
              item
              sm={12}
              md={12}
              lg={12}
              sx={{
                borderBottom: "solid 1px black",
              }}
              key={index}
              onClick={() => handleSelectCompany(item)}
            >
              <Box sx={{ display: "flex", padding: 2, cursor: "pointer", }}>
                <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                  {item.companyname}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>

        {selectedCompany ? <Grid item sm={7.5} md={7.5} lg={7.5}>
          <Grid container>
            <Grid
              item
              sm={12}
              md={12}
              lg={12}
              sx={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                padding: 2,
              }}
            >
              <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
                {selectedCompany.companyname}
              </Typography>
            </Grid>
            <Grid
              item
              sm={12}
              md={12}
              lg={12}
              sx={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                padding: 2,
                maxHeight: "400px", // Adjust this value as needed
                overflowY: "auto", // Add scrolling if there are too many messages
              }}
            >
              {data.message.map((item, index) => (
                <Grid item sm={12} md={12} lg={12} key={index} sx={{ mb: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: item.type === 'backend' ? "end" : "start",
                    }}
                  >
                    <Box
                      sx={{
                        width: "45%",
                        borderRadius: 4,
                      }}
                    >
                      <Typography>
                        {item.user}, {item.time}
                      </Typography>

                      <Box
                        sx={{
                          background:
                            item.user === name ? "#81fcc3" : "#b3b3b3",
                          padding: 2,
                          borderRadius: 4,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "black",
                          }}
                        >
                          {item.message}
                        </Typography>
                        {item.file && (
                          <a href={item.file} target="_blank" rel="noopener noreferrer">
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "blue",
                                textDecoration: "underline",
                                mt: 1
                              }}
                            >
                              View Attachment
                            </Typography>
                          </a>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Grid
              item
              sm={12}
              md={12}
              lg={12}
              sx={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                padding: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  sx={{ width: "50%" }}
                  placeholder="Type a message"
                  onChange={handleChange}
                  name="message"
                  value={message.message}
                />
                <input
                  type="file"
                  id="file-upload"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
                <IconButton
                  color="primary"
                  aria-label="upload file"
                  component="span"
                  onClick={() => document.getElementById("file-upload").click()}
                >
                  <AiOutlinePaperClip style={{ width: "25px", height: "25px" }} />
                </IconButton>
                <Button onClick={handleSend}>
                  <IoMdSend style={{ width: "25px", height: "25px" }} />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid> :
          <Grid item sm={7.5} md={7.5} lg={7.5}></Grid>
        }
      </Grid>
    </>
  );
}

export default ChatList;
