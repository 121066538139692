import React, { useState, useRef } from "react";
import Cookies from "js-cookie";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import { FiPhoneCall } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { AiOutlinePaperClip } from "react-icons/ai"; // Importing the attachment icon
import { useNavigate } from "react-router-dom";

function HelpDesk() {
  const navigate = useNavigate();
  const userInfo = JSON.parse(Cookies.get("userinfo"));
  const companyid = userInfo.companyid;
  const company = userInfo.companyname;
  const companyemail = userInfo.companyemail;
  const useremail = userInfo.email;
  const username = userInfo.username;
  const userid = userInfo.userid;
  const userrole = userInfo.userrole;
  const department = userInfo.department;

  const editor = useRef(null);

  const [data, setData] = useState({
    date: new Date().toISOString().split("T")[0],
    priority: "High",
    subject: "",
    description: "",
    status: "Open",
    attachment: [],
    issueurl: "",
    company,
    companyid,
    companyemail,
    username,
    userid,
    useremail,
  });

  const [files, setFiles] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleDescriptionChange = (newContent) => {
    setData((prevValues) => ({
      ...prevValues,
      description: newContent,
    }));
  };

  const handleFileUpload = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
  };

  const handleFileSubmit = async () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file);
    });

    try {
      const response = await axios.post(
        `https://vliv.app/ticket/fileupload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const fileUrls = response.data.files;
      setData((prevValues) => ({
        ...prevValues,
        attachment: { file: fileUrls },
      }));
    } catch (error) {
      console.error("Error uploading files", error);
    }
  };

  const handleSubmit = () => {
    if (data.subject === "" || data.description === "") {
      return alert("You have missing fields");
    }

    handleFileSubmit().then(() => {
      axios
        .post(`https://vliv.app/ticket/add`, data)
        .then((res) => {
          alert(res.data.message);
        })
        .catch((err) => console.log(err));
    });
  };

  const handlePriorityChange = (priority) => {
    setData((prevValues) => ({
      ...prevValues,
      priority,
    }));
  };

  return (
    <Box sx={{ background: "#F4FAFE" }}>
      <Grid container>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          sx={{ mb: 1, background: "#ffff", padding: 1 }}
        >
          <Button sx={{}}>
            <Typography
              sx={{
                // background: "#37668F",
                color: "#37668F",
                fontSize: "22px",
                fontWeight: "600",
                padding: 1,
                borderRadius: 2,
                ml: -1,
              }}
            >
              Help Desk
            </Typography>
          </Button>
        </Grid>

        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          sx={{ mb: 1, background: "#ffff", padding: 1 }}
        >
          <Button sx={{}}>
            <Typography
              sx={{
                background: "#37668F",
                color: "#F4EFEF",
                fontSize: "18px",
                fontWeight: "500",
                padding: 1,
                borderRadius: 5,
                ml: -1,
              
              }}
            >
              Ticket
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          justifyContent: "space-between",
          background: "#ffff",
          padding: 1,
        }}
      >
        <Grid item sm={12} md={5.5} lg={5.5}>
          <Grid item sm={12} md={12} lg={12}>
            <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
              Raise Ticket
            </Typography>
            <TextField
              name="subject"
              placeholder="Subject"
              value={data.subject}
              onChange={handleChange}
              fullWidth
              sx={{
                mb: 2,
                borderRadius: 3,
                "& .MuiOutlinedInput-root": { borderRadius: 3 },
              }}
            />
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
            <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
              Description
            </Typography>
            <TextField
              name="description"
              placeholder="Description"
              value={data.description}
              onChange={handleChange}
              fullWidth
              rows={4}
              sx={{
                mb: 2,
                fontSize: "12px",
                borderRadius: 3,
                "& .MuiOutlinedInput-root": { borderRadius: 3 },
              }}
            />
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
            <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
              Attach screenshots
            </Typography>
            <Button
              variant="secondary"
              component="label"
              startIcon={<AiOutlinePaperClip style={{ color: "#007BFF" }} />} // Blue attachment icon
              sx={{
                // backgroundColor: "#F4EFEF",
                color: "#007BFF",
                borderRadius: 3,
                textTransform: "none",
                fontSize: "12px",
                mb: 2,
              }}
            >
              Upload Attachment
              <input
                name="attachment"
                type="file"
                hidden
                multiple
                onChange={handleFileUpload}
              />
            </Button>
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
            <Typography sx={{ fontSize: "18px", fontWeight: "500", mb: 0.5 }}>
              Priority
            </Typography>
            <Box
              sx={{
                display: "flex",
                // justifyContent: "space-between",
                gap: 1,
                mb: 2,
              }}
            >
              <Button
                // variant={data.priority === "Low" ? "contained" : "outlined"}
                onClick={() => handlePriorityChange("Low")}
                sx={{
                  borderRadius: 2,
                  textTransform: "none",
                  width: "15%",
                  fontSize: "12px",
                  backgroundColor: data.priority === "Low" ? "#7ff085" : "",
                  color: data.priority === "Low" ? "#fff" : "green",
                  border: "solid 0.5px green",
                }}
              >
                Low
              </Button>
              <Button
                // variant={data.priority === "Medium" ? "contained" : "outlined"}
                onClick={() => handlePriorityChange("Medium")}
                sx={{
                  borderRadius: 2,
                  textTransform: "none",
                  width: "15%",
                  fontSize: "12px",
                  backgroundColor: data.priority === "Medium" ? "#f7bb6d" : "",
                  color: data.priority === "Medium" ? "#fff" : "#db6a00",
                  border: "solid 0.5px #db6a00",
                }}
              >
                Medium
              </Button>
              <Button
                // variant={data.priority === "High" ? "contained" : "outlined"}
                onClick={() => handlePriorityChange("High")}
                sx={{
                  borderRadius: 2,
                  textTransform: "none",
                  width: "15%",
                  fontSize: "12px",
                  backgroundColor: data.priority === "High" ? "#f55b6a" : "",
                  color: data.priority === "High" ? "#fff" : "red",
                  border: "solid 0.5px red",
                }}
              >
                High
              </Button>
            </Box>
          </Grid>

          <Grid
            item
            sm={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{
                border: "solid 1px grey",
                backgroundColor: "#37668F",
                "&:hover": {
                  backgroundColor: "#37668F",
                },
                color: "#FFFF",
                width: "200px",
                height: "40px",
                borderRadius: "15px",
                ml: 1,
                mt: 0.5,
                fontSize: "14px",
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          sm={12}
          md={5.5}
          lg={5.5}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            container
            sx={{
              border: "solid 1px black",
              paddingLeft: 3,
              paddingRight: 3,
              paddingBottom: 3,
              paddingTop: 2,

              justifyContent: "space-between",
              borderRadius: 5,
              background: "#37668F",
            }}
          >
            <Grid
              item
              sm={12}
              md={12}
              lg={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  // fontWeight: "550",
                  paddingBottom: 4,
                  color: "#F4EFEF",
                }}
              >
                You still have a query ?
              </Typography>
            </Grid>

            <Grid
              item
              sm={12}
              md={5.8}
              lg={5.8}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 5,
                background: "#F4EFEF",
                flexDirection: "column",
                padding: 3,
                // gap:3,
                // border:"solid 1px black"
              }}
            >
              <Box
                sx={{
                  display: "felx",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                {" "}
                <FiPhoneCall
                  style={{ width: "35px", height: "35px", color: "#37668F" }}
                />
              </Box>
              <Box
                sx={{
                  display: "felx",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "18px", fontWeight: "550" }}>
                  +919005784046
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "felx",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                  We are always happy to help
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              sm={12}
              md={5.8}
              lg={5.8}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 5,
                background: "#F4EFEF",
                flexDirection: "column",
                padding: 3,
                // gap:3,
                // border:"solid 1px black"
              }}
            >
              <Box
                sx={{
                  display: "felx",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                {" "}
                <HiOutlineMail
                  style={{ width: "35px", height: "35px", color: "#37668F" }}
                />
              </Box>
              <Box
                sx={{
                  display: "felx",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "18px", fontWeight: "550" }}>
                  info@vliv.app
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "felx",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                  The best way to get answer faster
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm={12} md={12} lg={12} sx={{background:"#FFF", mb:1}}>
        <Typography sx={{ fontSize: "22px", fontWeight: "500", mt: 2 }}>
          Youtube Links
        </Typography>
        <Box sx={{background:"#FFF"}}>
          <Typography sx={{ fontSize: "18px", mt: 1.5 }}>
            Vliv Tutorial 1
          </Typography>
          <a href="https://www.Youtube.com/abc">https://www.Youtube.com/abc</a>
          <Typography sx={{ fontSize: "18px", mt: 1.5 }}>
            Vliv Tutorial 2
          </Typography>
          <a href="https://www.Youtube.com/abc">https://www.Youtube.com/abc</a>
          <Typography sx={{ fontSize: "18px", mt: 1.5 }}>
            Vliv Tutorial 3
          </Typography>
          <a href="https://www.Youtube.com/abc">https://www.Youtube.com/abc</a>
        </Box>
      </Grid>

      <Grid container>
        <Grid item sm={12} md={12} lg={12}>
          <Button 
         sx={{
          border: "solid 1px grey",
          backgroundColor: "#37668F",
          "&:hover": {
            backgroundColor: "#37668F",
          },
          color: "#FFFF",
          width: "200px",
          height: "40px",
          borderRadius: "15px",
          ml: 1,
          mt: 0.5,
          fontSize: "14px",
        }}
              onClick={() => navigate("/frontend/chat")}
            >
              Chat
          
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default HelpDesk;
