import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  TextField,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Chip,
  Divider,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import Cookies from "js-cookie";
import { responsiveDrawer } from "../../../../constants/constants";
import axios from "axios";
import {
  backendlocalhrms,
  backendproductionhrms,
  companyUserAdd,
} from "../../../../constants/api";
import { notifyError, notifySuccess } from "../../../../constants/toastAlerts";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { plans } from "../../../../redux/actions/plans.action";
import LocationSearch from "./LocationSearch";

const AddUser = ({ handleDrawerClose, drawerOpen }) => {
  const navigate = useNavigate();
  const cookieData = Cookies.get("userinfo");
  const isProduction = process.env.NODE_ENV === "production";
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let totalmodules = ["HRMS", "KLMS"];
  let email = userData.email ? userData.email : "";

  const [placeId, setPlaceId] = useState("");
  const [refference, setRefference] = useState("");

  //Redux
  const dispatch = useDispatch();
  const planData = useSelector((state) => state.planReducer.planData);
  // console.log("planData", planData);
  const basic = [planData.basic || {}];
  const premium = [planData.premium || {}];
  const standard = [planData.standard || {}];
  const yearlybasic = [planData.yearlybasic || {}];
  const yearlystandard = [planData.yearlystandard || {}];
  const yearlypremium = [planData.yearlypremium || {}];

  const [formState, setFormState] = useState({
    userid: "",
    username: "",
    reportmanager: "",
    email: "",
    userphone: "",
    userrole: "User",
    userprofile: "",
    usermodules: [],
    emptype: "",
    position: "",
    level: "",
    otapplicable: "",
    branch: "",
    attendancescheme: "",
    department: "",
    location: "",
    companyemail,
    companyid,
    status: "Active",
    password: Array.from({ length: 8 }, () =>
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789".charAt(
        Math.floor(Math.random() * 62)
      )
    ).join(""),
  });
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [allEmp, setAllEmp] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [attendanceSchemeOptions, setAttendanceSchemeOptions] = useState([]);
  const [employeeTypeOptions, setEmployeeTypeOptions] = useState([]);
  const [branchoptions, setBranchOptions] = useState([]);
  const [locationOptions, setLocationoptions] = useState([]);
  const [levelOptions, setLevelOptions] = useState([]);
  const [positionOptions, setPositionOptions] = useState([]);
  const [profileOptions, setProfileOptions] = useState([]);
  const [reportingManagerOptions, setReportingManagerOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const [editMode, setEditMode] = useState({
    // userrole: false,
    userprofile: false,
    reportmanager: false,
    emptype: false,
    position: false,
    level: false,
    branch: false,
    location: false,
    attendancescheme: false,
    department: false,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddOption = (field) => () => {
    setEditMode((prevState) => ({
      ...prevState,
      [field]: true,
    }));
    setFormState((prevState) => ({
      ...prevState,
      [field]: "",
    }));
  };

  // const handleSaveOption = (field) => () => {
  //   setEditMode((prevState) => ({
  //     ...prevState,
  //     [field]: false,
  //   }));

  //   const addOptionIfNotExists = async (
  //     optionList,
  //     setOptionList,
  //     newOption,
  //     title
  //   ) => {
  //     if (newOption && !optionList.includes(newOption)) {
  //       try {
  //         let res = await axios.post(`${isProduction ? backendproductionhrms : backendlocalhrms}/employee/position/add`, {
  //           companyemail,
  //           companyid,
  //           title,
  //           name: newOption,
  //           status: "Active",
  //         },
  //           { withCredentials: true }
  //         );
  //         if (res.data.message == "Employee master added successfully") {
  //           setOptionList([...optionList, newOption]);
  //         }
  //       } catch (error) {
  //         console.log(error, "error");
  //       }
  //     }
  //   };

  //   switch (field) {
  //     case "userprofile":
  //       addOptionIfNotExists(
  //         profileOptions,
  //         setProfileOptions,
  //         formState.userprofile,
  //         "profile"
  //       );
  //       break;
  //     case "reportmanager":
  //       addOptionIfNotExists(
  //         reportingManagerOptions,
  //         setReportingManagerOptions,
  //         formState.reportmanager,
  //         "reportingmanager"
  //       );
  //       break;
  //     case "emptype":
  //       addOptionIfNotExists(
  //         employeeTypeOptions,
  //         setEmployeeTypeOptions,
  //         formState.emptype,
  //         "employeetype"
  //       );
  //       break;
  //     case "position":
  //       addOptionIfNotExists(
  //         positionOptions,
  //         setPositionOptions,
  //         formState.position,
  //         "position"
  //       );
  //       break;
  //     case "level":
  //       addOptionIfNotExists(
  //         levelOptions,
  //         setLevelOptions,
  //         formState.level,
  //         "level"
  //       );
  //       break;
  //     case "branch":
  //       addOptionIfNotExists(
  //         branchoptions,
  //         setBranchOptions,
  //         formState.branch,
  //         "branch"
  //       );
  //       break;
  //     case "location":
  //       addOptionIfNotExists(
  //         locationOptions,
  //         setLocationoptions,
  //         formState.location,
  //         "location"
  //       );
  //       break;
  //     case "attendancescheme":
  //       addOptionIfNotExists(
  //         attendanceSchemeOptions,
  //         setAttendanceSchemeOptions,
  //         formState.attendancescheme,
  //         "attendancescheme"
  //       );
  //       break;
  //     case "department":
  //       addOptionIfNotExists(
  //         departmentOptions,
  //         setDepartmentOptions,
  //         formState.department,
  //         "department"
  //       );
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const handleSaveOption = (field) => async () => {
    setEditMode((prevState) => ({
      ...prevState,
      [field]: false,
    }));

    const addOptionIfNotExists = async (
      optionList,
      setOptionList,
      newOption,
      title
    ) => {
      if (newOption && !optionList.includes(newOption)) {
        try {
          const res = await axios.post(
            `${
              isProduction ? backendproductionhrms : backendlocalhrms
            }/employee/position/add`,
            {
              companyemail,
              companyid,
              title,
              name: newOption,
              status: "Active",
              place_id: title == "location" ? placeId : "",
              reference: title == "location" ? refference : "",
            },
            { withCredentials: true }
          );
          if (res.data.message === "Employee master added successfully") {
            setOptionList([...optionList, newOption]);
          }
        } catch (error) {
          console.log(error, "error");
        }
      }
    };

    switch (field) {
      case "userprofile":
        addOptionIfNotExists(
          profileOptions,
          setProfileOptions,
          formState.userprofile,
          "profile"
        );
        break;
      case "reportmanager":
        addOptionIfNotExists(
          reportingManagerOptions,
          setReportingManagerOptions,
          formState.reportmanager,
          "reportingmanager"
        );
        break;
      case "emptype":
        addOptionIfNotExists(
          employeeTypeOptions,
          setEmployeeTypeOptions,
          formState.emptype,
          "employeetype"
        );
        break;
      case "position":
        addOptionIfNotExists(
          positionOptions,
          setPositionOptions,
          formState.position,
          "position"
        );
        break;
      case "level":
        addOptionIfNotExists(
          levelOptions,
          setLevelOptions,
          formState.level,
          "level"
        );
        break;
      case "branch":
        addOptionIfNotExists(
          branchoptions,
          setBranchOptions,
          formState.branch,
          "branch"
        );
        break;
      case "location":
        addOptionIfNotExists(
          locationOptions,
          setLocationoptions,
          formState.location,
          "location"
        );
        break;
      case "attendancescheme":
        addOptionIfNotExists(
          attendanceSchemeOptions,
          setAttendanceSchemeOptions,
          formState.attendancescheme,
          "attendancescheme"
        );
        break;
      case "department":
        addOptionIfNotExists(
          departmentOptions,
          setDepartmentOptions,
          formState.department,
          "department"
        );
        break;
      default:
        break;
    }
  };

  const getDropdowns = async () => {
    try {
      let res = await axios.post(
        `${
          isProduction ? backendproductionhrms : backendlocalhrms
        }/employee/position/get`,
        {
          companyemail,
          companyid,
        },
        { withCredentials: true }
      );
      //  console.log(res,"dropdown")
      if (res.data && res.data.length > 0) {
        const optionsMapping = {
          attendancescheme: setAttendanceSchemeOptions,
          reportingmanager: setReportingManagerOptions,
          position: setPositionOptions,
          employeetype: setEmployeeTypeOptions,
          branch: setBranchOptions,
          location: setLocationoptions,
          profile: setProfileOptions,
          level: setLevelOptions,
          department: setDepartmentOptions,
        };

        Object.keys(optionsMapping).forEach((key) => {
          optionsMapping[key](
            res.data
              .filter((opt) => opt.position === key && opt.status == "Active")
              .map((ele) => ele.name)
          );
        });
      }
    } catch (error) {
      console.log(error, "error");
      notifyError(error.message);
    }
  };

  const getAllEmp = async () => {
    try {
      let res = await axios.post(
        `${
          isProduction ? backendproductionhrms : backendlocalhrms
        }/employee/get`,
        {
          companyid,
          companyemail,
        },
        { withCredentials: true }
      );
      res.data && res.data.length ? setAllEmp(res.data) : setAllEmp([]);
    } catch (error) {
      console.log(error, "error");
      notifyError(error.message);
    }
  };

  const handleAddUser = async () => {
    // console.log(formState,"state");

    if (
      !formState.userid ||
      !formState.username ||
      !formState.email ||
      !formState.userphone ||
      !formState.reportmanager ||
      formState.usermodules.length == 0
    ) {
      setShowEmptyError(true);
      notifyError("You have empty fields");
      return;
    }

    try {
      let res = await axios.post(companyUserAdd, formState, {
        withCredentials: true,
      });
      if (res.data.message == "User added successfully") {
        notifySuccess(res.data.message);
        setFormState({
          userid: "",
          username: "",
          reportmanager: "",
          email: "",
          userphone: "",
          userrole: "User",
          userprofile: "",
          usermodules: [],
          emptype: "",
          position: "",
          level: "",
          otapplicable: "",
          branch: "",
          attendancescheme: "",
          department: "",
          location: "",
          companyemail,
          companyid,
          status: "",
          password: "",
        });
        handleDrawerClose();
      } else {
        notifyError(res.data.message);
      }
    } catch (error) {
      console.log(error, "error");
      notifyError(error.response.data.message);
    }
  };

  const handleUpgrade = () => {
    const currentplanname = !planData.planname ? "Basic" : planData.planname;
    let plan;
    if (planData.plansubscription == "Monthly") {
      switch (currentplanname) {
        case "Standard":
          plan = standard;
          break;
        case "Premium":
          plan = premium;
          break;
        default:
          plan = basic;
      }
    } else {
      switch (currentplanname) {
        case "Standard":
          plan = yearlystandard;
          break;
        case "Premium":
          plan = yearlypremium;
          break;
        default:
          plan = yearlybasic;
      }
    }

    navigate("/frontend/plan-summary", {
      state: { selectedPlan: plan, FreeTrial: false },
    });
  };

  const getUserId = async () => {
    try {
      let res = await axios.post("https://vliv.app/company/getuserid", {
        companyid,
        companyemail,
      });

      if (res.data && res.data.userid) {
        setFormState((prev) => ({ ...prev, userid: res.data.userid }));
      } else {
        setFormState((prev) => ({ ...prev, userid: "" }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDropdowns();
    getAllEmp();
    dispatch(plans({ email: email }));
    getUserId();
  }, [drawerOpen]);

  // console.log(placeId,refference,"all emp");

  return (
    <>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        sx={responsiveDrawer}
      >
        <Typography
          variant="h4"
          component={"h2"}
          gutterBottom
          px={2}
          py={1.5}
          className="primarycolor adduser"
        >
          Add User
        </Typography>
        <Divider />

        <Box width={"100%"} my={0}>
          <form>
            <Grid container spacing={2} padding={1.5}>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  required
                  label="User ID"
                  variant="outlined"
                  value={formState.userid}
                  fullWidth
                  name="userid"
                  // onChange={handleInputChange}
                  error={!formState.userid && showEmptyError}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  required
                  label="Name"
                  variant="outlined"
                  name="username"
                  placeholder="Enter Full Name"
                  value={formState.username}
                  onChange={handleInputChange}
                  fullWidth
                  error={!formState.username && showEmptyError}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  required
                  type="email"
                  label="Email"
                  name="email"
                  placeholder="Enter Email Id"
                  value={formState.email}
                  onChange={handleInputChange}
                  fullWidth
                  error={!formState.email && showEmptyError}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  required
                  label="Phone No."
                  name="userphone"
                  placeholder="Enter phone no"
                  value={formState.userphone}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only numeric input
                    if (/^\d*$/.test(value)) {
                      // Ensure the length is between 10 and 10 characters
                      if (value.length <= 10) {
                        handleInputChange(e);
                      }
                    }
                  }}
                  fullWidth
                  error={
                    (showEmptyError && !formState.userphone) ||
                    (formState.userphone && formState.userphone.length < 10)
                  }
                  helperText={
                    showEmptyError && !formState.userphone
                      ? "Phone number is required"
                      : formState.userphone && formState.userphone.length < 10
                      ? "Phone number must be 10 digits"
                      : ""
                  }
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: 10,
                  }}
                />
              </Grid>
              {/* <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth>
                  <InputLabel>Reporting Manager</InputLabel>
                  {editMode.reportmanager ? (
                    <Box display="flex" alignItems="center">
                      <TextField
                        name="reportmanager"
                        value={formState.reportmanager}
                        onChange={handleInputChange}
                        fullWidth
                      />
                      <IconButton onClick={handleSaveOption("reportmanager")}>
                        <CheckIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <Select
                      label="Reporting Manager"
                      name="reportmanager"
                      
                      id="demo-simple-select"
                      value={formState.reportmanager}
                      onChange={handleSelectChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleAddOption("reportmanager")}
                          >
                            <AddIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    >
                      {reportingManagerOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </Grid> */}
              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth required>
                  <InputLabel>Reporting Manager</InputLabel>
                  <Select
                    name="reportmanager"
                    id="demo-simple-select"
                    label="Reporting Manager"
                    value={formState.reportmanager}
                    onChange={handleSelectChange}
                    fullWidth
                    error={!formState.reportmanager && showEmptyError}
                    
                  >
                    {/* {allEmp
                      ?.filter(
                        (emp) =>
                        (emp.role.toLowerCase() == "masteradmin" ||
                        emp.role.toLowerCase() == "manager") && emp.status.toLowerCase()=="active"
                      )
                      .map((em) => (
                        <MenuItem value={em.name} key={em.id}>
                          {em.name}
                        </MenuItem>
                      ))} */}

                    {formState.userrole.toLowerCase() == "user"
                      ? allEmp
                          .filter(
                            (emp) =>
                              emp.role.toLowerCase() == "manager" &&
                              emp.status.toLowerCase() == "active"
                          )
                          .map((em) => (
                            <MenuItem value={em.name} key={em.id}>
                              {em.name}
                            </MenuItem>
                          ))
                      : allEmp
                          .filter(
                            (emp) =>
                              (emp.role.toLowerCase() == "masteradmin" ||
                                emp.role.toLowerCase() == "manager") &&
                              emp.status.toLowerCase() == "active"
                          )
                          .map((em) => (
                            <MenuItem value={em.name} key={em.id}>
                              {em.name}
                            </MenuItem>
                          ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth required>
                  <InputLabel>Access Role</InputLabel>
                  <Select
                    name="userrole"
                    id="demo-simple-select"
                    label="Access Role"
                    value={formState.userrole}
                    onChange={handleSelectChange}
                    fullWidth
                    error={!formState.userrole && showEmptyError}
                  >
                    <MenuItem value={"User"}>User</MenuItem>
                    <MenuItem value={"HR"}>HR</MenuItem>
                    <MenuItem value={"Manager"}>Manager</MenuItem>
                    {/* <MenuItem value={"masteradmin"}>masteradmin</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth>
                  {editMode.department ? (
                    <Box display="flex" alignItems="center">
                      <TextField
                        name="department"
                        label="Department"
                        value={formState.department}
                        onChange={handleInputChange}
                        fullWidth
                      />
                      <IconButton onClick={handleSaveOption("department")}>
                        <CheckIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <>
                      <InputLabel>Department</InputLabel>
                      <Select
                        id="demo-simple-select"
                        name="department"
                        label="Department"
                        IconComponent={() => null}
                        sx={{ pr: 0 }}
                        value={formState.department}
                        onChange={handleSelectChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton onClick={handleAddOption("department")}>
                              <AddIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      >
                        {departmentOptions.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth>
                  {editMode.userprofile ? (
                    <Box display="flex" alignItems="center">
                      <TextField
                        name="userprofile"
                        label="Profile"
                        value={formState.userprofile}
                        onChange={handleInputChange}
                        fullWidth
                      />
                      <IconButton onClick={handleSaveOption("userprofile")}>
                        <CheckIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <>
                      <InputLabel>Profile</InputLabel>
                      <Select
                        id="demo-simple-select"
                        name="userprofile"
                        label="Profile"
                        IconComponent={() => null}
                        sx={{ pr: 0 }}
                        value={formState.userprofile}
                        onChange={handleSelectChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleAddOption("userprofile")}
                            >
                              <AddIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      >
                        {profileOptions.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth>
                  {editMode.emptype ? (
                    <Box display="flex" alignItems="center">
                      <TextField
                        name="emptype"
                        value={formState.emptype}
                        onChange={handleInputChange}
                        fullWidth
                      />
                      <IconButton onClick={handleSaveOption("emptype")}>
                        <CheckIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <>
                      <InputLabel>Employee Type</InputLabel>
                      <Select
                        name="emptype"
                        id="demo-simple-select"
                        label="Employee Type"
                        IconComponent={() => null}
                        sx={{ pr: 0 }}
                        value={formState.emptype}
                        onChange={handleSelectChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton onClick={handleAddOption("emptype")}>
                              <AddIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      >
                        {employeeTypeOptions.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth>
                  {editMode.position ? (
                    <Box display="flex" alignItems="center">
                      <TextField
                        name="position"
                        value={formState.position}
                        onChange={handleInputChange}
                        fullWidth
                      />
                      <IconButton onClick={handleSaveOption("position")}>
                        <CheckIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <>
                      <InputLabel>Position</InputLabel>
                      <Select
                        name="position"
                        id="demo-simple-select"
                        label="Employee Type"
                        IconComponent={() => null}
                        sx={{ pr: 0 }}
                        value={formState.position}
                        onChange={handleSelectChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton onClick={handleAddOption("position")}>
                              <AddIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      >
                        {positionOptions.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth>
                  {editMode.level ? (
                    <Box display="flex" alignItems="center">
                      <TextField
                        name="level"
                        value={formState.level}
                        onChange={handleInputChange}
                        fullWidth
                      />
                      <IconButton onClick={handleSaveOption("level")}>
                        <CheckIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <>
                      <InputLabel>Level</InputLabel>
                      <Select
                        name="level"
                        label="Employee Type"
                        id="demo-simple-select"
                        IconComponent={() => null}
                        sx={{ pr: 0 }}
                        value={formState.level}
                        onChange={handleSelectChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton onClick={handleAddOption("level")}>
                              <AddIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      >
                        {levelOptions.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth>
                  <InputLabel>OT Applicable</InputLabel>
                  <Select
                    name="otapplicable"
                    id="demo-simple-select"
                    label="OT Applicable"
                    value={formState.otapplicable}
                    onChange={handleSelectChange}
                    fullWidth
                  >
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth>
                  {editMode.branch ? (
                    <Box display="flex" alignItems="center">
                      <TextField
                        name="branch"
                        value={formState.branch}
                        onChange={handleInputChange}
                        fullWidth
                      />
                      <IconButton onClick={handleSaveOption("branch")}>
                        <CheckIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <>
                      <InputLabel>Branch</InputLabel>
                      <Select
                        id="demo-simple-select"
                        name="branch"
                        label="Employee Type"
                        IconComponent={() => null}
                        sx={{ pr: 0 }}
                        value={formState.branch}
                        onChange={handleSelectChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton onClick={handleAddOption("branch")}>
                              <AddIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      >
                        {branchoptions.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth>
                  {editMode.location ? (
                    <Box display="flex" alignItems="center">
                      {/* <TextField
                        name="location"
                        value={formState.location}
                        onChange={handleInputChange}
                        fullWidth
                      /> */}
                      <LocationSearch
                        setRefference={setRefference}
                        setPlaceId={setPlaceId}
                        location={formState.location}
                        onLocationSelect={(selectedLocation) =>
                          setFormState((prevState) => ({
                            ...prevState,
                            location: selectedLocation,
                          }))
                        }
                      />
                      <IconButton onClick={handleSaveOption("location")}>
                        <CheckIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <>
                      <InputLabel>Location</InputLabel>
                      <Select
                        id="demo-simple-select"
                        name="location"
                        label="Employee Type"
                        IconComponent={() => null}
                        sx={{ pr: 0 }}
                        value={formState.location}
                        onChange={handleSelectChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton onClick={handleAddOption("location")}>
                              <AddIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      >
                        {locationOptions.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth>
                  <InputLabel>Attendance Scheme</InputLabel>
                  {editMode.attendancescheme ? (
                    <Box display="flex" alignItems="center">
                      <TextField
                        name="attendancescheme"
                        value={formState.attendancescheme}
                        onChange={handleInputChange}
                        fullWidth
                      />
                      <IconButton
                        onClick={handleSaveOption("attendancescheme")}
                      >
                        <CheckIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <Select
                      id="demo-simple-select"
                      name="attendancescheme"
                      label="Employee Type"
                      value={formState.attendancescheme}
                      onChange={handleSelectChange}
                      endAdornment={
                        <IconButton
                          onClick={handleAddOption("attendancescheme")}
                        >
                          <AddIcon />
                        </IconButton>
                      }
                    >
                      {attendanceSchemeOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </Grid> */}
              <Grid item xs={12} md={6} lg={6}>
                <Autocomplete
                  aria-expanded={true}
                  multiple
                  id="tags-outlined"
                  options={
                    totalmodules?.length > 0
                      ? totalmodules.map((plan) => plan)
                      : []
                  }
                  getOptionLabel={(option) => option}
                  filterSelectedOptions
                  value={formState.usermodules}
                  onChange={(event, newValue) => {
                    setFormState({ ...formState, usermodules: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="modules-access--feild"
                      fullWidth
                      required
                      placeholder="Module"
                      error={
                        formState.usermodules.length == 0 && showEmptyError
                      }
                      name="usermodules"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={2}
            >
              <h3
                className="user-email"
                style={{ fontSize: "1.3rem", textAlign: "center" }}
              >
                By clicking on submit, the user will be added and an invitation
                link will be sent to their email.
              </h3>
              <Button className="commonBtn inviteBtn" onClick={handleAddUser}>
                Submit
              </Button>
            </Box>
          </form>
        </Box>
        <Divider />
        <Box my={1} px={2}>
          <Typography fontSize="1.7rem" color={"#062C41"} fontWeight={700}>
            Upgrade your plan with add more user, designed to elevate your
            satisfaction to new heights.
          </Typography>
          <Button
            className="commonBtn inviteBtn"
            sx={{ mt: 1 }}
            onClick={() => handleUpgrade()}
          >
            Upgrade Plan
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default AddUser;
