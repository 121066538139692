import { Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React from "react";

const BreadCrumb = () => {
  const url = window.location.href;
  const urlParts = url.split("/");
  const desiredPart = urlParts.slice(3);
  const URL = desiredPart.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {URL.map((breadcrumb, index) => (
        <Typography key={index} variant="body1">
          {breadcrumb}
        </Typography>
      ))}
    </Breadcrumbs>
  );
};

export default BreadCrumb;
