import React from 'react';
import { useSelector } from 'react-redux';

function AffilateCards({ cardname, title, desc, image, color }) {
    const theme = useSelector((state) => state.themeReducer.theme);

    return (
        <div className={`affiliate-card ${color}`} >
            <h2 className={`cardname ${color}`}>{cardname}</h2>
            <img src={image} alt="Vector Image not available" className='cardimage' data-aos="fade-up" />
            <h3 className={`affiliate-title ${theme}`}>{title}</h3>
            <p className={`affiliate-desc ${theme}`}>{desc}</p>
        </div>
    )
}

export default AffilateCards