import * as React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../Components/Backend/Dashboard/Dashboard";
import Tickets from "../Components/Backend/Tickets/Tickets";
import AddTicket from "../Components/Backend/Tickets/AddTicket";
import Clients from "../Components/Backend/Clients/Clients";
import PageNotFound from "../constants/PageNotFound";
import Home from "../Components/Home/Home";
import Feature from "../Components/Home/Feature/Feature";
import Blogs from "../Components/Home/Blogs/Blogs";
import Plans from "../Components/Home/Plans/Plans";
import Contactus from "../Components/Home/Contactus/Contactus";
import Profile from "../Components/frontend/Profile/Profile";
import SocialMedia from "../Components/frontend/SocialMedia/SocialMedia";
import EditProfile from "../Components/frontend/Profile/EditProfile";
import MainLayout from "../Components/Home/MainLayout";
import BlogDetails from "../Components/Home/Blogs/BlogDetails";
import Auth from "../Components/Auth/Auth";
import FrontendHome from "../Components/frontend/Home/Home";
import Invite from "../Components/frontend/Invite/Invite";
import Help from "../Components/frontend/Help/Help";
import Apps from "../Components/frontend/Apps/Apps";
import TandC from "../Components/Home/Footer/TandC";
import PrivacyPolicy from "../Components/Home/Footer/PrivacyPolicy";
import CancellationAndRefund from "../Components/Home/Footer/CancellationAndRefund";
import CookiesPolicy from "../Components/Home/Footer/CookiesPolicy";
import PlanSummary from "../Components/frontend/Home/PlansSummary/PlanSummary";
import Blog from "../Components/Backend/Blog/Blog";
import ViewBlog from "../Components/Backend/Blog/ViewBlog/ViewBlog";
import ForgotPassword from "../Components/Auth/ForgotPassword";
import PaymentSucess from "../Components/frontend/Home/PlansSummary/PaymentSucess";
import PaymentFailure from "../Components/frontend/Home/PlansSummary/PaymentFailure";
// import PaymentPending from "../Components/frontend/Home/PlansSummary/PaymentPending";
import FrontendProtectRoute from "./FrontendProtectRoute";
import BackendProtectRoute from "./BackendProtectRoute";
import Billings from "../Components/Backend/Billings/Billings";
import Settings from "../Components/frontend/Settings/Settings";
import PurchaseHistory from "../Components/frontend/Settings/PurchaseHistory";
import BillingPlan from "../Components/frontend/Settings/BillingPlan";
import ChatBot from "../Components/Home/ChatBot";
import AccessManagement from "../Components/Backend/Home/AccessManagement/AccessManagement";
import Analytics from "../Components/Backend/Home/Analytics/Analytics";
import PlanCheckout from "../Components/frontend/Home/PlansSummary/PlanCheckout";
import BackendAuth from "../Components/Backend/BackendAuth/BackendAuth";
import UnsolvedTicket from "../Components/Backend/Tickets/UnsolvedTicket/UnsolvedTicket";
import HelpDesk from "../Components/frontend/Help/UserHelpDesk/HelpDesk";
import SolvedTicket from "../Components/Backend/Tickets/SolvedTicket/SolvedTicket";
import Partnershipwithus from "../Components/Home/Partnershipwithus/Partnershipwithus";
import Partnershipform from "../Components/Home/Partnershipform/Partnershipform";
import Chat from "../Components/Backend/Chat/Chat";
import ChatFrontend from "../Components/frontend/Help/Chat/ChatFrontend";
import LoginHistory from "../Components/frontend/Settings/LoginHistory";

const AllRoutes = () => {
  return (
    <>
      <Routes>
        {/* website routes */}
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="features" element={<Feature />} />
          <Route path="plans" element={<Plans />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="blog/:id" element={<BlogDetails />} />
          <Route path="partnership" element={<Partnershipwithus/>} />
          <Route path="partnership-program-form" element={<Partnershipform/>} />
          <Route path="contactus" element={<Contactus />} />
          <Route path="terms-and-condition" element={<TandC />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="cancellation-and-refund" element={<CancellationAndRefund />} />
          <Route path="cookies-policy" element={<CookiesPolicy />} />
          <Route path="chatbot" element={<ChatBot />} />
          <Route path="*" element={<PageNotFound />} />
        </Route >

        {/* Frontend Authentication routes */}
        <Route path="/frontend/login" element={<Auth />} />
        <Route path="/frontend/login/forgotpass" element={<ForgotPassword />} />

        {/* Frontend Portal routes */}
        <Route path="/frontend" element={<FrontendProtectRoute />} >
          <Route index element={<FrontendHome />} />
          <Route path="home" element={<FrontendHome />} />
          <Route path="plan-summary" element={<PlanSummary />} />
          <Route path="plan-checkout" element={<PlanCheckout />} />
          <Route path="payment/success/:transactionid/:merchantid/:date/:amount/:pay" element={<PaymentSucess />} />
          <Route path="payment/pending/:transactionid/:merchantid/:date/:amount" element={<PaymentFailure />} />
          <Route path="payment/failure/:transactionid/:merchantid/:date/:amount/:pay" element={<PaymentFailure />} />
          <Route path="apps" element={<Apps />} />
          <Route path="invite" element={<Invite />} />
          <Route path="settings" element={<Settings />} >
            <Route index element={<BillingPlan />} />
            <Route path="purchaseHistory" element={<PurchaseHistory />} />
            <Route path="loginHistory" element={<LoginHistory />} />
          </Route>
          <Route path="help" element={<Help />} />
          <Route path="chat" element={<ChatFrontend />} />
          <Route path="profile" element={<Profile />} />
          <Route path="profile/edit" element={<EditProfile />} />
          <Route path="notification" element={<SocialMedia />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>

        {/*Backend Authentication routes */}
        <Route path="/backend/login" element={<BackendAuth />} />
        {/* <Route path="/backend/forgotpassword" element={<ForgotPassword />} /> */}

        {/* Backend Portal routes */}
        <Route path="/backend" element={<BackendProtectRoute />} >
          <Route index element={<AccessManagement />} />
          <Route path="blog" element={<Blog />} />
          <Route path="view/blog/:id" element={<ViewBlog />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="clients" element={<Clients />} />
          <Route path="billings" element={<Billings />} />
          <Route path="tickets/unsolved" element={<UnsolvedTicket />} />
          <Route path="tickets/solved" element={<SolvedTicket />} />
          <Route path="tickets/add" element={<AddTicket />} />
          <Route path="*" element={<PageNotFound />} />

          {/* Home route */}
          <Route path="home/accessmanagement" element={<AccessManagement />} />
          <Route path="home/analytics" element={<Analytics />} />

          {/* Chat route start */}
          <Route path="chat" element={<Chat />} />
          

        </Route>
      </Routes>
    </>


  );
};

export default AllRoutes;
