import React, { useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { TbArrowBackUp } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { partnershipSchema } from '../../../schemas';
import { GoChevronDown } from "react-icons/go";
import { notifyError, notifySuccess } from '../../../constants/toastAlerts';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { reloadPage } from '../../../redux/actions/load.action';
import brandlogoImage from "../../../assets/Vliv-logo white.webp";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import instagramLogo from "../../../assets/Instagram-Logo.webp";
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { partnershipAPI } from '../../../constants/api';

function Partnershipform() {
    const navigate = useNavigate();
    //Redux
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.themeReducer.theme);
    const loadData = useSelector((state) => state.loadReducer);

    //state
    const options = useMemo(() => countryList().getData(), [])
    const initialValues = {
        type: '',
        name: '',
        website: '',
        size: '',
        email: '',
        contactno: '',
        country: null,
        services: '',
    };

    const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        initialValues,
        // validationSchema: partnershipSchema,
        onSubmit: async (values, action) => {
            try {
                // setLoad(true);
                dispatch(reloadPage(true));
                const backendReqData = {
                    type: values.type,
                    name: values.name,
                    website: values.website,
                    size: values.size,
                    email: values.email,
                    contactno: values.contactno,
                    country: values.country.label,
                    services: values.services,
                }
                const response = await axios.post(partnershipAPI, backendReqData, {withCredentials:true});
                if (response.data.message == 'Form submitted successfully') {
                    // setLoad(false);
                    setTimeout(() => {
                        dispatch(reloadPage(false));
                        notifySuccess(response.data.message);
                    }, 1500)
                } else {
                    setTimeout(() => {
                        dispatch(reloadPage(false));
                        notifyError(response.data.message);
                    }, 1500)
                }
            } catch (error) {
                console.log(`Error in contact us form - ${error}`);
            }
            // action.resetForm();
        },
    });

    const handleContactForm = () => {
        navigate('/partnership');
    };

    const handleCountryChange = (selectedOption) => {
        setFieldValue('country', selectedOption);
    };

    return (
        <section className={`section-contactus ${theme == "light" ? "light" : "dark"}`} >
            <h3 className={`container contactus-button ${theme == "light" ? "light" : "dark"}`}>
                <span onClick={handleContactForm} className='button-section'>
                    <span className='button-icon'><TbArrowBackUp /></span>
                    <span>Back</span>
                </span>
            </h3>
            <div className={`container grid grid-two--cols contactus-form--container ${theme == "light" ? "light" : "dark"}`}>
                <div className='contactus-logo--container'>
                    <img src={brandlogoImage} alt="Vliv Logo not available" />
                    <h2>"Vliv invites you to join our thriving Partnership Program"</h2>
                </div>
                <div className='contactus-form'>
                    <div className={`contact-form ${theme}`}>
                        {/* <h3 className='contactus-header'>Enter Your Details Below</h3> */}
                        <h4 className='contactus-subheading'>Let’s Get Start by Filling this Form</h4>
                        <form className='form-section' onSubmit={handleSubmit}>
                            <div className='companysizeContainer'>
                                <label htmlFor="type" >Kind of Partnership</label>
                                <select
                                    name="type"
                                    className='form-feild company-size'
                                    value={values.type}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    required
                                >
                                    <option value="" className='select-placeholder'>--Select--</option>
                                    <option value="implementation">Implementation</option>
                                    <option value="refferal">Refferal</option>
                                </select>
                                <span className='select-icon'><GoChevronDown /></span>
                                <small className={`text-danger ${theme == "light" ? "light" : "dark"} form-text`}>
                                    {touched.type && errors.type ? <>{errors.type}</> : null}
                                </small>
                            </div>
                            <div className='companynameContainer'>
                                <label htmlFor="name" >{values.type == "implementation" ? "Company Name" : "Name"}</label>
                                <input type="text"
                                    name='name'
                                    placeholder='Enter Your Company Name'
                                    className='form-feild'
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    required
                                />
                                <small className={`text-danger ${theme == "light" ? "light" : "dark"} form-text`}>
                                    {touched.name && errors.name ? <>{errors.name}</> : null}
                                </small>
                            </div>
                            {values.type == "implementation" && <div className='companynameContainer'>
                                <label htmlFor="website" >Company Website</label>
                                <input type="text"
                                    name='website'
                                    placeholder='Paste Your company website url'
                                    className='form-feild'
                                    value={values.website}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    required
                                />
                                <small className={`text-danger ${theme == "light" ? "light" : "dark"} form-text`}>
                                    {touched.website && errors.website ? <>{errors.website}</> : null}
                                </small>
                            </div>}
                            {values.type == "implementation" && <div className='companysizeContainer'>
                                <label htmlFor="size" >Company Size</label>
                                <select
                                    name="size"
                                    className='form-feild company-size'
                                    value={values.size}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    required
                                >
                                    <option value="" className='select-placeholder'>Company size</option>
                                    <option value="Sole Proprietor / Individual">Sole Proprietor / Individual</option>
                                    <option value="1-10 Employees">1-10 Employees</option>
                                    <option value="11-50 Employees">11-50 Employees</option>
                                    <option value="51-100 Employees">51-100 Employees</option>
                                    <option value="101-500 Employees">101-500 Employees</option>
                                    <option value="501-1,000 Employees">501-1,000 Employees</option>
                                    <option value="1,001-5,000 Employees">1,001-5,000 Employees</option>
                                    <option value="5,001-10,000 Employees">5,001-10,000 Employees</option>
                                </select>
                                <span className='select-icon'><GoChevronDown /></span>
                                <small className={`text-danger ${theme == "light" ? "light" : "dark"} form-text`}>
                                    {touched.size && errors.size ? <>{errors.size}</> : null}
                                </small>
                            </div>}
                            <div className='emailContainer'>
                                <label htmlFor="email" >{values.type == "implementation" ? "Business Email" : "Mail Address"}</label>
                                <input type="email"
                                    name='email'
                                    placeholder='Enter Your Business Email Address'
                                    className='form-feild'
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    required
                                />
                                <small className={`text-danger ${theme == "light" ? "light" : "dark"} form-text`}>
                                    {touched.email && errors.email ? <>{errors.email}</> : null}
                                </small>
                            </div>
                            <div className='phoneContainer'>
                                <label htmlFor="contactno" >{values.type == "implementation" ? "Official Contact Number" : "Contact Number"}</label>
                                <input type="number"
                                    name='contactno'
                                    placeholder='Contact Number'
                                    className='form-feild'
                                    value={values.contactno}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                />
                                <small className={`text-danger ${theme == "light" ? "light" : "dark"} form-text`}>
                                    {touched.contactno && errors.contactno ? <>{errors.contactno}</> : null}
                                </small>
                            </div>
                            <div className='countryContainer'>
                                <label htmlFor="country" >Country</label>
                                <Select options={options} value={values.country} onChange={(e) => handleCountryChange(e)} className='country-feild' onBlur={handleBlur}
                                    name="country" />
                                <small className={`text-danger ${theme == "light" ? "light" : "dark"} form-text`}>
                                    {touched.mobilenumber && errors.mobilenumber ? <>{errors.mobilenumber}</> : null}
                                </small>
                            </div>
                            {values.type == "implementation" && <div className='serviceoffer'>
                                <label htmlFor="services" >Services You Offer</label>
                                <input type="text"
                                    name='services'
                                    placeholder='Enter Details'
                                    className='form-feild'
                                    value={values.services}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    required
                                />
                                <small className={`text-danger ${theme == "light" ? "light" : "dark"} form-text`}>
                                    {touched.services && errors.services ? <>{errors.services}</> : null}
                                </small>
                            </div>}
                            <div className='button-container'>
                                <button type='submit' className='commonBtn'>
                                    {loadData ? <CircularProgress className='common-loading' size={20} /> : "Submit"}
                                </button>
                            </div>
                            <div className='socialmediaContainer'>
                                <span className={`followus ${theme}`}>Follow Us</span>
                                <div className='image-container'>
                                    <ul>
                                        <li ><a href="https://x.com/VlivApp" target='_blank'><span className='twitter'><FaSquareXTwitter /></span></a></li>
                                        <li ><a href="https://www.linkedin.com/company/vliv-app/" target='_blank'><span className='linkedin' target='_blank'><FaLinkedin /></span></a></li>
                                        <li ><a href="https://www.facebook.com/vlivapp" target='_blank'><span className='facebook'><FaFacebookSquare /></span></a></li>
                                        <li className='instagram'><a href="https://www.instagram.com/vliv.app/" target='_blank'><img src={instagramLogo} alt="Instagram logo not available" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Partnershipform;

// import React, { useState, useMemo } from 'react'
// import { useDispatch, useSelector } from 'react-redux';
// import { TbArrowBackUp } from "react-icons/tb";
// import { useNavigate } from 'react-router-dom';
// import { useFormik } from "formik";
// import { partnershipSchema } from '../../../schemas';
// import { GoChevronDown } from "react-icons/go";
// import { notifyError, notifySuccess } from '../../../constants/toastAlerts';
// import axios from 'axios';
// import CircularProgress from '@mui/material/CircularProgress';
// import { reloadPage } from '../../../redux/actions/load.action';
// import brandlogoImage from "../../../assets/Vliv-logo white.webp";
// import { FaSquareXTwitter } from "react-icons/fa6";
// import { FaLinkedin } from "react-icons/fa";
// import { FaFacebookSquare } from "react-icons/fa";
// import instagramLogo from "../../../assets/Instagram-Logo.webp";
// import Select from 'react-select';
// import countryList from 'react-select-country-list';

// function Partnershipform() {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const theme = useSelector((state) => state.themeReducer.theme);
//   const loadData = useSelector((state) => state.loadReducer);

//   const [loading, setLoading] = useState(false);
//   const options = useMemo(() => countryList().getData(), []);

//   const initialValues = {
//     type: '',
//     name: '',
//     website: '',
//     size: '',
//     email: '',
//     contactno: '',
//     country: null,
//     services: '',
//   };

//   const {
//     values,
//     touched,
//     errors,
//     handleBlur,
//     handleChange,
//     handleSubmit,
//     setFieldValue,
//   } = useFormik({
//     initialValues,
//     // validationSchema: partnershipSchema,
//     onSubmit: async (values, action) => {
//         console.log("values",values)
//       try {
//         setLoading(true);
//         const response = await axios.post('/company/addtowishlist', values);
//         if (response.data.success) {
//           notifySuccess(response.data.message);
//         } else {
//           notifyError(response.data.message);
//         }
//       } catch (error) {
//         notifyError('An error occurred while submitting the form.');
//       } finally {
//         setLoading(false);
//         action.resetForm();
//       }
//     },
//   });

//   const handleContactForm = () => {
//     navigate('/partnership');
//   };

//   const handleCountryChange = (selectedOption) => {
//     setFieldValue('country', selectedOption);
//   };

//   return (
//     <section className={`section-contactus ${theme === 'light' ? 'light' : 'dark'}`}>
//       <h3 className={`container contactus-button ${theme === 'light' ? 'light' : 'dark'}`}>
//         <span onClick={handleContactForm} className="button-section">
//           <span className="button-icon">
//             <TbArrowBackUp />
//           </span>
//           <span>Back</span>
//         </span>
//       </h3>
//       <div
//         className={`container grid grid-two--cols contactus-form--container ${
//           theme === 'light' ? 'light' : 'dark'
//         }`}
//       >
//         <div className="contactus-logo--container">
//           <img src={brandlogoImage} alt="Vliv Logo not available" />
//           <h2>"Vliv invites you to join our thriving Partnership Program"</h2>
//         </div>
//         <div className="contactus-form">
//           <div className={`contact-form ${theme}`}>
//             <h4 className="contactus-subheading">Let’s Get Started by Filling this Form</h4>
//             <form className="form-section" onSubmit={handleSubmit}>
//               {/* Partnership Type */}
//               <div className="companysizeContainer">
//                 <label htmlFor="type">Kind of Partnership</label>
//                 <select
//                   name="type"
//                   className="form-feild company-size"
//                   value={values.type}
//                   onChange={handleChange}
//                   onBlur={handleBlur}
//                   required
//                 >
//                   <option value="">--Select--</option>
//                   <option value="implementation">Implementation</option>
//                   <option value="refferal">Referral</option>
//                 </select>
//                 <span className="select-icon">
//                   <GoChevronDown />
//                 </span>
//                 {touched.type && errors.type && (
//                   <small className={`text-danger ${theme} form-text`}>{errors.type}</small>
//                 )}
//               </div>

//               {/* Name */}
//               <div className="companynameContainer">
//                 <label htmlFor="name">
//                   {values.type === 'implementation' ? 'Company Name' : 'Name'}
//                 </label>
//                 <input
//                   type="text"
//                   name="name"
//                   placeholder="Enter Your Company Name"
//                   className="form-feild"
//                   value={values.name}
//                   onChange={handleChange}
//                   onBlur={handleBlur}
//                   required
//                 />
//                 {touched.name && errors.name && (
//                   <small className={`text-danger ${theme} form-text`}>{errors.name}</small>
//                 )}
//               </div>

//               {/* Conditionally Rendered Fields */}
//               {values.type === 'implementation' && (
//                 <>
//                   {/* Website */}
//                   <div className="companynameContainer">
//                     <label htmlFor="website">Company Website</label>
//                     <input
//                       type="text"
//                       name="website"
//                       placeholder="Paste Your Company Website URL"
//                       className="form-feild"
//                       value={values.website}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       required
//                     />
//                     {touched.website && errors.website && (
//                       <small className={`text-danger ${theme} form-text`}>{errors.website}</small>
//                     )}
//                   </div>

//                   {/* Company Size */}
//                   <div className="companysizeContainer">
//                     <label htmlFor="size">Company Size</label>
//                     <select
//                       name="size"
//                       className="form-feild company-size"
//                       value={values.size}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       required
//                     >
//                       <option value="">Company size</option>
//                       <option value="Sole Proprietor / Individual">
//                         Sole Proprietor / Individual
//                       </option>
//                       <option value="1-10 Employees">1-10 Employees</option>
//                       <option value="11-50 Employees">11-50 Employees</option>
//                       <option value="51-100 Employees">51-100 Employees</option>
//                       <option value="101-500 Employees">101-500 Employees</option>
//                       <option value="501-1,000 Employees">501-1,000 Employees</option>
//                       <option value="1,001-5,000 Employees">1,001-5,000 Employees</option>
//                       <option value="5,001-10,000 Employees">5,001-10,000 Employees</option>
//                     </select>
//                     <span className="select-icon">
//                       <GoChevronDown />
//                     </span>
//                     {touched.size && errors.size && (
//                       <small className={`text-danger ${theme} form-text`}>{errors.size}</small>
//                     )}
//                   </div>
//                 </>
//               )}

//               {/* Email */}
//               <div className="emailContainer">
//                 <label htmlFor="email">
//                   {values.type === 'implementation' ? 'Business Email' : 'Mail Address'}
//                 </label>
//                 <input
//                   type="email"
//                   name="email"
//                   placeholder="Enter Your Business Email Address"
//                   className="form-feild"
//                   value={values.email}
//                   onChange={handleChange}
//                   onBlur={handleBlur}
//                   required
//                 />
//                 {touched.email && errors.email && (
//                   <small className={`text-danger ${theme} form-text`}>{errors.email}</small>
//                 )}
//               </div>

//               {/* Contact Number */}
//               <div className="phoneContainer">
//                 <label htmlFor="contactno">
//                   {values.type === 'implementation' ? 'Official Contact Number' : 'Contact Number'}
//                 </label>
//                 <input
//                   type="text"
//                   name="contactno"
//                   placeholder="Contact Number"
//                   className="form-feild"
//                   value={values.contactno}
//                   onChange={handleChange}
//                   onBlur={handleBlur}
//                 />
//                 {touched.contactno && errors.contactno && (
//                   <small className={`text-danger ${theme} form-text`}>{errors.contactno}</small>
//                 )}
//               </div>

//               {/* Country */}
//               <div className="countryContainer">
//                 <label htmlFor="country">Country</label>
//                 <Select
//                   options={options}
//                   value={values.country}
//                   onChange={handleCountryChange}
//                   onBlur={handleBlur}
//                   name="country"
//                   className="country-feild"
//                 />
//                 {touched.country && errors.country && (
//                   <small className={`text-danger ${theme} form-text`}>{errors.country}</small>
//                 )}
//               </div>

//               {/* Services Offered */}
//               {values.type === 'implementation' && (
//                 <div className="serviceoffer">
//                   <label htmlFor="services">Services You Offer</label>
//                   <input
//                     type="text"
//                     name="services"
//                     placeholder="Enter Details"
//                     className="form-feild"
//                     value={values.services}
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     required
//                   />
//                   {touched.services && errors.services && (
//                     <small className={`text-danger ${theme} form-text`}>{errors.services}</small>
//                   )}
//                 </div>
//               )}

//               {/* Submit Button */}
//               <div className="button-container">
//                 <button type="submit" className="commonBtn" disabled={loading}>
//                   {loading ? <CircularProgress size={20} /> : 'Submit'}
//                 </button>
//               </div>

//               {/* Social Media Links */}
//               <div className="socialmediaContainer">
//                 <span className={`followus ${theme}`}>Follow Us</span>
//                 <div className="image-container">
//                   <ul>
//                     <li>
//                       <a href="https://x.com/VlivApp" target="_blank" rel="noopener noreferrer">
//                         <span className="twitter">
//                           <FaSquareXTwitter />
//                         </span>
//                       </a>
//                     </li>
//                     <li>
//                       <a
//                         href="https://www.linkedin.com/company/vliv-app/"
//                         target="_blank"
//                         rel="noopener noreferrer"
//                       >
//                         <span className="linkedin">
//                           <FaLinkedin />
//                         </span>
//                       </a>
//                     </li>
//                     <li>
//                       <a
//                         href="https://www.facebook.com/vlivapp"
//                         target="_blank"
//                         rel="noopener noreferrer"
//                       >
//                         <span className="facebook">
//                           <FaFacebookSquare />
//                         </span>
//                       </a>
//                     </li>
//                     <li className="instagram">
//                       <a
//                         href="https://www.instagram.com/vliv.app/"
//                         target="_blank"
//                         rel="noopener noreferrer"
//                       >
//                         <img src={instagramLogo} alt="Instagram logo" />
//                       </a>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default Partnershipform;


