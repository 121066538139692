import React from 'react';
import { useSelector } from 'react-redux';
import brandlogo from "../../assets/Vliv logo.png";
import nightbrandlogo from "../../assets/Vliv logo-white.png";
import { Link, NavLink } from 'react-router-dom';
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import instagramLogo from "../../assets/Instagram-Logo.webp";

function Footer() {
    const theme = useSelector((state) => state.themeReducer.theme);
    const pages = [
        // {
        //     path: "/",
        //     name: "Home",
        //     // icon: <MdDashboard />,
        // },
        {
            path: "/features",
            name: "Features",
            // icon: <MdDashboard />,
        },
        {
            path: "/plans",
            name: "Pricing",
            // icon: <MdDashboard />,
        },
        {
            path: "/blogs",
            name: "Knowledge Hub",
            // icon: <MdDashboard />,
        },
        {
            path: "/partnership",
            name: "Partnership",
            // icon: <MdDashboard />,
        },
    ];
    const TandCpages = [
        {
            path: "/terms-and-condition",
            name: "Terms and Conditions",
            // icon: <MdDashboard />,
        },
        {
            path: "/privacy-policy",
            name: "Privacy Policy",
            // icon: <MdDashboard />,
        },
        {
            path: "/cancellation-and-refund",
            name: "Cancellation And Refund",
            // icon: <MdDashboard />,
        },
        {
            path: "/cookies-policy",
            name: "Cookies Policy",
            // icon: <MdDashboard />,
        },
    ];
    const currentyear = new Date().getFullYear();
    return (
        <>
            {/* Footer start */}
            <footer className={`section-footer ${theme == "light" ? "light" : "dark"}`} data-aos="fade-up">
                <div className={`container grid grid-five--cols footer ${theme == "light" ? "light" : "dark"}`} >
                    {/* <div className={`grid grid-three--cols vliv-container ${theme == "dark" && "dark"}`}> */}
                    <div className='info-container'>
                        <div>
                            <Link to="/">
                                <img src={theme === "light" ? brandlogo : nightbrandlogo} alt="Brand Logo not available" className='vliv-footer-logo' />
                            </Link>
                        </div>
                        <div className='image-container'>
                            <span className='followus'>Follow Us</span>
                            <ul>
                                <li ><a href="https://x.com/VlivApp" target='_blank'><span className='twitter'><FaSquareXTwitter /></span></a></li>
                                <li ><a href="https://www.linkedin.com/company/vliv-app/" target='_blank'><span className='linkedin'><FaLinkedin /></span></a></li>
                                <li ><a href="https://www.facebook.com/vlivapp" target='_blank'><span className='facebook'><FaFacebookSquare /></span></a></li>
                                <li className='instagram'><a href="https://www.instagram.com/vliv.app/" target='_blank'><img src={instagramLogo} alt="Instagram logo not available" /></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='page-container'>
                        <ul>
                            {pages.map((page, index) => {
                                return (
                                    <li key={index}>
                                        <NavLink
                                            to={page.path}
                                            className={`navlink ${theme === "light" ? 'light' : 'dark'}`}
                                        >
                                            {page.name}
                                        </NavLink>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className='page-container third-container'>
                        <ul className='termandconditions'>
                            {TandCpages.map((page, index) => {
                                return (
                                    <li key={index}>
                                        <NavLink
                                            to={page.path}
                                            className={`navlink ${theme === "light" ? 'light' : 'dark'}`}
                                        >
                                            {page.name}
                                        </NavLink>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className='fourth-container'></div>
                    {/* <div className='contactus-section'> */}
                        <div className='page-container'>
                            <ul>
                                <li><h2 className='contactus-heading'>Contact Us</h2></li>
                                <li><a href='https://vliv.app/' target='_blank' className={theme}>https://vliv.app/</a></li>
                                <li><a href='mailto:info@vliv.app' target='_blank' className={theme}>Email: info@vliv.app</a></li>
                                <li><a href="tel:+916613500218" target='_blank' className={theme}>Phone: +91 6613500218</a></li>
                            </ul>
                        {/* </div> */}
                    </div>
                </div>
                <div className='footer-end'>
                    <div className='container footer-heading'>
                        <ul>
                            <li>&copy;{currentyear} Venturesathi Business Services LLP, All rights reserved</li>
                            <li>A SaaS product by Venturesathi Business Services LLP</li>
                        </ul>
                    </div>
                </div>
            </footer>
            {/* Footer end */}
        </>
    )
}

export default Footer;