import React from 'react'
import { useSelector } from 'react-redux';
import Redirect from '../../../constants/Redirect';
import EmailLink from '../../../constants/Email';

function CookiesPolicy() {
  //Redux
  const theme = useSelector((state) => state.themeReducer.theme);
  return (
    <>
      {/* TandC section start */}
      < section className={`section-cookiepolicy ${theme == "light" ? "light" : "dark"}`}>
        <div className={`container hero-container ${theme == "dark" && "dark"}`}>
          <h1>Cookies Policy </h1>
          <p className='common-paragraph'>
            Venturesathi Business Services LLP ("Venturesathi," "we," "us," or "our") uses cookies and similar
            technologies on our website, vliv.app (the "Website") and in connection with our SaaS-based web
            application, Vliv (the "Service"). This Cookie Policy explains what cookies are, how we use them, and your
            choices regarding cookies.
          </p>

          <ul className='common-list'>
            <li>
              <h2 className='common-defination'>What are Cookies? </h2>
              <p className='common-paragraph my-2'> We use the term 'Cookies' to refer to all technologies which store and access information on the device
                that you use to access the Site, such as your computer, tablet, or mobile phone. For example, we use http
                cookies, which are small data files (typically made up of numbers and letters) that are downloaded when
                you access our Site and allow us to recognize your device. </p>

              <p className='common-paragraph my-2'>We use Cookies to help us to enhance the online experience of our visitors and to better understand how
                the Site is used. For example, Cookies may tell us whether you have visited the Site before or whether you
                are a new visitor. </p>

              <p className='common-paragraph my-2'>Personal Information which is being gathered through the Service consists of any personal details
                provided consciously and voluntarily by a Customer (Employer), End User or the Customer’s administrator
                or through your use of the Vliv platform. This may include your name (first and last), nickname, birthdate,
                gender, nationality, job title, phone number(s), date you first started working for your employer,
                department you work in, employee ID/ national security number, address, country, city, postcode, family
                status, spouse’s and other dependents name, gender and birth date, your bank account details (bank
                name, account number, branch address), details regarding your salary and work (pay period, payment
                frequency, base salary, gross salary, overtime, bonuses, commissions, statutory payments such as sick,
                maternity/paternity leave, salary payment currency, credential regarding the right to work in your
                jurisdiction, tax code, emergency contact details (name, relation, phone number(s), email address(es),
                city, country, post code), termination date, termination reason, probation end date, status in the system
                and in the workplace, IP address and other unique identifiers, User’s information relating to tax
                declarations, information the Customer chooses to collect and other information User may choose to
                provide to Venturesathi and to its employee. </p>
            </li>
            <li>
              <h2 className='common-defination'>How We Use Cookies</h2>
              <p className='common-paragraph my-2'>We use cookies for a variety of purposes, including:</p>
              <ul className='my-2'>
                <li>
                  Essential Cookies: These cookies are essential for the operation of the Website and Service. They
                  allow you to navigate and use the features of the Website and Service, such as accessing secure
                  areas.
                </li>
                <li>
                  Performance Cookies: These cookies collect information about how visitors use the Website and
                  Service, such as which pages are most visited and if they receive error messages. This
                  information helps us improve the performance and usability of the Website and Service.
                </li>
                <li>
                  Functionality Cookies: These cookies allow the Website and Service to remember choices you
                  make (such as your language preference) and provide enhanced, more personal features.
                </li>
                <li>Analytics Cookies: These cookies collect information about your use of the Website and Service.
                  This information is used to help us understand how the Website and Service are being used and
                  to make improvements.
                </li>
              </ul>
              <p className='common-paragraph my-2'>As is true of most web sites and apps, we gather certain information automatically and store it in log files.
                This information includes Internet protocol (IP) addresses, browser type, Internet service provider (ISP),
                referring/exit pages, operating system, date/time stamp, and clickstream data. </p>

              <p className='common-paragraph my-2'>We send you push notifications from time-to-time to update you about any events or promotions that we
                may be running. If you no longer wish to receive these types of communications, you may turn them off
                at the device level. To ensure you receive proper notifications, we will need to collect certain information
                about your device such as operating system and user identification information. </p>

            </li>
            <li>
              <h2 className='common-defination'>Third-Party Cookies</h2>
              <p className='common-paragraph my-2'>We may allow third-party companies to place cookies on your device when you visit the Website or use
                the Service. These companies may use information they collect through their cookies to serve you with
                relevant advertising on other websites and services. These third parties may include, but are not limited
                to, analytics providers and advertising networks. </p>
            </li>
            <li>
              <h2 className='common-defination'>Your Cookie Choices</h2>
              <p className='common-paragraph my-2'>If you do not want the Site to collect information using cookies you can use the cookie consent tool and
                / or set your web browser to reject cookies from the Site. Each browser is different, so you should check
                your browser's "Help", "Tools" or "Edit" menu/facility to learn how to change your cookie preferences
                (e.g., for “do-not-track-me” functions). </p>
              <ul className='my-2'>
                <li>
                  For the Chrome web browser, please visit this page from Google:
                  <Redirect path="https://support.google.com/accounts/answer/32050" />
                </li>
                <li>
                  For the Internet Explorer web browser, please visit this page from Microsoft:
                  <Redirect path="http://support.microsoft.com/kb/278835" />
                </li>
                <li>
                  For the Firefox web browser, please visit this page from Mozilla:
                  <Redirect path="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored" />
                </li>
                <li>
                  For the Safari web browser, please visit this page from Apple:
                  <Redirect path="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" />
                </li>
              </ul>
              <p className='common-paragraph my-2'>For any other web browser, please visit your web browser’s official web pages.</p>
              <p className='common-paragraph my-2'>If You do not accept Our Cookies, You may experience some inconvenience in your use of the Website and some features may not function properly.</p>
              <p className='common-paragraph my-2'>For further information about cookies, including how to see what cookies have been set on your device and how to manage and delete them, please visit </p>
              <Redirect path="https://youronlinechoices.eu/" />
            </li>
            <li>
              <h2 className='common-defination'>Contact Us</h2>
              <p className='common-paragraph my-2'>If you have any questions about our use of cookies, please contact us at: <EmailLink email="info@vliv.app" /></p>
            </li>
          </ul>
        </div>
      </ section >
      {/* TandC section end */}
    </>
  )
}

export default CookiesPolicy