import React, { useCallback, useRef, useState } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  ThemeProvider,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  CircularProgress,
} from "@mui/material";
import Cookies from "js-cookie";
import axios from "axios";
import { FaCloudUploadAlt } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import JoditEditor from "jodit-react";
import styles from "../AddBlog.module.css"; // Import CSS Module
import "../JoditEditorStyles.css"; // Import JoditEditor specific styles
import { useDropzone } from "react-dropzone";

const theme = createTheme({
  typography: {
    fontSize: 18,
    h6: {
      fontSize: "2rem",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: "2rem",
        },
      },
    },
  },
});

function AddBlog({ refresh, setRefresh, handleTriggerCreateBlog }) {
  const userInfo = JSON.parse(Cookies.get("backendinfo"));
  const { name } = userInfo;
  const editor = useRef(null);
  // console.log(name, "name form add blog ")

  const [data, setData] = useState({
    cardimage: "",
    cardtitle: "",
    description: [],
    username:name,
  });

  const [open, setOpen] = useState(false);
  const [imgLoader, setImgLoader] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file.size > 1 * 1024 * 1024) {
      alert("File size exceeds 1MB. Please upload a file smaller than 1MB.");
      return;
    }

    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);

    axios
      .post("https://vliv.app/blog/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setData((prev) => ({
          ...prev,
          cardimage: res.data.file,
        }));
      })
      .catch((err) => console.log(err));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleDescriptionChange = (content, index) => {
    setData((prev) => {
      const newDescription = [...prev.description];
      newDescription[index] = { type: "text", content };
      return { ...prev, description: newDescription };
    });
  };

  const handleDescriptionImageUpload = (e, index) => {
    setImgLoader(index);
    const file = e.target.files[0];
    if (file.size > 1 * 1024 * 1024) {
      alert("File size exceeds 1MB. Please upload a file smaller than 1MB.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    axios
      .post("https://vliv.app/blog/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setData((prev) => {
          const newDescription = [...prev.description];
          newDescription[index] = { type: "image", content: res.data.file };
          return { ...prev, description: newDescription };
        });
        setImgLoader(null)
      })
      .catch((err) => console.log(err));
  };

  const handleAddText = () => {
    setData((prev) => ({
      ...prev,
      description: [...prev.description, { type: "text", content: "" }],
    }));
    setOpen(false);
  };

  const handleAddImage = () => {
    setData((prev) => ({
      ...prev,
      description: [...prev.description, { type: "image", content: "" }],
    }));
    setOpen(false);
  };

  const handleRemoveDescription = (index) => {
    setData((prev) => {
      const newDescription = prev.description.filter((_, i) => i !== index);
      return { ...prev, description: newDescription };
    });
  };

  const handleSubmit = () => {
    // console.log(data, "data");
    if (data.cardtitle === "" || data.description.length === 0) {
      return alert("You have missing field");
    }
    axios
      .post(`https://vliv.app/blog/add`, data)
      .then((res) => {
        alert(res.data.message);
        setRefresh(!refresh);
        handleTriggerCreateBlog();
      })
      .catch((err) => console.log(err));
  };

  const handleCancel = () => {
    handleTriggerCreateBlog();
  };

  const handleAddMoreClick = () => {
    setOpen(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container className={styles.container}>
        <Box className={styles.box}>
          <Box className={styles.uploadBox}>
            <Typography>Add Image</Typography>
            <Box
              {...getRootProps()}
              className={`${styles.uploadArea} ${
                isDragActive ? styles.active : ""
              }`}
            >
              <input {...getInputProps()} />
              <Box>
                <Typography className={styles.uploadText}>
                  Drag & Drop or Browse the file
                </Typography>
                <Typography className={styles.uploadText}>
                  <FaCloudUploadAlt className={styles.uploadIcon} />
                </Typography>
              </Box>
            </Box>
            <Typography
              sx={{ fontSize: "11px", fontWeight: "bold", color: "grey" }}
            >
              Supported file type 'Img' {data.cardimage}
            </Typography>
          </Box>

          <Box className={styles.inputField}>
            <Typography>Title</Typography>
            <TextField
              placeholder="Title"
              variant="outlined"
              size="small"
              className={styles.inputField}
              name="cardtitle"
              value={data.cardtitle}
              onChange={handleInputChange}
            />
          </Box>

          {data.description.map((item, index) => (
            <Box key={index} className={styles.descriptionBox}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography>Description {index + 1}</Typography>
                <IconButton onClick={() => handleRemoveDescription(index)}>
                  <MdClose />
                </IconButton>
              </Box>
              {item.type === "text" ? (
                <JoditEditor
                  ref={editor}
                  value={item.content}
                  tabIndex={1}
                  onBlur={(newContent) =>
                    handleDescriptionChange(newContent, index)
                  }
                  onChange={() => {}}
                />
              ) : (
                <Box>
                  {imgLoader == index ? (
                    <>
                      {" "}
                      <CircularProgress />
                    </>
                  ) : (
                    <>
                      <input
                        type="file"
                        onChange={(e) => handleDescriptionImageUpload(e, index)}
                      />
                      {item.content && (
                        <Box className={styles.imagePreview}>
                          <img
                            src={item.content}
                            alt={`Description ${index + 1}`}
                          />
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              )}
            </Box>
          ))}

          <Button onClick={handleAddMoreClick}>Add More</Button>

          <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Choose an Option</DialogTitle>
            <DialogContent>
              <Button onClick={handleAddText}>Edit Text</Button>
              <Button onClick={handleAddImage}>Upload Image</Button>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </Box>

        <Box className={styles.buttonContainer}>
          <Button className={styles.cancelButton} onClick={handleCancel}>
            <b>Cancel</b>
          </Button>

          <Button className={styles.submitButton} onClick={handleSubmit}>
            <b>Submit</b>
          </Button>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default AddBlog;
