import { Button, CircularProgress, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { plans } from '../../../redux/actions/plans.action';
import { reloadPage } from '../../../redux/actions/load.action';
import { useFormik } from 'formik';
import { MdDelete, MdEdit } from "react-icons/md";
import { billingSchema } from '../../../schemas';
import axios from 'axios';
import { notifyError, notifySuccess, notifyWarning } from '../../../constants/toastAlerts';
import Loader from '../../../constants/Loader';
import { FaCheck } from "react-icons/fa6";
import { FaMinus, FaPlus } from 'react-icons/fa';

const STATE = {
    CANCELPLAN: "CANCELPLAN",
    UPGRADEPLAN: "UPGRADEPLAN",
    ADDUSER: "ADDUSER"
}

function BillingPlan() {
    const navigate = useNavigate();
    //redux
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.themeReducer.theme);
    // const loadData = useSelector((state) => state.loadReducer);
    const { id,
        email,
        status,
        userid,
        username,
        reportmanager,
        userphone,
        userrole,
        userprofile,
        companyemail,
        companyid,
        clientimage,
        gender,
        country,
        state,
        pincode,
        city,
        address,
        address2,
        typeofdocument,
        documentvalue,
        language,
        timezone,
        signature,
        designation,
        pan,
        twitter,
        linkedin,
        clientname,
        clientemail,
        companyname,
        companyimage,
        employeeid,
        pf,
        companygst,
        companyregno,
        countrycode,
        countryaddress,
    } = useSelector((state) => state.userDataReducer.userData || {})
    // console.log("address", address);

    const planData = useSelector((state) => state.planReducer.planData);
    // console.log("planData", planData);
    const basic = [planData.basic || {}];
    const premium = [planData.premium || {}];
    const standard = [planData.standard || {}];
    const yearlybasic = [planData.yearlybasic || {}];
    const yearlystandard = [planData.yearlystandard || {}];
    const yearlypremium = [planData.yearlypremium || {}];

    //state
    const [edit, setEdit] = useState(false);
    const [loadData, setLoadData] = useState("");
    const [additionaluser, setAdditionaluser] = useState(0);
    const addEmailField = () => {
        if (values.emails.length < 2) {
            setFieldValue('emails', [...values.emails, '']);
        }
    };

    const handleDelete = (index) => {
        const newEmails = values.emails.filter((_, i) => i !== index);
        setFieldValue('emails', newEmails);
    }

    const handleCancelSubscription = async (loadbuttonname) => {
        try {
            const body = {
                companyid,
                companyemail,
                canclestatus: true
            }
            setLoadData(loadbuttonname)
            const res = await axios.post("/company/cancleplan", body)

            if (res.data.message == "Plan subscription has been cancelled") {
                setTimeout(() => {
                    setLoadData("")
                    notifySuccess(res.data.message);
                }, 1000)
            }
            else {
                setTimeout(() => {
                    setLoadData("")
                    notifyError(res.data.message);
                }, 1000)
            }
        }
        catch (error) {
            notifyError(error);
            console.log(`Error while cancel plan subscription ${error}`)
        }
    }

    const handleUpgradePlan = (loadbuttonname) => {
        setLoadData(loadbuttonname);
        const currentplanname = !planData.planname ? "Basic" : planData.planname;
        let plan;
        if (planData.plansubscription == "Monthly") {
            switch (currentplanname) {
                case "Standard":
                    plan = standard;
                    break;
                case "Premium":
                    plan = premium;
                    break;
                default:
                    plan = basic;
            }
        }
        else {
            switch (currentplanname) {
                case "Standard":
                    plan = yearlystandard;
                    break;
                case "Premium":
                    plan = yearlypremium;
                    break;
                default:
                    plan = yearlybasic;
            }
        }
        // console.log("plandata", plan)
        setTimeout(() => {
            setLoadData("")
            navigate('/frontend/plan-summary', { state: { selectedPlan: plan, FreeTrial: false } });
        }, 1000)

    }

    const handleAdditionalUser = (loadbuttonname) => {
        setLoadData(loadbuttonname);
        const plancheckpageData = {
            id: planData?.companyid,
            companyid: planData?.companyid,
            color: planData?.color,
            planname: planData?.planname,
            planprice: planData?.planprice,
            previousprice: planData?.previousprice,
            users: planData?.users,
            additionalusercost: planData?.additionalusercost,
            additionalusers: Number(planData?.additionaluser) + Number(additionaluser),
            additionaluserprice: planData?.additionaluserprice,
            monthlyprice: planData?.monthlyprice,
            purchased: planData?.purchased,
            featureslist: planData?.featureslist,
            viewmorefeature: planData?.viewmorefeature,
            yearlyprice: planData?.yearlyprice,
            plansubscription: planData?.plansubscription,
            tax: planData?.tax,
            discount: planData?.discount,
            addonpack: planData?.addonpack,
            purchasedate: planData?.purchasedate,
            expiredate: planData?.expiredate,
            companyemail: planData?.companyemail,
            basic: planData?.basic,
            standard: planData?.standard,
            premium: planData?.premium,
            yearlybasic: planData?.yearlybasic,
            yearlystandard: planData?.yearlystandard,
            yearlypremium: planData?.yearlypremium,
            isfreetrial: planData?.isFreeTrial,
            userid: planData?.userid,
            name: clientname,
            number: "9090909090",
            MID: "MID" + Date.now(),
            transactionid: "T" + Date.now(),
            purchaseaddonpack: planData?.selectedAddons || [],
            addonpackprice: planData?.addonpackprice,
            tax: 0,
            isadditional: true,
            totaladditionalusers :additionaluser,
            amount: Number(planData?.additionalusercost || 0) * Number(additionaluser),

        }
        
        if (planData?.plansubscription != "Yearly") {
            setTimeout(() => {
                setLoadData("");
                notifyWarning(`In ${planData?.plansubscription} ${planData?.planname} you cannot add more users`);
            }, 500)
        }
        else {
            setTimeout(() => {
                setLoadData("");
                navigate("/frontend/plan-checkout", { state: { selectedPlan: plancheckpageData, freeTrial: false } });
            }, 500)
        }
    }

    useEffect(() => {
        dispatch(plans({ "email": email }));
    }, []);

    //state
    const initialValues = {
        companyname: companyname,
        companygst: companygst,
        billingaddress: `${address}, ${state}, ${city}, ${pincode}, ${country}`,
        emails: [`${email}`]
    };

    const { values, touched, errors, handleBlur, handleChange, handleSubmit, setFieldValue } =
        useFormik({
            initialValues,
            validationSchema: billingSchema,
            onSubmit: async (values, action) => {
                try {
                    // setLoad(true);
                    dispatch(reloadPage(true));
                    const response = await axios.post("", values);

                    if (response.data.message === "Congratulations! You're now part of the VLIV waiting list. Your journey to a better HR and knowledge & learning management starts now!") {
                        // setLoad(false);
                        setTimeout(() => {
                            dispatch(reloadPage(false));
                            notifySuccess(response.data.message);
                        }, 1500)
                    } else {
                        setTimeout(() => {
                            dispatch(reloadPage(false));
                            notifyError(response.data.message);
                        }, 1500)
                    }
                } catch (error) {
                    console.log(`Error in contact us form - ${error}`);
                }
                action.resetForm();
            },
        });
    // console.log("initialValues", initialValues)
    return (
        <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
                <div className='billingandplan'>
                    <h2 className='common-heading' >Current Plan</h2>
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div className={`billing-form ${theme}`}>
                    <div className='billing-form-header'>
                        <h2 className='common-heading'>Billing information</h2>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={10}>
                        <div className={`billing-plan ${planData?.planname}`} >
                            <div className='plan-details' >
                                <h2>Vliv {planData.planname} Plan</h2>
                                <p className='plan-validity'>{planData?.daysleft} Days Left</p>
                            </div>

                            <div className='plan-details'>
                                <p className={`plan-subscription ${theme}`}>{planData?.plansubscription}</p>
                                <p className='plan-validity'>Expiry on : {planData?.expiredate}</p>
                            </div>
                            <div className='plan-details'>
                                <button className='commonBtn purchased' onClick={() => handleCancelSubscription(STATE.CANCELPLAN)}>
                                    {
                                        loadData == STATE.CANCELPLAN ? <Loader /> : "Cancel Subscription"
                                    }
                                </button>
                                <button className='commonBtn' onClick={() => handleUpgradePlan(STATE.UPGRADEPLAN)}>
                                    {
                                        loadData == STATE.UPGRADEPLAN ? <Loader /> : "Upgrade plan"
                                    }
                                </button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <div className='billingandplan'>
                            <h2 className='common-heading'>Add more users to current plans (Applicable only on yearly plan)</h2>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <div className={`billing-plan ${planData?.planname}`} >
                            <div className='plan-details' >
                                <h2>Total Users</h2>
                                <p className='plan-validity'>{Number(planData.users) + Number(planData.additionaluser) + additionaluser} Users</p>
                            </div>

                            <div className='plan-details'>
                                <p className={`plan-subscription ${theme}`}>Add More users</p>
                                <p className='plan-validity additionuser'>
                                    <span className='usersbtn' onClick={() => setAdditionaluser(() => additionaluser > 0 ? additionaluser - 1 : additionaluser)}><FaMinus /></span>
                                    <span>{additionaluser}</span>
                                    <span className='usersbtn' onClick={() => setAdditionaluser(() => additionaluser + 1)}><FaPlus /></span>

                                </p>
                            </div>
                            <div className='plan-details'>
                                <button className='commonBtn purchased' onClick={() => handleCancelSubscription(STATE.CANCELPLAN)} style={{ visibility: "hidden" }}>
                                    {
                                        loadData == STATE.CANCELPLAN ? <Loader /> : "Cancel Subscription"
                                    }
                                </button>
                                <button className={`commonBtn ${additionaluser <= 0 ? "commonBtnDisable" : ""}`}
                                    onClick={() => additionaluser > 0 && handleAdditionalUser(STATE.ADDUSER)}
                                    disabled={additionaluser <= 0}>
                                    {loadData == STATE.ADDUSER ? <Loader /> : `Pay Now for ${additionaluser} user`}
                                </button>

                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={10}>
                        <div className='billingandplan'>
                            <h2 className='common-heading'>You have Acess To</h2>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <div className={`billing-plan ${planData?.planname}`}>
                            <div className='second-container'>
                                {/* <span className='core-feature'>Core Features</span> */}
                                <ul>
                                    {
                                        planData.featureslist?.slice(0, 5).map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <span><FaCheck /></span>
                                                    <span> {item}</span>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>

                                <ul>
                                    {
                                        planData.featureslist?.slice(5, 10).map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <span><FaCheck /></span>
                                                    <span> {item}</span>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={12} md={6}>
                <div className={`billing-form ${theme}`}>

                    <form className='form-section' onSubmit={handleSubmit}>
                        <div className='nameContainer'>
                            <label htmlFor="companyname" >Company Name</label>
                            <input
                                type="text"
                                name='companyname'
                                placeholder='Enter Your Company Name'
                                className='form-feild'
                                value={values.companyname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                autoComplete="off"
                                required
                                disabled={!edit}
                            />
                            <small className='small-text'>
                                This will appear your invoice
                            </small>
                            <small className={`text-danger ${theme == "light" ? "light" : "dark"} form-text`} >
                                {touched.companyname && errors.companyname ? <>{errors.companyname}</> : null}
                            </small>
                        </div>
                        <div className='gstContainer'>
                            <label htmlFor="companygst" >Company GSTIN</label>
                            <input
                                type="text"
                                name='companygst'
                                placeholder='Enter Your GSTIN'
                                className='form-feild'
                                value={values.companygst}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                autoComplete="off"
                                required
                                disabled={!edit}
                            />
                            <small className='small-text'>
                                This will appear your invoice
                            </small>
                            <small className={`text-danger ${theme == "light" ? "light" : "dark"} form-text`} >
                                {touched.companygst && errors.companygst ? <>{errors.companygst}</> : null}
                            </small>
                        </div>
                        <div className='billingAddress'>
                            <label htmlFor="billingaddress" >Billing Address</label>
                            <textarea
                                rows={5}
                                cols={10}
                                name='billingaddress'
                                placeholder='Enter Your Company Address'
                                className='form-feild'
                                value={values.billingaddress}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                autoComplete="off"
                                required
                                disabled={!edit}
                            />
                            <small className={`text-danger ${theme == "light" ? "light" : "dark"} form-text`} >
                                {touched.billingaddress && errors.billingaddress ? <>{errors.billingaddress}</> : null}
                            </small>
                        </div>
                        <div className='billingContacts'>
                            {/* <h3 className='common-heading'>Billing Contacts</h3> */}
                            {/* <div> */}

                            {values.emails.map((email, index) => (
                                <div key={index} >
                                    <label htmlFor="email">Billing Contacts</label>
                                    <div className='emailContainers'>

                                        <input
                                            type="email"
                                            name={`emails.${index}`}
                                            placeholder='Enter Your Mail Address'
                                            className='form-feild'
                                            value={email}
                                            onChange={handleChange} onBlur={handleBlur}
                                            autoComplete="off"
                                            required
                                            disabled={!edit}
                                        />
                                        <span className='deleteicon' onClick={() => handleDelete(index)}><MdDelete /></span>
                                    </div>
                                    <small className='small-text'>
                                        All billing-emails will be sent to your email address and these billing contacts
                                    </small>
                                    <small className={`text-danger ${theme === "light" ? "light" : "dark"} form-text`}>
                                        {touched[`email${index}`] && errors[`email${index}`] ? errors[`email${index}`] : null}
                                    </small>
                                </div>
                            ))}
                            {/* </div> */}
                        </div>
                        <span onClick={addEmailField} className='newemail' style={{ display: "inline-block" }}>+ Add new</span>

                        <div className='button-container'>
                            {!edit &&
                                <button className='commonBtn purchased' onClick={() => setEdit(!edit)}>
                                    Modify
                                </button>
                            }
                            {edit &&
                                <>
                                    <button className='commonBtn purchased' onClick={() => setEdit(!edit)}>
                                        Cancel
                                    </button>
                                    <button type='submit' className='commonBtn' >
                                        {loadData ? <CircularProgress className='common-loading' size={20} /> : "Save"}
                                    </button>
                                </>
                            }
                        </div>
                    </form>
                </div>
            </Grid >
        </Grid >
    )
}

export default BillingPlan