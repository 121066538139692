import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { Box } from "@mui/material";

function FrontendLayout() {
  return (
    <>
      <Box className="root-main">
        <Navbar />
        <main id="main">
          <Sidebar />
          <div className="section-main">
            <Outlet/>
          </div>
        </main>
      </Box>
    </>
  );
}

export default FrontendLayout;
