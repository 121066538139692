import html2pdf from 'html2pdf.js';

export const generatePdf = async (invoiceid, containerRef) => {
  
  const element = containerRef.current;  // Reference to the payment receipt section
  if (!element) {
    console.log('Element not found for PDF generation.');
    return;
  }

  try {
    const options = {
      margin: 0.5,
      filename: `Payment-${invoiceid || 'no-invoice'}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
    };

    await html2pdf().set(options).from(element).save();
  } catch (error) {
    console.log('Error generating PDF:', error);
  }
};