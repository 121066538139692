import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { TbArrowBackUp } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { contactusSchema } from '../../../schemas';
import { GoChevronDown } from "react-icons/go";
import { notifyError, notifySuccess } from '../../../constants/toastAlerts';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { reloadPage } from '../../../redux/actions/load.action';
import brandlogoImage from "../../../assets/Vliv-logo white.webp";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import instagramLogo from "../../../assets/Instagram-Logo.webp";

function Contactus() {
    //Redux
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.themeReducer.theme);
    const loadData = useSelector((state) => state.loadReducer);

    //state
    const initialValues = {
        name: "",
        email: "",
        mobilenumber: "",
        company: "",
        companysize: "",
    };

    const navigate = useNavigate();

    //function
    const handleContactForm = () => {
        navigate("/")
    };

    const { values, touched, errors, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            validationSchema: contactusSchema,
            onSubmit: async (values, action) => {
                try {
                    // setLoad(true);
                    dispatch(reloadPage(true));
                    const response = await axios.post("/company/addtowishlist", values);

                    if (response.data.message === "Congratulations! You're now part of the VLIV waiting list. Your journey to a better HR and knowledge & learning management starts now!") {
                        // setLoad(false);
                        setTimeout(() => {
                            dispatch(reloadPage(false));
                            notifySuccess(response.data.message);
                        }, 1500)
                    } else {
                        setTimeout(() => {
                            dispatch(reloadPage(false));
                            notifyError(response.data.message);
                        }, 1500)
                    }
                } catch (error) {
                    console.log(`Error in contact us form - ${error}`);
                }
                action.resetForm();
            },
        });
    return (
        <section className={`section-contactus ${theme == "light" ? "light" : "dark"}`} >
            <h3 className={`container contactus-button ${theme == "light" ? "light" : "dark"}`}>
                <span onClick={handleContactForm} className='button-section'>
                    <span className='button-icon'><TbArrowBackUp /></span>
                    <span>Back</span>
                </span>
            </h3>
            <div className={`container grid grid-two--cols contactus-form--container ${theme == "light" ? "light" : "dark"}`}>
                <div className='contactus-logo--container'>
                    <img src={brandlogoImage} alt="Vliv Logo not available" />
                    <h2>"Be the first to know and get an exclusive look of all our upcoming products"</h2>
                </div>
                <div className='contactus-form'>
                    <div className={`contact-form ${theme}`}>
                        <h3 className='contactus-header'>Enter Your Details Below</h3>
                        <h4 className='contactus-subheading'>Don't miss this opportunity to be an early adopters!</h4>
                        <form className='form-section' onSubmit={handleSubmit}>
                            <div className='nameContainer'>
                                <label htmlFor="name" >Name</label>
                                <input
                                    type="text"
                                    name='name'
                                    placeholder='Enter Your Name'
                                    className='form-feild'
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    required
                                />
                                <small className={`text-danger ${theme == "light" ? "light" : "dark"} form-text`} >
                                    {touched.name && errors.name ? <>{errors.name}</> : null}
                                </small>
                            </div>
                            <div className='emailContainer'>
                                <label htmlFor="email" >Email Address</label>
                                <input type="email"
                                    name='email'
                                    placeholder='Enter Your Mail Address'
                                    className='form-feild'
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    required
                                />
                                <small className={`text-danger ${theme == "light" ? "light" : "dark"} form-text`}>
                                    {touched.email && errors.email ? <>{errors.email}</> : null}
                                </small>
                            </div>
                            <div className='phoneContainer'>
                                <label htmlFor="mobilenumber" >Official Contact Number</label>
                                <input type="number"
                                    name='mobilenumber'
                                    placeholder='Mobile (optional)'
                                    className='form-feild'
                                    value={values.mobilenumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                />
                                <small className={`text-danger ${theme == "light" ? "light" : "dark"} form-text`}>
                                    {touched.mobilenumber && errors.mobilenumber ? <>{errors.mobilenumber}</> : null}
                                </small>
                            </div>
                            <div className='companynameContainer'>
                                <label htmlFor="company" >Company Name</label>
                                <input type="text"
                                    name='company'
                                    placeholder='Enter Your Company Name'
                                    className='form-feild'
                                    value={values.company}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    required
                                />
                                <small className={`text-danger ${theme == "light" ? "light" : "dark"} form-text`}>
                                    {touched.company && errors.company ? <>{errors.company}</> : null}
                                </small>
                            </div>
                            <div className='companysizeContainer'>
                                <label htmlFor="companysize" >Company Size</label>
                                <select
                                    name="companysize"
                                    className='form-feild company-size'
                                    value={values.companysize}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    required
                                >
                                    <option value="" className='select-placeholder'>Company size</option>
                                    <option value="Sole Proprietor / Individual">Sole Proprietor / Individual</option>
                                    <option value="1-10 Employees">1-10 Employees</option>
                                    <option value="11-50 Employees">11-50 Employees</option>
                                    <option value="51-100 Employees">51-100 Employees</option>
                                    <option value="101-500 Employees">101-500 Employees</option>
                                    <option value="501-1,000 Employees">501-1,000 Employees</option>
                                    <option value="1,001-5,000 Employees">1,001-5,000 Employees</option>
                                    <option value="5,001-10,000 Employees">5,001-10,000 Employees</option>
                                </select>
                                <span className='select-icon'><GoChevronDown /></span>
                                <small className={`text-danger ${theme == "light" ? "light" : "dark"} form-text`}>
                                    {touched.companysize && errors.companysize ? <>{errors.companysize}</> : null}
                                </small>
                            </div>
                            <div className='button-container'>
                                <button type='submit' className='commonBtn'>
                                    {loadData ? <CircularProgress className='common-loading' size={20} /> : "Signup now"}
                                </button>
                            </div>
                            <div className='socialmediaContainer'>
                                <span className={`followus ${theme}`}>Follow Us</span>
                                <div className='image-container'>
                                    <ul>
                                        <li ><a href="https://x.com/VlivApp" target='_blank'><span className='twitter'><FaSquareXTwitter /></span></a></li>
                                        <li ><a href="https://www.linkedin.com/company/vliv-app/" target='_blank'><span className='linkedin' target='_blank'><FaLinkedin /></span></a></li>
                                        <li ><a href="https://www.facebook.com/vlivapp" target='_blank'><span className='facebook'><FaFacebookSquare /></span></a></li>
                                        <li className='instagram'><a href="https://www.instagram.com/vliv.app/" target='_blank'><img src={instagramLogo} alt="Instagram logo not available" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contactus