import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
// import Header from "./Components/Header/Header";
import AllRoutes from "./routes/AllRoutes";
import "./App.css";
import "./styles/layout.css";
import "./styles/module.css";
import "./styles/theme.css";
// import Sidebar from "./Company/Components/Sidebar/Sidebar";
// import Navbar from "./Company/Components/Sidebar/Navbar";
import { useDispatch, useSelector } from 'react-redux';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from "./constants/ScrollToTop";
import { userData } from "./redux/actions/login.action";

const App = () => {
  //Redux
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.themeReducer.theme);
  const loadData = useSelector((state) => state.loadReducer);
  // const { pathname } = useLocation();
  // const navigate = useNavigate();

  useEffect(() => {
    document.body.className = theme;
    AOS.init({
      duration: 1000,
    });
  }, [theme]);

  useEffect(() => {
    dispatch(userData());
  }, [dispatch, loadData]);

  return (
    <>
      <ScrollToTop />
      <AllRoutes />
      {/* <CompanyAllRoute/> */}
    </>
  );
};

export default App;
