import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { FaSearch } from "react-icons/fa";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import _ from 'lodash';
import { vlivBackendApi } from '../../../constants/api';
import { formattedDate, formattedTime } from '../../../constants/formatedDate';
import LoginHistoryModal from './LoginHistoryModal';

function LoginHistory() {
    const [searchKeyword, setSearchKeyword] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [logHistoryData, setLogHistoryData] = useState({});
    const [open, setOpen] = useState(false);
    const [rowData, setRowData] = useState(null);

    const containerRef = useRef(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const columns = [
        { id: 'userid', label: 'User Id', minWidth: 120, align: 'center', },
        { id: 'name', label: 'Username', minWidth: 125, align: 'center', },

        { id: 'module', label: 'Module', minWidth: 125, align: 'center', },
        {
            id: 'date',
            label: 'Date',
            minWidth: 130,
            align: 'center',
            // format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'logintime',
            label: 'Login Time',
            minWidth: 100,
            align: 'center',
            // format: (value) => value.toFixed(2),
        },
        // {
        //     id: 'location',
        //     label: 'Location',
        //     minWidth: 350,
        //     align: 'center',
        //     // format: (value) => value.toFixed(2),

        // },
        {
            id: 'city',
            label: 'City',
            minWidth: 120,
            align: 'center',
            // format: (value) => value.toFixed(2),
        },
        {
            id: 'state',
            label: 'State',
            minWidth: 50,
            align: 'center',
            // format: (value) => value.toFixed(2),
        },
        {
            id: 'country',
            label: 'Country',
            minWidth: 50,
            align: 'center',
            // format: (value) => value.toFixed(2),
        },
        {
            id: 'location',
            label: 'Location Access',
            minWidth: 120,
            align: 'center',
            // format: (value) => value.toFixed(2),
        },
        {
            id: 'action',
            label: 'Action',
            minWidth: 50,
            align: 'center',
            // format: (value) => value.toFixed(2),
        },

    ];

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${vlivBackendApi}/user/loginhistory`, {}, { withCredentials: true });
            const loghistoryData = response.data.data.length > 0 && response.data.data;
            setLogHistoryData(loghistoryData);
        }
        catch (error) {
            console.log("Error in fetching history status ", error)
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    //search start
    const handleSearch = async (value) => {
        setLoading(true);
        setSearchKeyword(value);
    };

    const debouncedSearch = useCallback(_.debounce(() => {
        setLoading(false);
    }, 300), []);

    const filteredData = () => {
        if (logHistoryData.length > 0) {
            debouncedSearch()
            return logHistoryData?.filter((item) =>
                Object.values(item)?.some(value =>
                    value?.toString()?.toLowerCase()?.includes(searchKeyword.toLowerCase().trim())
                )
            );
        }
        else {
            return [];
        }

    }

    const filterData = filteredData();

    //search end

    //modal start
    const handleOpen = (rowData) => {
        setOpen(true);
        setRowData(rowData);
    }

    const handleClose = () => {
        setOpen(false);
        setRowData(null);

    };

    //modal end

    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={12} md={12}>
                    <div className='purchase'>
                        <div>
                            <h2 className='common-heading' >Log History</h2>
                        </div>
                        <div className='searchFeild'>
                            <span className='search-icon'><FaSearch /></span>
                            <input type="search" placeholder='Search... ' value={searchKeyword} onChange={(e) => handleSearch(e.target.value)} className='form-feild' />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={12}>
                    <div className='purchase-table'>
                        <div style={{ height: 400, width: '100%' }}>
                            <TableContainer sx={{ maxHeight: 370 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow className='tableheader'>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loading ? (
                                            <TableRow>
                                                <TableCell colSpan={12} align="center">
                                                    <CircularProgress />
                                                </TableCell>
                                            </TableRow>
                                        ) : filterData.length > 0 ? (
                                            filterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                                const {
                                                    id,
                                                    email,
                                                    userid,
                                                    name,
                                                    companyid,
                                                    companyemail,
                                                    module,
                                                    location,
                                                    ip,
                                                    city,
                                                    region,
                                                    country,
                                                    state,
                                                    createdAt,
                                                    updatedAt
                                                } = row;

                                                return (
                                                    <TableRow
                                                        key={row.id}
                                                        colSpan={12}
                                                    >
                                                        <TableCell>{userid || "--"}</TableCell>
                                                        <TableCell>{name || "--"}</TableCell>
                                                        <TableCell>
                                                            {module && module.length > 0
                                                                ? module.map((item) => item || "--").join(" → ")
                                                                : "--"}
                                                        </TableCell>
                                                        <TableCell>{formattedDate(updatedAt) || "--"}</TableCell>
                                                        <TableCell>{formattedTime(createdAt) || "--"}</TableCell>
                                                        {/* <TableCell>{location || "--"}</TableCell> */}

                                                        <TableCell>{city || "--"}</TableCell>
                                                        <TableCell>{state || "--"}</TableCell>
                                                        <TableCell>{country || "--"}</TableCell>
                                                        <TableCell>{!ip ? "Yes" : "No" || "--"}</TableCell>
                                                        <TableCell>
                                                            {
                                                                <span className='view-icon' onClick={() => handleOpen(row)}>
                                                                    <MdOutlineRemoveRedEye />
                                                                </span>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }
                                            )
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={12} align="center">
                                                    <h2 >No Log History Found</h2>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                className='commonpagination'
                                rowsPerPageOptions={[10, 25, 100, filterData.length]}
                                component="div"
                                count={filterData.length || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid >

            {open ? <LoginHistoryModal
                open={open}
                handleClose={handleClose}
                rowData={rowData}
            /> : null}

        </>
    )
}

export default LoginHistory;