import {
  Box,
  Container,
  createTheme,
  ThemeProvider,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "../JoditEditorStyles.css"; // Import JoditEditor specific styles

const theme = createTheme({
  typography: {
    fontSize: 18,
    h6: {
      fontSize: "2rem",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: "2rem",
        },
      },
    },
  },
});

function ViewBlog() {
  const { id } = useParams();
  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .post(`https://vliv.app/blog/getbyid`, { id })
      .then((res) => {
        setBlogData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Container
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Container>
      </ThemeProvider>
    );
  }

  if (!blogData) {
    return (
      <ThemeProvider theme={theme}>
        <Container
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Typography>Blog not found</Typography>
        </Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container id="view-blog-container" sx={{ width: "100%", paddingTop: 5 }}>
        <Box>
          <Box
            sx={{
              marginBottom: 1,
              width: "100%",
              background: "#ffff",
              padding: 2,
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              borderRadius: "5px",
            }}
          >
            <Typography variant="h6">{blogData.cardtitle}</Typography>
            {blogData.cardimage && (
              <Box
                sx={{
                  marginBottom: 1,
                  width: "100%",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={blogData.cardimage}
                  alt="Blog"
                  width="150"
                  height="280"
                  style={{ width: "100%", borderRadius: "10px" }}
                />
              </Box>
            )}
            <Typography>{blogData.username}</Typography>
            <Typography
              sx={{ fontSize: "11px", fontWeight: "bold", color: "grey" }}
            >
              {new Date(blogData.createdAt).toLocaleString()}
            </Typography>
          </Box>

          <Box sx={{ marginBottom: 1, width: "100%", fontSize: "1.5rem" }}>
            <Typography>Description</Typography>
            {blogData.description.map((item, index) => (
              <Box key={index} sx={{ marginBottom: 2 }}>
                {item.type === "text" ? (
                  <Box
                    sx={{ width: "100%", fontSize: "1.5rem" }}
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={item.content}
                      alt={`Description ${index + 1}`}
                      style={{ maxWidth: "100%", borderRadius: "10px" }}
                      width="300"
                      height="150"
                    />
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default ViewBlog;
