import React, { useEffect, useState } from 'react';
import { IoAddCircleSharp } from "react-icons/io5";
import { FaCheckCircle, FaMinus, FaRupeeSign } from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';
// import { basic, premium, standard, yearlybasic, yearlypremium, yearlystandard } from '../../../Home/Plans/PlanData';
import { useDispatch, useSelector } from 'react-redux';
import { todayDate } from '../../../../constants/getDate';
import { plans } from '../../../../redux/actions/plans.action';
import { RiAwardFill } from 'react-icons/ri';
import limitedofferimage from "../../../../assets/OFF.webp";
import Loader from '../../../../constants/Loader';
import { reloadPage } from '../../../../redux/actions/load.action';
import { Slider } from '@mui/material';
import { IoMdAdd } from "react-icons/io";
import { notifyWarning } from '../../../../constants/toastAlerts';

function PlanSummary() {
    const location = useLocation();
    const navigate = useNavigate();
    const selectedplans = location.state?.selectedPlan || [];
    const isFreeTrial = location.state?.freeTrial || false;

    //redux
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.themeReducer.theme);
    const loadData = useSelector((state) => state.loadReducer);
    const { id,
        email,
        status,
        userid,
        username,
        reportmanager,
        userphone,
        userrole,
        userprofile,
        companyemail,
        companyid,
        clientimage,
        gender,
        country,
        state,
        pincode,
        city,
        address,
        address2,
        typeofdocument,
        documentvalue,
        language,
        timezone,
        signature,
        designation,
        pan,
        twitter,
        linkedin,
        clientname,
        clientemail,
        companyname,
        companyimage,
        employeeid,
        pf,
        companygst,
        companyregno,
        countrycode,
        countryaddress } = useSelector((state) => state.userDataReducer.userData || {})

    const planData = useSelector((state) => state.planReducer.planData);
    const basic = [planData.basic || {}];
    const premium = [planData.premium || {}];
    const standard = [planData.standard || {}];
    const yearlybasic = [planData.yearlybasic || {}];
    const yearlystandard = [planData.yearlystandard || {}];
    const yearlypremium = [planData.yearlypremium || {}];
    // console.log("basic", basic)
    // State
    const [open, setOpen] = useState(false);
    const [currentViewMore, setCurrentViewMore] = useState('');
    const [isViewMore, setIsViewMore] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(selectedplans);
    const [subscriptionType, setSubscriptionType] = useState(selectedplans[0]?.plansubscription);
    const [basicUsers, setBasicUsers] = useState(0);
    const [standardUsers, setStandardUsers] = useState(0);
    const [premiumUsers, setPremiumUsers] = useState(0);

    useEffect(() => {
        dispatch(plans({ "email": email }));
    }, []);

    //Get Expiry Date
    const expiryDate = () => {
        if (subscriptionType === "Monthly" && !isFreeTrial) {
            const today = new Date();
            today.setDate(today.getDate() + 30);
            const day = String(today.getDate()).padStart(2, '0');
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = today.getFullYear();
            return `${day}-${month}-${year}`;
        }
        else if (subscriptionType === "Yearly" && !isFreeTrial) {
            const today = new Date();
            today.setDate(today.getDate() + 365);
            const day = String(today.getDate()).padStart(2, '0');
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = today.getFullYear();
            return `${day}-${month}-${year}`;
        }
        else {
            const today = new Date();
            today.setDate(today.getDate() + 7);
            const day = String(today.getDate()).padStart(2, '0');
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = today.getFullYear();
            return `${day}-${month}-${year}`;
        }
    }

    const getPrice = () => {
        if (!isFreeTrial) {
            const price = subscriptionType === "Monthly" ? selectedPlan[0]?.monthlyprice * selectedPlan[0]?.users : selectedPlan[0]?.yearlyprice * selectedPlan[0]?.users;
            return price;
        }
        else {
            return 0;
        }
    }

    const handleIncrement = () => {
        switch (selectedPlan[0].planname) {
            case basic[0].planname:
                setBasicUsers((prev) => prev + 1);
                break;
            case standard[0].planname:
                setStandardUsers((prev) => prev + 1);
                break;
            case premium[0].planname:
                setPremiumUsers((prev) => prev + 1);
                break;
            default:
                break;
        }
    };

    const handleDecrement = () => {
        switch (selectedPlan[0].planname) {
            case basic[0].planname:
                setBasicUsers((prev) => (prev > 0 ? prev - 1 : prev));
                break;
            case standard[0].planname:
                setStandardUsers((prev) => (prev > 0 ? prev - 1 : prev));
                break;
            case premium[0].planname:
                setPremiumUsers((prev) => (prev > 0 ? prev - 1 : prev));
                break;
            default:
                break;
        }

    };

    const handleSliderChange = (event, newValue,) => {
        switch (selectedPlan[0].planname) {
            case basic[0].planname:
                setBasicUsers(newValue);
                break;
            case standard[0].planname:
                setStandardUsers(newValue);
                break;
            case premium[0].planname:
                setPremiumUsers(newValue);
                break;
            default:
                break;
        }

    };

    const getAdditionalUserCost = (plan) => {
        switch (plan[0].planname) {
            case basic[0].planname:
                return basicUsers * basic[0].additionalusercost || 0;
            case standard[0].planname:
                return standardUsers * standard[0].additionalusercost || 0;
            case premium[0].planname:
                return premiumUsers * premium[0].additionalusercost || 0;
            default:
                return 0;
        }
    }

    const handleOpen = () => {
        if (location.pathname.includes('/frontend')) {
            setOpen(true);
        }
        else {
            navigate("/frontend/login");
        }
    };

    const handleClose = () => {
        setOpen(false);
        setIsViewMore(false);
        navigate("/frontend/home")
    };

    const handleViewmore = (plan) => {
        if (currentViewMore === plan[0].planname && isViewMore) {
            setIsViewMore(false);
            setCurrentViewMore("");
        } else {
            setCurrentViewMore(plan[0].planname);
            setIsViewMore(true);
        }
    };

    const handleCheckboxChange = (event, id, addonName, addonPrice, planName) => {
        let updatedAddons = [...(selectedPlan && selectedPlan && selectedPlan[0].selectedAddons || [])];
        if (event.target.checked) {
            updatedAddons = [...updatedAddons, { id, addonpackname: addonName, addonprice: addonPrice }];
        } else {
            updatedAddons = updatedAddons.filter(addon => addon.id !== id);
        }

        const updatedSelectedPlan = {
            ...selectedPlan && selectedPlan && selectedPlan[0],
            selectedAddons: updatedAddons
        };
        setSelectedPlan([updatedSelectedPlan]);
    };

    const handlePlanSelection = (planname) => {
        if (
           (planname[0].plansubscription == "Monthly" && ((planname[0].planname == "Basic" && basic[0].purchased == "Downgrade") ||
            (planname[0].planname == "Standard" && standard[0].purchased == "Downgrade") ||
            (planname[0].planname == "Standard" && premium[0].purchased == "Downgrade")))
        ) {
            return notifyWarning("To downgrade your current plan kindly contact our support team.")
        }

        if (
            (planname[0].plansubscription == "Yearly" && ((planname[0].planname == "Basic" && yearlybasic[0].purchased == "Downgrade") ||
            (planname[0].planname == "Standard" && yearlystandard[0].purchased == "Downgrade") ||
            (planname[0].planname == "Standard" && yearlypremium[0].purchased == "Downgrade")))
        ) {
            return notifyWarning("To downgrade your current plan kindly contact our support team.")
        }

        if (selectedPlan && selectedPlan[0].planname !== planname[0].planname) {
            setSelectedPlan(planname);
            setCurrentViewMore(planname[0].planname);

            // Uncheck all checkboxes
            const checkboxes = document.querySelectorAll('.addon-pack input[type="checkbox"]');
            checkboxes.forEach((checkbox) => {
                checkbox.checked = false;
            });
            setBasicUsers(0);
            setStandardUsers(0);
            setPremiumUsers(0);

            return;
        }


    };

    const handleSubscriptionTypeChange = (type) => {
        setSubscriptionType(type);

        let newSelectedPlan;

        if (type === "Yearly") {
            newSelectedPlan = selectedPlan && selectedPlan[0].planname === "Basic" ? yearlybasic :
                selectedPlan && selectedPlan[0].planname === "Standard" ? yearlystandard :
                    yearlypremium;

            // Uncheck all checkboxes
            const checkboxes = document.querySelectorAll('.addon-pack input[type="checkbox"]');
            checkboxes.forEach((checkbox) => {
                checkbox.checked = false;
            });
        } else {
            newSelectedPlan = selectedPlan && selectedPlan[0].planname === "Basic" ? basic :
                selectedPlan && selectedPlan[0].planname === "Standard" ? standard :
                    premium;

            // Uncheck all checkboxes
            const checkboxes = document.querySelectorAll('.addon-pack input[type="checkbox"]');
            checkboxes.forEach((checkbox) => {
                checkbox.checked = false;
            });
        }


        setSelectedPlan(newSelectedPlan);
        setIsViewMore(false);
        setCurrentViewMore("");
        setBasicUsers(0);
        setStandardUsers(0);
        setPremiumUsers(0);
    };

    const handleSlider = (plan) => {
        if (plan) {
            return selectedPlan[0]?.planname != plan[0]?.planname;
        }
    }

    const getAmount = Number(getPrice()) + getAdditionalUserCost(selectedPlan) + (selectedPlan[0]?.selectedAddons?.length > 0 ? selectedPlan[0]?.selectedAddons.reduce((acc, addon) => acc + Number(!isFreeTrial ? addon.addonprice : 0), 0) : 0);

    const getTotalAmount = getAmount

    const handleContinue = async () => {
        dispatch(reloadPage(true));

        const backendReqBodyData = {
            id: companyid,
            companyid,
            color: selectedPlan[0].color,
            planname: selectedPlan[0].planname,
            planprice: Number(getPrice()),
            previousprice: selectedPlan[0].previousprice,
            users: selectedPlan[0].users,
            additionalusercost: selectedPlan[0]?.additionalusercost,
            additionalusers: selectedPlan[0].planname == "Basic" ? basicUsers : selectedPlan[0].planname == "Standard" ? standardUsers : premiumUsers,
            additionaluserprice: getAdditionalUserCost(selectedPlan),
            monthlyprice: selectedPlan[0].monthlyprice,
            purchased: selectedPlan[0].purchased = "yes",
            featureslist: selectedPlan[0].featureslist,
            viewmorefeature: selectedPlan[0].viewmorefeature,
            yearlyprice: selectedPlan[0].yearlyprice,
            plansubscription: selectedPlan[0].plansubscription,
            tax: selectedPlan[0].tax,
            discount: selectedPlan[0].discount,
            addonpack: selectedPlan[0].addonpack,
            purchasedate: todayDate(),
            expiredate: expiryDate(),
            companyemail: companyemail,
            basic: planData.basic,
            standard: planData.standard,
            premium: planData.premium,
            yearlybasic: planData.yearlybasic,
            yearlystandard: planData.yearlystandard,
            yearlypremium: planData.yearlypremium,
            isfreetrial: isFreeTrial,
            userid: companyid,
            name: clientname,
            number: "9090909090",
            MID: "MID" + Date.now(),
            transactionid: "T" + Date.now(),
            purchaseaddonpack: selectedPlan[0].selectedAddons || [],
            addonpackprice: (selectedPlan[0]?.selectedAddons?.length > 0 ? selectedPlan[0]?.selectedAddons.reduce((acc, addon) => acc + Number(!isFreeTrial ? addon.addonprice : 0), 0) : 0),
            isadditional:false,
            amount: getTotalAmount,
            // amount: 1

        }

        if (isFreeTrial) {
            setTimeout(() => {
                dispatch(reloadPage(false));
                navigate("/frontend/plan-checkout", { state: { selectedPlan: backendReqBodyData, freeTrial: true } });
            }, 500)
        }
        else {
            setTimeout(() => {
                dispatch(reloadPage(false));
                navigate("/frontend/plan-checkout", { state: { selectedPlan: backendReqBodyData, freeTrial: false } });
            }, 500)
        }
    };


    return (

        <div className={`section-plansummary ${theme}`}>
            {/* <button className="commonBtn" onClick={handleOpen}>Buy Plan</button> */}
            {/* <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            > */}
            {/* <Box className="section-modal--container"> */}
            <div className='modal-container plan-summary'>
                <div className='modal-header'>
                    <span><h2 className='modal-title'>VLIV Plans</h2></span>
                    <span className='crossicon' onClick={handleClose}>&times;</span>
                </div>
                <div className={`modal-body ${theme}`}>
                    <div className={`plans-main--container ${theme}`}>
                        <div className='plans-main--section'>
                            <div className="plans-section">
                                {subscriptionType === "Monthly" ? (
                                    <>
                                        <div className={`plan ${selectedPlan && selectedPlan && selectedPlan[0]?.planname === basic[0]?.planname && "Basic selected"}`} onClick={() => handlePlanSelection(basic)}>
                                            <div className='plan-header blue'>
                                                <h2 className='plan-name'>
                                                    <span className='basic-badge'><RiAwardFill /></span>
                                                    <span className='plan-title'>{basic[0]?.planname}</span>
                                                </h2>
                                                {
                                                    basic[0].previousprice != "" &&
                                                    <>
                                                        <span className='plan-prices'>&#8377;{basic[0].previousprice}</span> <span className='discount-price'>{basic[0].discount}% off</span>
                                                    </>
                                                }
                                                {
                                                    !isFreeTrial ? <h3 className='plan-price'>&#8377;{basic[0].monthlyprice}</h3> :
                                                        <h3 className='free-trail--plan-price'>7-Days Free Trial</h3>
                                                }
                                            </div>
                                            <div className='plan-body'>
                                                <div className='first-container'>
                                                    <span className='feature-header'>Features:</span>
                                                    <span className='features-title'>(HRM Basic Features)</span>
                                                </div>
                                                <div className='second-container'>
                                                    <ul>
                                                        {
                                                            basic[0]?.featureslist?.slice(0, 4).map((item, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <span><FaCheckCircle /></span>
                                                                        <span> {item}</span>
                                                                    </li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                    <span className='core-feature'>Core Features</span>
                                                    <ul>
                                                        {
                                                            basic[0]?.featureslist?.slice(4, 7).map((item, index) => {

                                                                return (
                                                                    <li key={index}>
                                                                        <span><FaCheckCircle /></span>
                                                                        <span> {item}</span>
                                                                    </li>
                                                                );
                                                            })
                                                        }
                                                        {currentViewMore === basic[0].planname && isViewMore && basic[0].featureslist?.slice(7).map((item, index) => (
                                                            <li key={index}>
                                                                <span><FaCheckCircle /></span>
                                                                <span> {item}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className='third-container'>
                                                    <span className='plan-viewmore' onClick={() => handleViewmore(basic)}>
                                                        {currentViewMore === basic[0].planname && isViewMore ? 'view less' : 'view more'}
                                                    </span>
                                                </div>
                                                <div className='fourth-container'>
                                                    {!isFreeTrial && <div>
                                                        <div className="addon-pack">
                                                            <span className='additional-user'>Additional Users (per User {basic[0]?.additionalusercost})</span>
                                                            <span>&#8377;{getAdditionalUserCost(basic)}</span>
                                                        </div>
                                                        <div className={`add-user ${handleSlider(basic) ? "notallowed" : ""}`}>
                                                            <span className='addonBtn' onClick={() => handleDecrement(basic)}><FaMinus /></span>
                                                            <span className='addonBtn'>{basicUsers}</span>
                                                            <span className='addonBtn' onClick={() => handleIncrement(basic)}><IoMdAdd /></span>
                                                            <span className='slider-container'>
                                                                <Slider
                                                                    value={basicUsers}
                                                                    min={0}
                                                                    max={100}
                                                                    onChange={(event, newValue) => handleSliderChange(event, newValue, basic)}
                                                                    // defaultValue={50}
                                                                    aria-label="Default"
                                                                    valueLabelDisplay="auto"
                                                                    disabled={handleSlider(basic)}
                                                                />

                                                            </span>
                                                        </div>
                                                    </div>}
                                                    <div>
                                                        <p className='addon-section'>
                                                            <span className='plus-icon'><IoAddCircleSharp /></span>
                                                            <span className='addon-text'>Add on {isFreeTrial && <span className='free-tails--addon'>(Only with Paid Plans)</span>}</span>
                                                        </p>
                                                        <div className="addon-pack--container">
                                                            {basic[0].addonpack?.map((item, index) => (
                                                                <div className="addon-pack" key={item.id}>
                                                                    <label>
                                                                        <span className={`custom-checkbox ${isFreeTrial && "nocursor"}`} >
                                                                            <input type="checkbox" id="resume-matcher" name="resume-matcher" onChange={(e) => handleCheckboxChange(e, item.id, item.addonpackname, item.addonprice, selectedPlan && selectedPlan && selectedPlan[0].planname)} disabled={isFreeTrial} />
                                                                            <span className="checkmark"></span>
                                                                        </span>
                                                                        {item.addonpackname}
                                                                    </label>
                                                                    <span className="price">
                                                                        &#8377;{item.addonprice}
                                                                    </span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className={`plan ${selectedPlan && selectedPlan && selectedPlan[0].planname === standard[0].planname && "Standard selected"}`} onClick={() => handlePlanSelection(standard)}>
                                            <div className='plan-header blue'>
                                                <h2 className='plan-name'>
                                                    <span className='standard-badge'><RiAwardFill /></span>
                                                    <span className='plan-title'>{standard[0].planname}</span>
                                                </h2>
                                                {
                                                    standard[0].previousprice != "" &&
                                                    <>
                                                        <span className='plan-prices'>&#8377;{standard[0].previousprice}</span>
                                                    </>
                                                }
                                                {
                                                    !isFreeTrial ? <h3 className='plan-price'>&#8377;{standard[0].monthlyprice}</h3> :
                                                        <h3 className='free-trail--plan-price'>7-Days Free Trial</h3>
                                                }

                                            </div>
                                            <div className='plan-body'>
                                                <div className='first-container'>
                                                    <span className='feature-header'>Features:</span>
                                                    <span className='features-title'>(HRM Standard + KMS)</span>
                                                </div>
                                                <div className='second-container'>
                                                    <ul>
                                                        {
                                                            standard[0]?.featureslist?.slice(0, 4).map((item, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <span><FaCheckCircle /></span>
                                                                        <span> {item}</span>
                                                                    </li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                    <span className='core-feature'>Everything in Basic +</span>
                                                    <ul>
                                                        {
                                                            standard[0]?.featureslist?.slice(4, 7).map((item, index) => {

                                                                return (
                                                                    <li key={index}>
                                                                        <span><FaCheckCircle /></span>
                                                                        <span> {item}</span>
                                                                    </li>
                                                                );
                                                            })
                                                        }
                                                        {currentViewMore === standard[0].planname && isViewMore && standard[0].featureslist?.slice(7).map((item, index) => (
                                                            <li key={index}>
                                                                <span><FaCheckCircle /></span>
                                                                <span> {item}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className='third-container'>
                                                    <span className='plan-viewmore' style={{ marginTop: "0rem" }} onClick={() => handleViewmore(standard)}>
                                                        {currentViewMore === standard[0].planname && isViewMore ? 'view less' : 'view more'}
                                                    </span>
                                                </div>
                                                <div className='fourth-container'>
                                                    {!isFreeTrial && <div>
                                                        <div className="addon-pack">
                                                            <span className='additional-user'>Additional Users (per User {standard[0]?.additionalusercost})</span>
                                                            <span>&#8377;{getAdditionalUserCost(standard)}</span>
                                                        </div>
                                                        <div className={`add-user ${handleSlider(standard) ? "notallowed" : ""}`}>
                                                            <span className='addonBtn' onClick={() => handleDecrement(standard)}><FaMinus /></span>
                                                            <span className='addonBtn'>{standardUsers}</span>
                                                            <span className='addonBtn' onClick={() => handleIncrement(standard)}><IoMdAdd /></span>
                                                            <span className='slider-container'>
                                                                <Slider
                                                                    value={standardUsers}
                                                                    min={0}
                                                                    max={100}
                                                                    onChange={(event, newValue) => handleSliderChange(event, newValue, standard)}
                                                                    // defaultValue={50}
                                                                    aria-label="Default"
                                                                    valueLabelDisplay="auto"
                                                                    disabled={handleSlider(standard)}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>}
                                                    <div>
                                                        <p className='addon-section'>
                                                            <span className='plus-icon'><IoAddCircleSharp /></span>
                                                            <span className='addon-text'>Add on {isFreeTrial && <span className='free-tails--addon'>(Only with Paid Plans)</span>}</span>
                                                        </p>
                                                        <div className="addon-pack--container">
                                                            {standard[0].addonpack?.map((item, index) => (
                                                                <div className="addon-pack" key={index}>
                                                                    <label>
                                                                        <span className={`custom-checkbox ${isFreeTrial && "nocursor"}`} >
                                                                            <input type="checkbox" id="resume-matcher" name="resume-matcher" onChange={(e) => handleCheckboxChange(e, item.id, item.addonpackname, item.addonprice, selectedPlan && selectedPlan && selectedPlan[0].planname)} disabled={isFreeTrial} />
                                                                            <span className="checkmark"></span>
                                                                        </span>
                                                                        {item.addonpackname}
                                                                    </label>
                                                                    <span className="price">
                                                                        &#8377;{item.addonprice}
                                                                    </span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`plan ${selectedPlan && selectedPlan && selectedPlan[0].planname === premium[0].planname && "Premium selected"}`} onClick={() => handlePlanSelection(premium)}>
                                            <div className='plan-header blue'>
                                                <h2 className='plan-name'>
                                                    <span className='premium-badge'><RiAwardFill /></span>
                                                    <span className='plan-title'>{premium[0].planname}</span>
                                                </h2>
                                                {
                                                    premium[0].previousprice != "" &&
                                                    <>
                                                        <span className='plan-prices'>&#8377;{premium[0].previousprice}</span>
                                                    </>

                                                }

                                                {
                                                    !isFreeTrial ? <h3 className='plan-price'>&#8377;{premium[0].monthlyprice}</h3> :
                                                        <h3 className='free-trail--plan-price'>7-Days Free Trial</h3>
                                                }
                                            </div>
                                            <div className='plan-body'>
                                                <div className='first-container'>
                                                    <span className='feature-header'>Features:</span>
                                                    <span className='features-title'>(HRM Advanced + KMS+ LMS)</span>
                                                </div>
                                                <div className='second-container'>
                                                    <ul>
                                                        {
                                                            premium[0]?.featureslist?.slice(0, 4).map((item, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <span><FaCheckCircle /></span>
                                                                        <span> {item}</span>
                                                                    </li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                    <span className='core-feature'>Everything in Standard +</span>
                                                    <ul>
                                                        {
                                                            premium[0]?.featureslist?.slice(4, 7).map((item, index) => {

                                                                return (
                                                                    <li key={index}>
                                                                        <span><FaCheckCircle /></span>
                                                                        <span> {item}</span>
                                                                    </li>
                                                                );
                                                            })
                                                        }
                                                        {currentViewMore === premium[0].planname && isViewMore && premium[0].featureslist?.slice(7).map((item, index) => (
                                                            <li key={index}>
                                                                <span><FaCheckCircle /></span>
                                                                <span> {item}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className='third-container'>
                                                    <span className='plan-viewmore' style={{ marginTop: "0rem" }} onClick={() => handleViewmore(premium)}>
                                                        {currentViewMore === premium[0].planname && isViewMore ? 'view less' : 'view more'}
                                                    </span>
                                                </div>
                                                <div className='fourth-container'>
                                                    {!isFreeTrial && <div>
                                                        <div className="addon-pack">
                                                            <span className='additional-user'>Additional Users (per User {premium[0]?.additionalusercost})</span>
                                                            <span>&#8377;{getAdditionalUserCost(premium)}</span>
                                                        </div>
                                                        <div className={`add-user ${handleSlider(premium) ? "notallowed" : ""}`}>
                                                            <span className='addonBtn' onClick={() => handleDecrement(premium)}><FaMinus /></span>
                                                            <span className='addonBtn'>{premiumUsers}</span>
                                                            <span className='addonBtn' onClick={() => handleIncrement(premium)}><IoMdAdd /></span>
                                                            <span className='slider-container'>
                                                                <Slider
                                                                    value={premiumUsers}
                                                                    min={0}
                                                                    max={100}
                                                                    onChange={(event, newValue) => handleSliderChange(event, newValue, premium)}
                                                                    // defaultValue={50}
                                                                    aria-label="Default"
                                                                    valueLabelDisplay="auto"
                                                                    disabled={handleSlider(premium)}
                                                                />

                                                            </span>
                                                        </div>
                                                    </div>}
                                                    <div>
                                                        <p className='addon-section'>
                                                            <span className='plus-icon'><IoAddCircleSharp /></span>
                                                            <span className='addon-text'>Add on {isFreeTrial && <span className='free-tails--addon'>(Only with Paid Plans)</span>}</span>
                                                        </p>
                                                        <div className="addon-pack--container">
                                                            {premium[0].addonpack?.map((item, index) => (
                                                                <div className="addon-pack" key={item.id}>
                                                                    <label>
                                                                        <span className={`custom-checkbox ${isFreeTrial && "nocursor"}`}>
                                                                            <input type="checkbox" id="resume-matcher" name="resume-matcher" onChange={(e) => handleCheckboxChange(e, item.id, item.addonpackname, item.addonprice, selectedPlan && selectedPlan && selectedPlan[0].planname)} disabled={isFreeTrial} />
                                                                            <span className="checkmark"></span>
                                                                        </span>
                                                                        {item.addonpackname}
                                                                    </label>
                                                                    <span className="price">
                                                                        &#8377;{item.addonprice}
                                                                    </span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className={`plan ${selectedPlan[0].planname === yearlybasic[0].planname && "Basic selected"}`} onClick={() => handlePlanSelection(yearlybasic)}>
                                            <img src={limitedofferimage} alt="limited period offer" className='discount-offer' />
                                            <div className='plan-header blue '>
                                                <h2 className='plan-name'>
                                                    <span className='basic-badge'><RiAwardFill /></span>
                                                    <span className='plan-title'>{yearlybasic[0].planname}</span>
                                                </h2>
                                                {
                                                    yearlybasic[0].previousprice != "" &&
                                                    <>
                                                        <span className='plan-prices'>&#8377;{yearlybasic[0].previousprice}</span>
                                                    </>
                                                }
                                                {
                                                    !isFreeTrial ? <h3 className='plan-price'>&#8377;{yearlybasic[0].yearlyprice}</h3> :
                                                        <h3 className='free-trail--plan-price'>7-Days Free Trial</h3>
                                                }

                                            </div>
                                            <div className='plan-body'>
                                                <div className='first-container'>
                                                    <span className='feature-header'>Features:</span>
                                                    <span className='features-title'>(HRM Basic Features)</span>
                                                </div>
                                                <div className='second-container'>
                                                    <ul>
                                                        {
                                                            yearlybasic[0].featureslist?.slice(0, 4).map((item, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <span><FaCheckCircle /></span>
                                                                        <span> {item}</span>
                                                                    </li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                    <span className='core-feature'>Core Features</span>
                                                    <ul>
                                                        {
                                                            yearlybasic[0].featureslist?.slice(4, 7).map((item, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <span><FaCheckCircle /></span>
                                                                        <span> {item}</span>
                                                                    </li>
                                                                );
                                                            })
                                                        }
                                                        {currentViewMore === yearlybasic[0].planname && isViewMore && yearlybasic[0].featureslist?.slice(7).map((item, index) => (
                                                            <li key={index}>
                                                                <span><FaCheckCircle /></span>
                                                                <span> {item}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className='third-container'>
                                                    <span className='plan-viewmore' onClick={() => handleViewmore(yearlybasic)}>
                                                        {currentViewMore === yearlybasic[0].planname && isViewMore ? 'view less' : 'view more'}
                                                    </span>
                                                </div>
                                                <div className='fourth-container'>
                                                    {!isFreeTrial && <div>
                                                        <div className="addon-pack">
                                                            <span className='additional-user'>Additional Users (per User {yearlybasic[0]?.additionalusercost})</span>
                                                            <span>&#8377;{getAdditionalUserCost(yearlybasic)}</span>
                                                        </div>
                                                        <div className={`add-user ${handleSlider(yearlybasic) ? "notallowed" : ""}`}>
                                                            <span className='addonBtn' onClick={() => handleDecrement(yearlybasic)}><FaMinus /></span>
                                                            <span className='addonBtn'>{basicUsers}</span>
                                                            <span className='addonBtn' onClick={() => handleIncrement(yearlybasic)}><IoMdAdd /></span>
                                                            <span className='slider-container'>
                                                                <Slider
                                                                    value={basicUsers}
                                                                    min={0}
                                                                    max={100}
                                                                    onChange={(event, newValue) => handleSliderChange(event, newValue, yearlybasic)}
                                                                    // defaultValue={50}
                                                                    aria-label="Default"
                                                                    valueLabelDisplay="auto"
                                                                    disabled={handleSlider(yearlybasic)}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>}
                                                    <div>
                                                        <p className='addon-section'>
                                                            <span className='plus-icon'><IoAddCircleSharp /></span>
                                                            <span className='addon-text'>Add on {isFreeTrial && <span className='free-tails--addon'>(Only with Paid Plans)</span>}</span>
                                                        </p>
                                                        <div className="addon-pack--container">
                                                            {yearlybasic[0].addonpack?.map((item, index) => (
                                                                <div className="addon-pack" key={item.id}>
                                                                    <label>
                                                                        <span className={`custom-checkbox ${isFreeTrial && "nocursor"}`}>
                                                                            <input type="checkbox" id="resume-matcher" name="resume-matcher" onChange={(e) => handleCheckboxChange(e, item.id, item.addonpackname, item.addonprice, selectedPlan && selectedPlan[0].planname)} disabled={isFreeTrial} />
                                                                            <span className="checkmark"></span>
                                                                        </span>
                                                                        {item.addonpackname}
                                                                    </label>
                                                                    <span className="price">
                                                                        &#8377;{item.addonprice}
                                                                    </span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`plan ${selectedPlan[0].planname === yearlystandard[0].planname && "Standard selected"}`} onClick={() => handlePlanSelection(yearlystandard)}>
                                            <img src={limitedofferimage} alt="limited period offer" className='discount-offer' />
                                            <div className='plan-header blue'>
                                                <h2 className='plan-name'>
                                                    <span className='premium-badge'><RiAwardFill /></span>
                                                    <span className='plan-title'>{yearlystandard[0].planname}</span>
                                                </h2>
                                                {
                                                    yearlystandard[0].previousprice != "" &&
                                                    <>
                                                        <span className='plan-prices'>&#8377;{yearlystandard[0].previousprice}</span>
                                                    </>
                                                }
                                                {
                                                    !isFreeTrial ? <h3 className='plan-price'>&#8377;{yearlystandard[0].yearlyprice}</h3> :
                                                        <h3 className='free-trail--plan-price'>7-Days Free Trial</h3>
                                                }

                                            </div>
                                            <div className='plan-body'>
                                                <div className='first-container'>
                                                    <span className='feature-header'>Features:</span>
                                                    <span className='features-title'>(HRM Standard + KMS)</span>
                                                </div>
                                                <div className='second-container'>
                                                    <ul>
                                                        {
                                                            yearlystandard[0].featureslist?.slice(0, 4).map((item, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <span><FaCheckCircle /></span>
                                                                        <span> {item}</span>
                                                                    </li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                    <span className='core-feature'>Everything in Basic +</span>
                                                    <ul>
                                                        {
                                                            yearlystandard[0].featureslist?.slice(4, 7).map((item, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <span><FaCheckCircle /></span>
                                                                        <span> {item}</span>
                                                                    </li>
                                                                );
                                                            })
                                                        }
                                                        {currentViewMore === yearlystandard[0].planname && isViewMore && yearlystandard[0].featureslist?.slice(7).map((item, index) => (
                                                            <li key={index}>
                                                                <span><FaCheckCircle /></span>
                                                                <span> {item}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className='third-container'>
                                                    <span className='plan-viewmore' style={{ marginTop: "0rem" }} onClick={() => handleViewmore(yearlystandard)}>
                                                        {currentViewMore === yearlystandard[0].planname && isViewMore ? 'view less' : 'view more'}
                                                    </span>
                                                </div>
                                                <div className='fourth-container'>
                                                    {!isFreeTrial && <div>
                                                        <div className="addon-pack">
                                                            <span className='additional-user'>Additional Users (per User {yearlystandard[0]?.additionalusercost})</span>
                                                            <span>&#8377;{getAdditionalUserCost(yearlystandard)}</span>
                                                        </div>
                                                        <div className={`add-user ${handleSlider(yearlystandard) ? "notallowed" : ""}`}>
                                                            <span className='addonBtn' onClick={() => handleDecrement(yearlystandard)}><FaMinus /></span>
                                                            <span className='addonBtn'>{standardUsers}</span>
                                                            <span className='addonBtn' onClick={() => handleIncrement(yearlystandard)}><IoMdAdd /></span>
                                                            <span className='slider-container'>
                                                                <Slider
                                                                    value={standardUsers}
                                                                    min={0}
                                                                    max={100}
                                                                    onChange={(event, newValue) => handleSliderChange(event, newValue, yearlystandard)}
                                                                    // defaultValue={50}
                                                                    aria-label="Default"
                                                                    valueLabelDisplay="auto"
                                                                    disabled={handleSlider(yearlystandard)}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>}
                                                    <div>
                                                        <p className='addon-section'>
                                                            <span className='plus-icon'><IoAddCircleSharp /></span>
                                                            <span className='addon-text'>Add on {isFreeTrial && <span className='free-tails--addon'>(Only with Paid Plans)</span>}</span>
                                                        </p>
                                                        <div className="addon-pack--container">
                                                            {yearlystandard[0].addonpack?.map((item, index) => (
                                                                <div className="addon-pack" key={item.id}>
                                                                    <label>
                                                                        <span className={`custom-checkbox ${isFreeTrial && "nocursor"}`}>
                                                                            <input type="checkbox" id="resume-matcher" name="resume-matcher" onChange={(e) => handleCheckboxChange(e, item.id, item.addonpackname, item.addonprice, selectedPlan && selectedPlan[0].planname)} disabled={isFreeTrial} />
                                                                            <span className="checkmark"></span>
                                                                        </span>
                                                                        {item.addonpackname}
                                                                    </label>
                                                                    <span className="price">
                                                                        &#8377;{item.addonprice}
                                                                    </span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`plan ${selectedPlan[0].planname === yearlypremium[0].planname && "Premium selected"}`} onClick={() => handlePlanSelection(yearlypremium)}>
                                            <img src={limitedofferimage} alt="limited period offer" className='discount-offer' />
                                            <div className='plan-header blue'>
                                                <h2 className='plan-name'>
                                                    <span className='premium-badge'><RiAwardFill /></span>
                                                    <span className='plan-title'>{yearlypremium[0].planname}</span>
                                                </h2>
                                                {
                                                    yearlypremium[0].previousprice != "" &&
                                                    <>
                                                        <span className='plan-prices'>&#8377;{yearlypremium[0].previousprice}</span>
                                                    </>

                                                }
                                                {
                                                    !isFreeTrial ? <h3 className='plan-price'>&#8377;{yearlypremium[0].yearlyprice}</h3> :
                                                        <h3 className='free-trail--plan-price'>7-Days Free Trial</h3>
                                                }


                                            </div>
                                            <div className='plan-body'>
                                                <div className='first-container'>
                                                    <span className='feature-header'>Features:</span>
                                                    <span className='features-title'>(HRM Advanced + KMS+ LMS)</span>
                                                </div>
                                                <div className='second-container'>
                                                    <ul>
                                                        {
                                                            yearlypremium[0].featureslist?.slice(0, 4).map((item, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <span><FaCheckCircle /></span>
                                                                        <span> {item}</span>
                                                                    </li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                    <span className='core-feature'>Everything in Standard +</span>
                                                    <ul>
                                                        {
                                                            yearlypremium[0].featureslist?.slice(4, 7).map((item, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <span><FaCheckCircle /></span>
                                                                        <span> {item}</span>
                                                                    </li>
                                                                );
                                                            })
                                                        }
                                                        {currentViewMore === yearlypremium[0].planname && isViewMore && yearlypremium[0].featureslist?.slice(7).map((item, index) => (
                                                            <li key={index}>
                                                                <span><FaCheckCircle /></span>
                                                                <span> {item}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className='third-container'>
                                                    <span className='plan-viewmore' style={{ marginTop: "0rem" }} onClick={() => handleViewmore(yearlypremium)}>
                                                        {currentViewMore === yearlypremium[0].planname && isViewMore ? 'view less' : 'view more'}
                                                    </span>
                                                </div>
                                                <div className='fourth-container'>
                                                    {!isFreeTrial && <div>
                                                        <div className="addon-pack">
                                                            <span className='additional-user'>Additional Users (per User {yearlypremium[0]?.additionalusercost})</span>
                                                            <span>&#8377;{getAdditionalUserCost(yearlypremium)}</span>
                                                        </div>
                                                        <div className={`add-user ${handleSlider(yearlypremium) ? "notallowed" : ""}`}>
                                                            <span className='addonBtn' onClick={() => handleDecrement(yearlypremium)}><FaMinus /></span>
                                                            <span className='addonBtn'>{premiumUsers}</span>
                                                            <span className='addonBtn' onClick={() => handleIncrement(yearlypremium)}><IoMdAdd /></span>
                                                            <span className='slider-container'>
                                                                <Slider
                                                                    value={premiumUsers}
                                                                    min={0}
                                                                    max={100}
                                                                    onChange={(event, newValue) => handleSliderChange(event, newValue, yearlypremium)}
                                                                    // defaultValue={50}
                                                                    aria-label="Default"
                                                                    valueLabelDisplay="auto"
                                                                    disabled={handleSlider(yearlypremium)}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>}
                                                    <div>
                                                        <p className='addon-section'>
                                                            <span className='plus-icon'><IoAddCircleSharp /></span>
                                                            <span className='addon-text'>Add on {isFreeTrial && <span className='free-tails--addon'>(Only with Paid Plans)</span>}</span>
                                                        </p>
                                                        <div className="addon-pack--container">
                                                            {yearlypremium[0].addonpack?.map((item, index) => (
                                                                <div className="addon-pack" key={item.id}>
                                                                    <label>
                                                                        <span className={`custom-checkbox ${isFreeTrial && "nocursor"}`}>
                                                                            <input type="checkbox" id="resume-matcher" name="resume-matcher" onChange={(e) => handleCheckboxChange(e, item.id, item.addonpackname, item.addonprice, selectedPlan && selectedPlan[0].planname)} disabled={isFreeTrial} />
                                                                            <span className="checkmark"></span>
                                                                        </span>
                                                                        {item.addonpackname}
                                                                    </label>
                                                                    <span className="price">
                                                                        &#8377;{item.addonprice}
                                                                    </span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className={`calculation-section ${theme}`}>
                            <div>
                                <h2 className='calculation-title'>Choose a Payment Plan</h2>
                                {!isFreeTrial ?
                                    <>
                                        {planData?.plansubscription != "Yearly" && <div className={`plan-detail ${subscriptionType === "Monthly" && 'current-plan'}`} onClick={() => handleSubscriptionTypeChange("Monthly")}>
                                            <p className='plan-name'>Monthly</p>
                                            <p className='plan-price'>{selectedPlan && selectedPlan[0].monthlyprice}</p>
                                            <p className='message'>Cancel anytime, no fee.</p>
                                        </div>}

                                       <div className={`plan-detail ${subscriptionType === "Yearly" && 'current-plan'}`} onClick={() => handleSubscriptionTypeChange("Yearly")}>
                                            <p className='plan-name'>Yearly</p>
                                            <p className='plan-price'>{selectedPlan && selectedPlan[0].yearlyprice}</p>
                                            <p className='message'>Fee applies if you Cancel after 14 days.</p>
                                        </div>
                                    </> :
                                    <div className={`plan-detail ${subscriptionType === "Yearly" && 'current-plan'}`}>
                                        <p className='plan-name'>{selectedPlan && selectedPlan[0].planname}</p>
                                        <p className='free-trail--plan-price summary'>7-days Free Trial</p>
                                        <p className='message'>You won't be changed until after your Free Trial ends.</p>
                                    </div>
                                }
                                <h2 className='calculation-title'>{!isFreeTrial && subscriptionType} Payment Summary</h2>
                                <ul className='calculation-container'>
                                    <li className=''>
                                        <span>{selectedPlan[0]?.planname}</span>
                                        <span>&#8377;{getPrice()}</span>
                                    </li>
                                    <li className=''>
                                        <span>Additional User Cost</span>
                                        <span>&#8377;{getAdditionalUserCost(selectedPlan)}</span>
                                    </li>
                                    {!isFreeTrial && selectedPlan && selectedPlan[0].selectedAddons?.map((item, index) => (
                                        <li key={index}>
                                            <span>{item.addonpackname}</span>
                                            <span>&#8377;{item.addonprice}</span>
                                        </li>
                                    ))}
                                </ul>
                                <ul className='calculation-grandtotal--container'>
                                    <li>
                                        <span>Sub Total</span>
                                        <span>&#8377;{
                                            getTotalAmount
                                        }</span>
                                    </li>
                                </ul>

                                <div className='checkout-button--container'>
                                    <div>
                                        <button className={`commonBtn`} onClick={handleContinue}>{loadData ? <Loader /> : !isFreeTrial ? "Continue" : "Start your Free Trial"}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </Box> */}
            {/* </Modal> */}
        </div>
    )
}

export default PlanSummary