import React from 'react'
import loadingAnimation from "../assets/Animation - 1722597592899.gif";

function PageLoading() {
    return (
        <div className='loading-animation'>
            <img src={loadingAnimation} alt="loading animation is not available" />
            <span>Loading...</span>
        </div>
    )
}

export default PageLoading;