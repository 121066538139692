import React, { useState } from "react";
import { FaBook } from "react-icons/fa";
import {
  MdOutlineDashboard,
  MdOutlineExplore,
  MdOutlineOndemandVideo,
} from "react-icons/md";
import { Button, Box, Typography, Tooltip, Toolbar, IconButton, Menu, Avatar, MenuItem } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Cookies from "js-cookie";
import noprofile from "../../../assets/No-image.webp";
import vlivlogo from "../../../assets/Vliv logo.png";

function NavbarBackend() {
    const isProduction = process.env.NODE_ENV === 'production';

  const navigate = useNavigate();
  const location = useLocation();

  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [profileEl, setProfileEl] = React.useState(null);
  const [selectedMainRoute, setSelectedMainRoute] = useState(null);
  const [currentPath, setCurrentPath] = useState("");

  const routes1 = [
    {
      path: "/backend/home/accessmanagement",
      name: "HOME",
      iconComponent: <MdOutlineDashboard />,
      subRoutes: [
        {
          path: "/backend/home/accessmanagement",
          name: "Access Management",
          iconComponent: <MdOutlineDashboard />,
        },
        {
          path: "/backend/home/analytics",
          name: "Analytics",
          iconComponent: <MdOutlineDashboard />,
        },
      ],
    },
    {
      path: "/backend/tickets/unsolved",
      name: "Tickets",
      iconComponent: <MdOutlineDashboard />,
      subRoutes: [
        {
          path: "/backend/tickets/unsolved",
          name: "Unsolved",
          iconComponent: <MdOutlineDashboard />,
        },
        {
          path: "/backend/tickets/solved",
          name: "Solved",
          iconComponent: <MdOutlineDashboard />,
        },
      ],
    },
    {
        path: "/backend/blog",
        name: "Blog",
        iconComponent: <MdOutlineDashboard />,
        subRoutes: [
          {
            path: "/backend/blog",
            name: "Blog Editor",
            iconComponent: <MdOutlineDashboard />,
          },
         
        ],
      },
      {
        path: "/backend/chat",
        name: "Chat",
        iconComponent: <MdOutlineDashboard />,
        // subRoutes: [
        //   {
        //     path: "/backend/Chat",
        //     name: "Chat",
        //     iconComponent: <MdOutlineDashboard />,
        //   },
         
        // ],
      },
  ];

  const navigateFnc = (path) => {
    setCurrentPath(path);
    navigate(path);
  };

  const handleMainRouteClick = (path) => {
    if (selectedMainRoute === path) {
      setSelectedMainRoute(null); // Collapse the current route if clicked again
    } else {
      setSelectedMainRoute(path);
      setCurrentPath(path); // Navigate to the main route
      
    }
    navigate(path)
  };




  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenDetailsMenu = (event) => {
    setProfileEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseDetailsMenu = () => {
    setProfileEl(null);
  };

  const navigateTo = (url) => {
    navigate(url);
  };

  const handleLogout = () => {
    Cookies.remove("companytoken",
      {
        domain: isProduction ? '.vliv.app' : 'localhost',
        path: '/'
      });
    Cookies.remove("userinfo",
      {
        domain: isProduction ? '.vliv.app' : 'localhost',
        path: '/'
      });
    navigate("/signin");
  };

  return (
    <Box>
      <div
        style={{
          backgroundColor: "#F4FAFF",
          // boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
        }}
      >
        <Box minWidth="100%">
          <Toolbar
            disableGutters
            sx={{
              minWidth: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 0, // Remove padding on x-axis
            }}
          >
            <Box sx={{ ml: 1 }}>
              <Typography
                variant="h6"
                noWrap
                component="a"
                sx={{
                  display: { xs: "flex", md: "flex" },
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  textDecoration: "none",
                  // ml: -4.8, // Remove left margin
                }}
              >
                <img
                  src={vlivlogo}
                  alt="vs-logo"
                  width="90"
                  height="25"
                  onClick={() =>
                    // window.location.assign(`${imspage}/frontend/apps`)
                    navigate("/")
                  }
                />
              </Typography>
            </Box>
      
            <Box>
            
              <Box
                sx={{
                  display: {
                    xs: "flex",
                    md: "none",
                    justifyContent: "flex-end",
                  },
                  flexGrow: 1,
                }}
              >
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenDetailsMenu} sx={{ p: 0 }}>
                    <MoreVertIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={profileEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(profileEl)}
                  onClose={handleCloseDetailsMenu}
                >
                 
             
                  <MenuItem>
                    <Button sx={{ color: "black" }}>
                      <Avatar
                        alt="Profile Image"
                        src={
                          // !userData.clientimage
                          //   ? 
                            noprofile
                            // : userData.clientimage
                        }
                      />{" "}
                      &nbsp;
                      <span>
                        {userData.userrole == "masteradmin"
                          ? userData.clientname
                          : userData.username}
                      </span>
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <Button>
                      <LogoutIcon />
                      <span>Logout</span>
                    </Button>
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </Toolbar>
        </Box>
      </div>

      <Box>
        {/* Main Routes */}
        <Box
          className="second-nav"
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            alignItems: "center",
            padding: "8px 16px",
            backgroundColor: "#F1F1F1",
            
          }}
        >
          {routes1.map((route, ind) => (
            <Button
              key={ind}
              startIcon={route.iconComponent}
              sx={{
                color:
                  currentPath.includes(route.path) ||
                  selectedMainRoute === route.path
                    ? "#37668F"
                    : "grey",
                textAlign: "left",
                padding: "8px 16px",
                boxShadow:
                  currentPath.includes(route.path) ||
                  selectedMainRoute === route.path
                    ? "rgba(0, 0, 0, 0.18) 0px 2px 4px"
                    : "",
                backgroundColor:
                  currentPath.includes(route.path) ||
                  selectedMainRoute === route.path
                    ? "#E3F2FD"
                    : "transparent",
                "&:hover": {
                  backgroundColor: "#E0E0E0",
                  
                },
                fontSize:"15px"
              }}
              onClick={() => handleMainRouteClick(route.path)}
            >
              {route.name}
            </Button>
          ))}
        </Box>

        {/* Sub Routes */}
        {selectedMainRoute && (
          <Box
            className="second-nav1 third-nav"
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              alignItems: "center",
              padding: "8px 16px",
              backgroundColor: "#FBFBFB",
              mt: 1,
            }}
          >
         {routes1
  .find((route) => route.path === selectedMainRoute)
  ?.subRoutes?.map((subRoute, ind) => (
    <Tooltip
      key={ind}
      title={subRoute.iconComponent?.props?.title || ""}
    >
      <span>
        <Button
          startIcon={subRoute.iconComponent}
          className={
            currentPath.includes(subRoute.path)
              ? "secondnav-active-btn1"
              : "second-nav-btn1"
          }
          sx={{
            color:
              currentPath.includes(subRoute.path)
                ? "#37668F"
                : "grey",
            textAlign: "left",
            padding: "8px 16px",
            boxShadow: currentPath.includes(subRoute.path)
              ? "rgba(0, 0, 0, 0.18) 0px 2px 4px"
              : "",
            backgroundColor: currentPath.includes(subRoute.path)
              ? "#E3F2FD"
              : "transparent",
            "&:hover": {
              backgroundColor: "#E0E0E0",
            },
            fontSize:"13px"
          }}
          onClick={() => {
            setCurrentPath(subRoute.path); // Add this line to set the subroute as active
            navigate(subRoute.path); // Navigate to the subroute
          }}
        >
          {subRoute.name}
        </Button>
      </span>
    </Tooltip>
  ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default NavbarBackend;
