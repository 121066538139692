const initialState = {

    userData: {},

  };
  
  //
  export const userReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case "GET_USER": {
        return { ...state, userData: { ...payload } };
      }
      
      default: {
        return state;
      }
    }
  };