import React from 'react'
import { useSelector } from 'react-redux';
import EmailLink from '../../../constants/Email';

function TandC() {
  //Redux
  const theme = useSelector((state) => state.themeReducer.theme);
  return (
    <>
      {/* TandC section start */}
      < section className={`section-tandc ${theme == "light" ? "light" : "dark"}`}>
        <div className={`container hero-container ${theme == "dark" && "dark"}`}>
          <h1>Terms and Conditions</h1>

          <p className='common-paragraph'>
            Welcome to {theme == "light" ? <strong className='strong_tag_color'>Vliv</strong> : <b>Vliv</b>}, a SaaS-based web application product powered by
            {theme == "light" ? <strong className='strong_tag_color'> VentureSathi Business Services LLP </strong> : <b>VentureSathi Business Services LLP </b>}("Venturesathi," "we," "us," or "our"). These Terms and Conditions ("Terms") govern your access to and use of Vliv, including our website located at vliv.app ("Website") and any related services (collectively, the "Services").
          </p>

          <p className='common-paragraph bold'>Please read these Terms carefully before using the Services. By accessing or using the Services, you agree to be bound by these Terms. If you disagree with any part of the Terms, then you may not access or use the Services.</p>

          <ul className='common-list'>
            <li>
              <h2 className='common-defination'>1. Definitions</h2>
              <ul className='my-2'>
                <li>
                  "Content" means any information, data, text, software, music, sound, photographs, graphics,
                  videos, messages or other materials uploaded, downloaded or appearing on the Services.
                </li>
                <li>
                  "User," "you," or "your" means any individual or entity accessing or using the Services.
                </li>
                <li>"Your Content" means Content that you upload, submit, post, transmit or otherwise make
                  available through the Services. </li>
              </ul>
            </li>
            <li>
              <h2 className='common-defination'>2. Eligibility</h2>
              <ul className='my-2'>
                <li>
                  You must be at least 18 years of age or the age of majority in your jurisdiction, whichever is older, to use
                  the Services. By using the Services, you represent and warrant that you meet the foregoing eligibility
                  requirements. If you are using the Services on behalf of an entity, you represent and warrant that you
                  have the authority to bind that entity to these Terms.
                </li>
              </ul>
            </li>
            <li>
              <h2 className='common-defination'>3.  Account Registration</h2>
              <p className='common-paragraph my-2'> You may be required to create an account to access and use certain features of the Services. If you create
                an account, you agree to: </p>
              <ul>
                <li className='alphabet-list'>provide accurate, current, and complete information.</li>
                <li className='alphabet-list'>maintain and promptly update your account information.</li>
                <li className='alphabet-list'>maintain the security of your account.</li>
                <li className='alphabet-list'>
                  be responsible for all activity that occurs under your account. Yoare solely responsible for maintaining the confidentiality of your account and password and for restricting access to your computer.
                </li>
              </ul>
            </li>
            <li>
              <h2 className='common-defination'>4. User Content</h2>

              <p className='common-paragraph my-2'> You retain all rights in and to Your Content. You are solely responsible for the development, content, operation, maintenance, and use of Your Content, and for any consequences thereof. </p>

              <p className='common-paragraph my-2'>By submitting Your Content through the Services, you grant Venturesathi a non-exclusive, worldwide, royalty-free, sublicensable and transferable license to use, reproduce, modify, adapt, publish, translate, distribute, publicly perform and display Your Content in connection with operating and providing theServices. </p>

              <p className='common-paragraph my-2'>
                You represent and warrant that you own all rights in and to Your Content or have obtained all necessary
                rights and permissions to grant the rights granted herein. You further represent and warrant that Your
                Content and our use thereof will not infringe or violate the rights of any third party.
              </p>
            </li>
            <li>
              <h2 className='common-defination'>5. Prohibited Activities </h2>
              <p className='common-paragraph my-2'>You agree not to engage in any of the following prohibited activities:</p>
              <ul>
                <li>Violating any local, state, national or international law or regulation.
                </li>
                <li>
                  Impersonating any person or entity or falsely stating or otherwise misrepresenting your
                  affiliation with a person or entity.
                </li>
                <li>Interfering with or disrupting the Services or servers or networks connected to the Services.
                </li>
                <li>Attempting to gain unauthorized access to the Services, other user accounts, or computer
                  systems or networks connected to the Services.
                </li>
                <li>Uploading, transmitting, or otherwise making available any Content that is unlawful, harmful,
                  threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of
                  another's privacy, hateful, or racially, ethnically or otherwise objectionable.
                </li>
                <li>Engaging in any other conduct that restricts or inhibits anyone's use or enjoyment of the
                  Services, or which, as determined by us, may harm Venturesathi or users of the Services or
                  expose them to liability.
                </li>
              </ul>
            </li>
            <li>
              <h2 className='common-defination'>6. Termination</h2>
              <p className='common-paragraph my-2'>We may terminate your access to the Services, in whole or in part, without notice, for any reason or no
                reason, including but not limited to your violation of these Terms. You may terminate your account at
                any time. Upon termination of your account, your right to use the Services will immediately cease.</p>
            </li>
            <li>
              <h2 className='common-defination'>7. Disclaimer of Warranties</h2>
              <p className='common-paragraph my-2'>The Services are provided "as is" and "as available" without warranty of any kind, express or implied,
                including but not limited to the implied warranties of merchantability, fitness for a particular purpose,
                title, and non-infringement. Venturesathi does not warrant that the Services will be uninterrupted or
                error-free, that defects will be corrected, or that the Services are free of viruses or other harmful
                components. </p>
            </li>
            <li>
              <h2 className='common-defination'>8. Limitation of Liability </h2>
              <p className='common-paragraph my-2'>In no event shall Venturesathi, its officers, directors, employees, agents, or licensors be liable for any
                direct, indirect, incidental, special, consequential, or exemplary damages (including, without limitation,
                damages for loss of profits, goodwill, use, data, or other intangible losses), arising out of or in
                connection with your use of or inability to use the Services, even if Venturesathi has been advised of the
                possibility of such damages. </p>
            </li>
            <li>
              <h2 className='common-defination'>9. Indemnification</h2>
              <p className='common-paragraph my-2'>
                You agree to defend, indemnify, and hold harmless Venturesathi, its officers, directors, employees,
                agents, and licensors from and against any and all claims, liabilities, damages, losses, costs, and
                expenses (including, without limitation, attorneys' fees) arising out of or relating to your use of the
                Services, your violation of these Terms, or your violation of any third-party rights.
              </p>
            </li>
            <li>
              <h2 className='common-defination'>10. Governing Law and Dispute Resolution </h2>
              <p className='common-paragraph my-2'>
                These Terms and your use of the Services will be governed by and construed in accordance with the
                laws of India without regard to its conflict of law provisions Any dispute arising out of or relating to
                these Terms or your use of the Services will be submitted to the exclusive jurisdiction of the courts
                located in Rourkela, Odisha, India.
              </p>
            </li>
            <li>
              <h2 className='common-defination'>11. Entire Agreement </h2>
              <p className='common-paragraph my-2'>These Terms constitute the entire agreement between you and Venturesathi relating to your access to
                and use of the Services and supersede all prior or contemporaneous communications and proposals
                whether oral or written between you and Venturesathi.</p>
            </li>
            <li>
              <h2 className='common-defination'>12. Waiver and Severability </h2>
              <p className='common-paragraph my-2'>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those
                rights or provisions. If any provision of these Terms is held to be invalid or unenforceable by a court of
                competent jurisdiction, the remaining provisions of these Terms will remain in full force and effect. </p>
            </li>
            <li>
              <h2 className='common-defination'>13. Notices </h2>
              <p className='common-paragraph my-2'>All notices and other communications hereunder shall be in writing and shall be deemed to have been
                duly given when delivered personally upon the first business day following deposit in the mail postage
                prepaid certified or registered return receipt requested addressed as follows</p>
              <p className='common-paragraph my-2'>If to Venturesathi Business Services LLP:</p>
              <p className='common-paragraph my-2'>Venturesathi Business Services LLP, #220, Forum Galleria Mall, Civil Township, Rourkela-769004, Odisha,
                India </p>
              <p className='common-paragraph my-2'>If to you: </p>
              <p className='common-paragraph my-2'>
                At the email address you provided to us.
              </p>
            </li>
            <li>
              <h2 className='common-defination'>14. Contact Us</h2>
              <p className='common-paragraph my-2'>If you have any questions about these Terms, please contact us at: <EmailLink email="info@vliv.app" /></p>
            </li>
          </ul>
        </div>
      </ section >
      {/* TandC section end */}
    </>

  )
}

export default TandC