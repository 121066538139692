import React from 'react'
import { useSelector } from 'react-redux';
import EmailLink from '../../../constants/Email';

function CancellationAndRefund() {
  //Redux
  const theme = useSelector((state) => state.themeReducer.theme);
  return (
    <>
      {/* TandC section start */}
      < section className={`section-cancellation ${theme == "light" ? "light" : "dark"}`}>
        <div className={`container hero-container ${theme == "dark" && "dark"}`}>
          <h1>Cancellation and Refund Policy</h1>

          <p className='common-paragraph text-start'>
            This Cancellation and Refund Policy explains how you can cancel your subscription to Vliv
            and whether you qualify for any refunds.
          </p>

          <ul className='common-list'>
            <li>
              <h2 className='common-defination'>1. Subscription Cancellation</h2>
              <p className='common-paragraph my-2'>You may cancel your subscription to Vliv at any time. You can cancel your subscription by:</p>
              <ul className='my-2'>
                <li>Logging into your account settings and following the cancellation procedures.</li>
                <li>Contacting our customer support team. </li>
              </ul>
              <h2 className='common-defination'>Please note:</h2>
              <ul className='my-2'>
                <li>No refunds shall be provided for any subscription package. </li>
                <li>We do not provide refunds or any prorated billing for any subscriptions that are
                  canceled mid-term or for any reason whatsoever. </li>
                <li>Upon cancellation, your access to the Service will continue until the end of your
                  current billing cycle. You will not be charged for any subsequent billing cycles.</li>
              </ul>
            </li>
            <li>
              <h2 className='common-defination'>2. Free Trials</h2>
              <ul className='my-2'>
                <li>A free trial of 7 days will be applicable for new users.</li>
                <li>Upon expiry of the free trial period, you will be required to subscribe to a paid plan
                  to maintain continued access to the Vliv platform. </li>
                <li>Failure to subscribe to a paid plan will result in the restriction of your current usage
                  and functionality </li>
              </ul>
            </li>
            <li>
              <h2 className='common-defination'>3. Account Termination</h2>
              <p className='common-paragraph my-2'>We reserve the right to terminate your account for violations of our Terms of Service. No
                refunds will be issued for accounts terminated due to violations of our Terms of Service. </p>

            </li>
            <li>
              <h2 className='common-defination'>4. Subscription Changes </h2>

              <p className='common-paragraph my-2'>Subscription charges are subject to change with prior notice. You can downgrade or
                upgrade your subscription plan from your account dashboard. However, any changes to
                your subscription plan will take effect at the end of your current billing cycle.
              </p>
            </li>
            <li>
              <h2 className='common-defination'>5. Circumstances Outside of this Policy</h2>
              <p className='common-paragraph my-2'>While we generally do not offer refunds, we may make exceptions in rare circumstances at
                our sole discretion, such as: </p>
              <ul>
                <li>Technical Issues: If Vliv experiences significant technical issues that prevent you from
                  accessing the Service for an extended period and we are unable to resolve the issue
                  within a reasonable timeframe, we may offer a partial or full refund.
                </li>
                <li>
                  Billing Errors: If we make a mistake in billing you, we will, of course, correct the error
                  and issue a refund for any overpayment.
                </li>
              </ul>
              <p className='common-paragraph my-2'>In such exceptional scenario the refund shall be processed in 5 to 7 business working days.</p>
            </li>
            <li>
              <h2 className='common-defination'>6. Contact Us</h2>
              <p className='common-paragraph my-2'>If you have any questions about our Cancellation and Refund Policy, please contact us at: <EmailLink email="info@vliv.app" /></p>
            </li>
          </ul>
        </div>
      </ section >
      {/* TandC section end */}
    </>
  )
}

export default CancellationAndRefund