import Cookies from "js-cookie";
import { PLANS } from "../actionTypes";

const storedToken = Cookies.get('companytoken');
const storedUserData = Cookies.get('userinfo');
// Initial state
const initialState = {
    planData: ""
};

// Reducer function
const planReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case PLANS:
            return {
                ...state,
                planData: payload
            };

        default:
            return state;
    }
};

export default planReducer;