import HRMSIMG1 from "../../assets/HRMSIMG1.webp";
import HRMSIMG2 from "../../assets/HRMSIMG2.webp";
import HRMSIMG3 from "../../assets/HRMSIMG3.webp";
import HRMSIMG4 from "../../assets/HRMSIMG4.webp";
import HRMSIMG5 from "../../assets/HRMSIMG5.webp";
import HRMSIMG6 from "../../assets/HRMSIMG6.webp";
import HRMSIMG7 from "../../assets/HRMSIMG7.webp";
import HRMSIMG8 from "../../assets/HRMSIMG8.webp";
import KMSIMG1 from "../../assets/KMSIMG1.webp";
import KMSIMG2 from "../../assets/KMSIMG2.webp";
import KMSIMG3 from "../../assets/KMSIMG3.webp";
import KMSIMG4 from "../../assets/KMSIMG4.webp";
import KMSIMG5 from "../../assets/KMSIMG5.webp";
import KMSIMG6 from "../../assets/KMSIMG6.webp";
import LMSIMG1 from "../../assets/LMSIMG1.webp";
import LMSIMG2 from "../../assets/LMSIMG2.webp";
import LMSIMG3 from "../../assets/LMSIMG3.webp";
import LMSIMG4 from "../../assets/LMSIMG4.webp";
import LMSIMG5 from "../../assets/LMSIMG5.webp";
import LMSIMG6 from "../../assets/LMSIMG6.webp";


export const HRMSDATA = [
    {
        id: 1,
        panel: 'pannel1',
        title: 'Recruitment Management',
        desc: 'AI enable resume scanning, Enhanced recruitment process, Hassle free onboarding. ',
        images: HRMSIMG1
    },
    {
        id: 2,
        panel: 'pannel2',
        title: 'Employee Management',
        desc: 'Interactive & User friendly way to managing employee’s data',
        images: HRMSIMG2
    },
    {
        id: 3,
        panel: 'pannel3',
        title: 'Payroll Management',
        desc: 'Optimized payroll calculation, Pay slip generation with custom salary structure.',
        images: HRMSIMG3
    },
    {
        id: 4,
        panel: 'pannel4',
        title: 'Employee Self-service',
        desc: 'AI enabled employee self-service portal to empower the employee regarding their documents, Help desk, Leave’s, etc.',
        images: HRMSIMG4
    },
    {
        id: 5,
        panel: 'pannel5',
        title: 'Performance Management',
        desc: 'Enhanced & user friendly portal to measure the success rate and employee’s performance through optimized KPI’s.',
        images: HRMSIMG5
    },
    {
        id: 6,
        panel: 'pannel6',
        title: 'Compliance',
        desc: 'One place for all the policy document, Regulation, etc.',
        images: HRMSIMG6
    },
    {
        id: 7,
        panel: 'pannel7',
        title: 'MIS (Management Information System)',
        desc: 'Insightful, Interactive, KPI’s information system for the stakeholders.',
        images: HRMSIMG7
    },
    {
        id: 8,
        panel: 'pannel8',
        title: 'Master Data',
        desc: 'Custom configuration to be included in the system like, salary components, organization logo, recruitment Process, etc.',
        images: HRMSIMG8
    },
];

export const KMSDATA = [
    {
        id: 1,
        panel: 'pannel1',
        title: 'User Management',
        desc: 'Role based access management, user management etc',
        images: KMSIMG1
    },
    {
        id: 2,
        panel: 'pannel2',
        title: 'Knowledge Creation',
        desc: 'AI enabled Knowledge generation application reducing course creation effort & time by generating need content',
        images: KMSIMG2
    },
    {
        id: 3,
        panel: 'pannel3',
        title: 'Document Repository',
        desc: 'AI powered quiz creator reducing time & efforts, and auto evaluation of quizzer.',
        images: KMSIMG3
    },
    {
        id: 4,
        panel: 'pannel4',
        title: 'Compliance',
        desc: 'Policy, regulations, instruction etc',
        images: KMSIMG4
    },
    {
        id: 5,
        panel: 'pannel5',
        title: 'MIS (Management Information System)',
        desc: 'Tailored MIS Reports and admin reports',
        images: KMSIMG5
    },
    {
        id: 6,
        panel: 'pannel6',
        title: 'Help Desk',
        desc: 'Intelligent Chatbot for resolving queries powered by highly trained Artificial Intelligence models.',
        images: KMSIMG6
    }
];

export const LMSDATA = [
    {
        id: 1,
        panel: 'pannel1',
        title: 'User Management',
        desc: 'Role based access management, user management etc',
        images: LMSIMG1
    },
    {
        id: 2,
        panel: 'pannel2',
        title: 'Content Creation',
        desc: 'AI enabled content generation application reducing course creation effort & time by generating need content',
        images: LMSIMG2
    },
    {
        id: 3,
        panel: 'pannel3',
        title: 'Quiz & Assessment',
        desc: 'AI powered quiz creator reducing time & efforts, and auto evaluation of quizzer.',
        images: LMSIMG3
    },
    {
        id: 4,
        panel: 'pannel4',
        title: 'Compliance',
        desc: 'Policy, regulations, instruction etc',
        images: LMSIMG4
    },
    {
        id: 5,
        panel: 'pannel5',
        title: 'MIS (Management Information System)',
        desc: 'Tailored MIS Reports and admin reports',
        images: LMSIMG5
    },
    {
        id: 6,
        panel: 'pannel6',
        title: 'Help Desk',
        desc: 'Intelligent Chatbot for resolving queries powered by highly trained Artificial Intelligence models.',
        images: LMSIMG6
    }
];