import {
  Autocomplete,
  Box,
  Container,
  Divider,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BiSolidFileExport } from "react-icons/bi";
import { DataGrid } from "@mui/x-data-grid";
import { IoIosSend } from "react-icons/io";
import axios from "axios";
import BreadCrumb from "../../../constants/BreadCrumb";
import { adminAllCompanylist } from "../../../constants/api";

const Billings = () => {
  const pdfRef = useRef(null);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const columns = [
    {
      field: "company",
      headerName: "Company Name",
      headerClassName: "custom-header",
      flex: 1,
    },
    {
      field: "module",
      headerName: "Module",
      headerClassName: "custom-header",
      cellClassName: "module-cell",
      flex: 1,
      renderCell: (params) => {
        let result = [];
        if (Array.isArray(params.value) && params.value.length > 0) {
          // Check if 'basickms' is present in the array
          if (params.value.includes("basickms")) {
            result.push(<Typography color="green">KMS</Typography>);
          }
          if (params.value.includes("standardkms")) {
            result.push(<Typography color="skyblue">KMS</Typography>);
          }
          if (params.value.includes("premiumkms")) {
            result.push(<Typography color="orange">KMS</Typography>);
          }
          if (params.value.includes("basichrms")) {
            result.push(<Typography color="green">HRMS</Typography>);
          }
          if (params.value.includes("standardhrms")) {
            result.push(<Typography color="skyblue">HRMS</Typography>);
          }
          if (params.value.includes("premiumhrms")) {
            result.push(<Typography color="orange">HRMS</Typography>);
          }
          if (params.value.includes("basicpms")) {
            result.push(<Typography color="green">PMS</Typography>);
          }
          if (params.value.includes("standardpms")) {
            result.push(<Typography color="skyblue">PMS</Typography>);
          }
          if (params.value.includes("premiumpms")) {
            result.push(<Typography color="orange">PMS</Typography>);
          }
          if (params.value.includes("basicitams")) {
            result.push(<Typography color="green">ITAMS</Typography>);
          }
          if (params.value.includes("standarditams")) {
            result.push(<Typography color="skyblue">ITAMS</Typography>);
          }
          if (params.value.includes("premiumitams")) {
            result.push(<Typography color="orange">ITAMS</Typography>);
          }
        }
        return result;
      },
    },
    {
      field: "frequency",
      headerName: "Frequency",
      headerClassName: "custom-header",
      flex: 1,
    },
    {
      field: "moduleduedays",
      headerName: "Module Due Days",
      headerClassName: "custom-header",
      cellClassName: "module-cell",
      flex: 1,
      renderCell: (params) => {
        const moduleDueDays = params.value;
        if (Array.isArray(moduleDueDays) && moduleDueDays.length > 0) {
          let arr = [];
          const moduleAndDaysList = moduleDueDays.map((moduleDueDay, index) => {
            const [moduleName, days] = moduleDueDay.split("-");

            const isValid =
              (params.row.frequency === "Annual" && parseInt(days) <= 30) ||
              (params.row.frequency === "Monthly" && parseInt(days) <= 7);
            arr.push(
              isValid && (
                <Typography key={index}>
                  {(moduleName.includes("kms") && "KMS") ||
                    (moduleName.includes("hrms") && "HRMS") ||
                    (moduleName.includes("pms") && "PMS") ||
                    (moduleName.includes("itams") && "ITAMS")}
                  - {days}
                </Typography>
              )
            );
          });
          return arr;
        }
      },
    },
    {
      field: "renewalamount",
      headerName: "Renewal Amount",
      headerClassName: "custom-header",
      flex: 1,
    },
    {
      field: "mail",
      headerName: "Mail",
      headerClassName: "custom-header",
      flex: 1,
      renderCell: (params) => {
        const moduleDueDays = params.row.moduleduedays;
        if (Array.isArray(moduleDueDays) && moduleDueDays.length > 0) {
          let shouldMail = false;
          const moduleAndDaysList = moduleDueDays.map((moduleDueDay, index) => {
            const [moduleName, days] = moduleDueDay.split("-");

            const isValid =
              (params.row.frequency === "Annual" && parseInt(days) <= 30) ||
              (params.row.frequency === "Monthly" && parseInt(days) <= 7);
            if (isValid) {
              shouldMail = isValid;
            }
          });
          return (
            shouldMail && (
              <IconButton
                aria-label="edit"
                onClick={(event) => {
                  event.stopPropagation();
                  handleSendMail(params);
                }}
              >
                <IoIosSend style={{ color: "red" }} />
              </IconButton>
            )
          );
        } else {
          return null; // Render nothing if moduleduedays is empty or undefined
        }
      },
    },
  ];

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleSendMail = (params) => {
    const { company, frequency, moduleduedays, clientemail, renewalamount } =
      params.row;

    let arr = "";
    const moduleAndDaysList = moduleduedays.map((moduleDueDay, index) => {
      const [moduleName, days] = moduleDueDay.split("-");
      const isValid =
        (params.row.frequency === "Annual" && parseInt(days) <= 30) ||
        (params.row.frequency === "Monthly" && parseInt(days) <= 7);
      if (isValid) {
        arr += `${
          moduleName.includes("basickms")
            ? "KMS(Basic)"
            : moduleName.includes("standardkms")
            ? "KMS(Standard)"
            : moduleName.includes("premiumkms")
            ? "KMS(Premium)"
            : moduleName.includes("basichrms")
            ? "HRMS(Basic)"
            : moduleName.includes("standardhrms")
            ? "HRMS(Standard)"
            : moduleName.includes("premiumhrms")
            ? "HRMS(Premium)"
            : moduleName.includes("basicpms")
            ? "PMS(Basic)"
            : moduleName.includes("standardpms")
            ? "PMS(Standard)"
            : moduleName.includes("premiumpms")
            ? "PMS(Premium)"
            : moduleName.includes("basicitams")
            ? "ITAMS(Basic)"
            : moduleName.includes("standarditams")
            ? "ITAMS(Standard)"
            : moduleName.includes("premiumitams")
            ? "ITAMS(Premium)"
            : ""
        } - For ${days} Days\n`;
      }
    });

    const body = encodeURIComponent(
      `Dear ${company},\n\n` +
        `We would like to inform you regarding your subscription details:\n` +
        `Plan: ${frequency}\n` +
        // `Module Due Days: ${moduleduedays.join(", ")}\n` +
        `Due Days: ${arr}\n` +
        `Renewal Amount: ${renewalamount}\n\n` +
        `Please review the information above and let us know if you have any questions.\n\n` +
        `Best regards,\nVenturesathi`
    );
    const subject = encodeURIComponent("Regarding Your Subscription"); // Encode subject string
    const mailTo = `mailto:${clientemail}?subject=${subject}&body=${body}`;
    window.open(mailTo);
  };

  //all company
  useEffect(() => {
    axios
      .post(adminAllCompanylist, {}, {withCredentials:true})
      .then((res) => {
        const initialData =
          res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [];
        setData(initialData);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!searchValue) {
      // If searchValue is empty, display all original data
      setFilteredData(data);
    } else {
      // Filter the original data based on searchValue
      const filtered = data.filter((company) =>
        company.company.toLowerCase().includes(searchValue.toLowerCase().trim())
      );
      setFilteredData(filtered);
    }
  }, [searchValue, data]);

  return (
    <>
      <Container maxWidth="xl">
        <BreadCrumb />
        <Divider />
        <br />
      </Container>
      <Container maxWidth="xl">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            variant="h4"
            color={"#062C41"}
            fontWeight={"bold"}
            gutterBottom
          >
            Billings
          </Typography>
          <Box display="flex" alignItems="center" gap={2}>
            <Autocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              options={data.map((option) => option.company)}
              onChange={(event, newValue) => setSearchValue(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  value={searchValue}
                  onChange={(event, newValue) =>
                    setSearchValue(event.target.value)
                  }
                  sx={{ width: 700 }}
                />
              )}
            />
            <DownloadTableExcel
              filename="Biilings"
              sheet="Biilings"
              currentTableRef={pdfRef.current}
            >
              <BiSolidFileExport
                fontSize={"30px"}
                cursor={"pointer"}
                color="#062C41"
              />
            </DownloadTableExcel>
          </Box>
        </Box>
        <br />
        <DataGrid
          onRowClick={handleRowClick}
          ref={pdfRef}
          rows={filteredData.length > 0 && filteredData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 8 },
            },
          }}
          pageSizeOptions={[8, 16, 32]}
        />
      </Container>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm">
          <Box sx={{ mt: 4, p: 1, bgcolor: "background.paper" }}>
            <Typography
              variant="h6"
              color={"#062C41"}
              fontWeight={600}
              gutterBottom
            >
              Subscription History
            </Typography>
            <Divider />
            <Typography variant="subtitle1" color={"#062C41"} fontWeight={600}>
              {selectedRow?.company}
            </Typography>
            <Divider />
            <br />
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead
                  sx={{
                    bgcolor: "#062C41",
                    "& .MuiTableCell-root": { color: "white" },
                  }}
                >
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell align="right">Module</TableCell>
                    <TableCell align="right">Subscription</TableCell>
                    <TableCell align="right">Start Date</TableCell>
                    <TableCell align="right">Due Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody></TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
      </Modal>
    </>
  );
};

export default Billings;
