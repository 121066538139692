export const faqData = [
  {
    "question": "Is there a free trial available?",
    "answer": {
      desc: "Absolutely! We understand that trying out a new platform can be a big decision, which is why we offer a 7-day free trial for all our plans. This trial period gives you full access to explore Vliv's powerful features and see first-hand how it can streamline your workforce management and learning & development processes. Experience the convenience and efficiency of our platform without any commitment or risk."
    }
  },
  {
    "question": "Are there additional savings for Annual plans?",
    "answer": {
      desc: "Definitely! When you commit to an annual plan, we reward your long-term investment with substantial savings. By opting for an annual subscription, you can enjoy up to 30% off compared to monthly billing. This significant discount is our way of showing appreciation for your dedication to your organizational goals and your trust in Vliv's capabilities. Unlock even more value and maximize your return on investment by choosing an annual plan."
    }
  },
  {
    "question": "Who is Vliv for?",
    "answer": {
      desc: "Vliv is designed for businesses of all sizes, from startups to enterprises, across various industries. Our scalable and adaptable platform grows with your business, providing the tools and features you need to optimize workforce management and productivity.",
      example: "As Vliv continues to evolve, you'll gain access to cutting-edge modules and functionalities that will empower you to optimize your business processes and achieve unprecedented success."
    }
  },
  {
    "question": "What does the Basic plan offer and how much does it cost?",
    "answer": {
      desc: "The Basic plan costs INR 50 per user* and includes up to 30 users. If your team expands, you can add more users at INR 30 per additional user. ",
      example: "If you have a team of 35, your cost would be: (30 users * INR 50) + (5 additional users * INR 30) = INR 1650 per month."
    }
  },
  {
    "question": "What are the benefits and costs of the Standard plan?",
    "answer": {
      desc: "Our Standard plan is designed for larger teams, including up to 100 users at INR 70 per user*. Additional users can be added at INR 50 each.",
      example: "For a team of 110, the cost is: (100 users * INR 70) + (10 additional users * INR 50) = INR 7500 per month."
    }
  },
  {
    "question": "Can you explain the Premium plan's pricing and features?",
    "answer": {
      desc: "The Premium plan supports up to 250 users at INR 150 per user*, ideal for extensive teams. Additional users are INR 100 each, perfect for scaling businesses.",
      example: "If your organization grows to 260, it costs: (250 users * INR 150) + (10 additional users * INR 100) = INR 38500 per month."
    }
  },
  {
    "question": "How can I determine which pricing plan is best for my needs?",
    "answer": {
      desc: "Choose the plan based on the feature you need, your current team size and anticipated growth. Consider the Basic or Standard for startups and smaller teams, and the Premium for larger organizations needing extensive support and users."
    }
  },
  {
    "question": "Is it possible to upgrade or downgrade between plans?",
    "answer": {
      desc: "Yes, you can change your plan anytime based on your evolving business needs. To upgrade, use the dashboard which shows your current plan. To Downgrade, contact our support team for an easy transition. Note: Billing changes will be effective after completion of the current billing cycle."
    }
  },
  {
    "question": "What happens if I exceed the included number of users in my plan?",
    "answer": {
      desc: "When sending invite to add new users, you’ll have an option to upgrade into the next tier plan or add additional user in the current tier at a nominal additional cost."
    }
  },
  {
    "question": "What are Add-On Features and Why it’s Needed?",
    "answer": {
      desc: "Add-Ons are the Special Features specially crafted according to need of the customer. for each tier we have designed add-ons which will reduce your time and efforts in Overall Operations of HRMS And Your L&D Team. Our Add on are powered latest cutting-edge technologies like large language models."
    }
  },
  {
    "question": "Can I Subscribe for any add-on?",
    "answer": {
      desc: "No, you can subscribe the add-on tier wise only. if you are Subscribing basic plan, you can select the add-on on from Basic plan’s add-on bucket only. All The Add-ons cost is in either Per User or Per Day Basis (Billed Monthly). You can explore the available add on in Add-on Section of the plan."
    }
  },
  {
    "question": "What is add-on cost per day?",
    "answer": {
      desc: "Some add-ons are on per day basis. It will be charged per day, billed monthly.",
      example: "Resume Matcher Add-on is on per/day/resume. You can match maximum of 5 resume in a day. If you subscribe for the resume matcher it will be charged as 10/per/day, billed monthly."
    }
  },
  {
    "question": "What is add-on cost per user?",
    "answer": {
      desc: "Some add-ons are on per user basis. It will be charged per day, billed monthly.",
      example: "Employee Self Service Chatbot Add-on is per/user/month. It will be charged as 5/per user/per month. For basic plan (30 Users * 5 = 150 for a month)"
    }
  },
  {
    "question": "Can I cancel the add-on subscription?",
    "answer": {
      desc: "Yes, you can cancel the subscription anytime. But you will be charged in current billing cycle.",
      example: "You have subscribed for an add-on on 1st January 2024 and cancelled the subscription on 8th January 2024, then you will be charged for the January month add-on subscription charge."
    }
  }
]