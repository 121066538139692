import React, { useState } from 'react'
import { HRMSDATA } from "../FeatureDummyData";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function HRMSTab({theme, header, desc}) {

    //state
    const [expanded, setExpanded] = useState('pannel1');

    //function
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <h1 className={`feature-heading ${theme == "light" ? "light" : "dark"}`}>{header}</h1>
            <h3 className='common-subheading' >{desc}</h3>
            <div className='grid grid-two--cols feature-container'>
                <div className='feature-Images' >
                    {HRMSDATA.map((itemData, index) => (
                        expanded === itemData.panel && (
                            <React.Fragment key={index}>
                                <img src={itemData.images} alt="HRMS Image not available" data-aos="fade-up"/>
                            </React.Fragment>
                        )
                    ))}
                </div>
                <div className='accordianDesc'>
                    <div className={`accordian-container ${theme == "light" ? "light" : "dark"}`}>
                        {
                            HRMSDATA.map((item, index) => {
                                return (
                                    <Accordion key={item.id} expanded={expanded === item.panel} onChange={handleChange(item.panel)} className={`accordianPannel ${theme == "light" ? "light" : "dark"}`}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id={`${item.panel}-header`}

                                        >
                                            <Typography className='pannel-title'>{item.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography className='pannel-desc'>
                                                <Typography className='panel-desc'>
                                                    <span className='desc'>{item.desc}</span>
                                                </Typography>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                );

                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default HRMSTab