import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { cardData } from "./BlogData";
import axios from "axios";
import { formattedDate } from "../../../constants/formatedDate";
// sahid
const BlogDetails = () => {
  const { id } = useParams();

  //Redux
  const theme = useSelector((state) => state.themeReducer.theme);
  // const blog = cardData.find(blog => blog.id === parseInt(id));
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    axios
      .post(`https://vliv.app/blog/getbyid`, { id })
      .then((res) => {
        setBlog(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  if (!blog) {
    return <div>Blog not found</div>;
  }

  return (
    <>
      {/* Blogs details section start */}
      <section
        className={`section-blogdetails ${theme == "light" ? "light" : "dark"}`}
      >
        <div className={`container ${theme == "dark" && "dark"}`}>
          <div className="blog-details--container">
            <h1 className="blog-title">{blog.cardtitle}</h1>
            <p className="blog-posted--user">
              {/* <span>By:{blog.username}</span> */}
              <span>Posted on:{formattedDate(blog.updatedAt)}</span>
            </p>
            <img src={blog.cardimage} alt="Blog image not available" />
            {
              blog.description.map((item, index) =>
                item.type === "text" ? (
                  <div key={index}>
                    <p className="desc" dangerouslySetInnerHTML={{ __html: item.content }} />
                  </div>
                ) : (
                  // <div key={`image-${blogIndex}-${descIndex}`}>
                  <img
                    src={item.content}
                    // alt={`Description ${descIndex + 1}`}
                    style={{ maxWidth: "100%", borderRadius: "10px" }}
                    width="300"
                    height="150"
                  />
                  // </div>
                )

              )
            }

          </div>
        </div>
      </section>
      {/* Blogs details section end */}
    </>
  );
};

export default BlogDetails;
