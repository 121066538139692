import { Accordion, AccordionDetails, AccordionSummary, Fade, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CustomTabPanel, { a11yProps } from '../../CustomTabPanel/CustomTabPanel';
import BusinessTransformSection from '../BusinessTransformSection';
import EarlyBirdAcess from "../../../assets/Early-bird-access.webp";
import PlanDetails from './PlansDetails';
import earlybird from "../../../assets/earlybird.webp";
import rightearlybird from "../../../assets/Right-bird.webp";
import { basic } from './PlanData';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { faqData } from './FaqData';
import FreeTrial from './FreeTrial';
import { reloadPage } from '../../../redux/actions/load.action';

function Plans({ }) {
  const navigate = useNavigate();

  //Redux
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.themeReducer.theme);
  const loadData = useSelector((state) => state.loadReducer);
  const isAuthenticated = useSelector((state) => state.loginReducer.token);

  //state
  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleExpland = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };



  const handleFreeTrial = (plan) => {
    dispatch(reloadPage(true));
    if (isAuthenticated) {
      setTimeout(() => {
        dispatch(reloadPage(false));
        navigate('/frontend/plan-summary', { state: { selectedPlan: plan, freeTrial: true} });
      }, 1000)

    }
    else {
      setTimeout(() => {
        dispatch(reloadPage(false));
        navigate('/frontend/login', { state: { from: '/plans', intendedPath: '/frontend/plan-summary', selectedPlan: plan, freeTrial: true} });
      }, 1000)

    }

    // 

  }

  return (
    <>
      <section className={`section-plans ${theme == "light" ? "light" : "dark"}`}>
        <div className={`container plans-container ${theme == "light" ? "light" : "dark"}`}
          style={{
            ...(theme === "dark" && { color: "var(--header-link-color)" })
          }}
        >
          <div className='plans-page'>
            <img src={earlybird} alt="Early bird image not available" className='earlybirdImage' data-aos="fade-up" />
            <div className='tabs-section'>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='tabs-section'>
                <Tab label="Monthly" {...a11yProps(0)} className='tab-button' />
                <Tab label="Yearly" {...a11yProps(1)} className='tab-button' />
              </Tabs>
              <CustomTabPanel value={value} index={0} >
                <PlanDetails
                  currentplan="Monthly"
                >
                </PlanDetails>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <PlanDetails
                  currentplan="Yearly"
                >
                </PlanDetails>
              </CustomTabPanel>
            </div>

          </div>
          <FreeTrial theme={theme} handleFreeTrial={handleFreeTrial} loadData={loadData} />
          <div className='faq-section'>
            <p className='faq-header'>Frequently Asked Questions</p>
            <div className='grid grid-two--cols'>
              {faqData.map((item, index) => (
                <div>
                  <Accordion
                    key={index}
                    expanded={expanded === `panel${index}`}
                    onChange={handleExpland(`panel${index}`)}
                    className={`accordianPannel ${theme === "light" ? "light" : "dark"}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <p className={`faq-question ${theme == "dark" && "dark"}`}>Q: {item.question}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className='faq-answer'>A: {item.answer.desc}</p>
                      {item.answer.example && <p className={`faq-example ${theme == "dark" && "dark"}`}>Example: {item.answer.example}</p>}
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section >
      {/* Transform business start */}
      < BusinessTransformSection theme={theme} />
      {/* Transform business end */}
    </>

  )
}

export default Plans