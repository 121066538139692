import {
  Box,
  FormControl,
  FormLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import vliv_logo from "../../assets/Vliv logo-white.png";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { notifyError, notifySuccess } from "../../constants/toastAlerts";
import OTPInput from "react-otp-input";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [cred, setCred] = useState({
    email: "",
    password: "",
  });

  const [forgotPasswordOtp, setForgotPasswordOtp] = useState("");
  const [forgotPasswordApiOtp, setForgotPasswordApiOtp] = useState("");
  const [isForgotPasswordOtp, setIsForgotPasswordOtp] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);

  //for setting new password
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const handleForgotOtpChange = (otp) => {
    setForgotPasswordOtp(otp);
    if (otp.length === 6 && forgotPasswordApiOtp !== otp) {
      notifyError("Invalid OTP");
    }
    if (forgotPasswordApiOtp == otp) {
      notifySuccess("OTP Validated");
      setIsOtpVerified(true);
      // forgotPasswordFunc();
    }
  };

  const resendForgotOtp = async () => {
    // empty the otp box
    setForgotPasswordOtp("");

    try {
      let res = await axios.post(
        "https://vliv.app/company/forgotpasswordotp",
        { email: cred.email }
      );
      if (res.data.otp) {
        setForgotPasswordApiOtp(res.data.otp);
        notifySuccess("Otp has been resended successfully");
      } else {
        setForgotPasswordApiOtp("");
        // setIsForgotPasswordOtp(false);
      }
    } catch (error) {
      console.log(error, "error");
      notifyError("Something Went Wrong..");
    }
  };

  const forgotPasswordFunc = async () => {
    if (!newPassword || !confirmNewPassword) {
      notifyError("All Fields are required");
      return;
    } else if (newPassword !== confirmNewPassword) {
      notifyError("Password Mismatched");
      return;
    }
    try {
      let res = await axios.post(
        "https://vliv.app/company/updatepassword",
        { email: cred.email, password: newPassword }
      );
      if (res.data.message == "Password updated succesfully") {
        notifySuccess("Password Changed,Now Login Using New Password");
        setTimeout(() => {
          navigate("/frontend/login");
        }, 1000);
      }
    } catch (error) {
      console.log(error, "error");
      notifyError("Something Went Wrong..");
    }
  };

  // const updateNewPassword = async () => {
  //   if (!cred.email || !newPassword || !confirmNewPassword) {
  //     notifyError("All Fields are required");
  //     return;
  //   } else if (newPassword !== confirmNewPassword) {
  //     notifyError("Password Mismatched");
  //     return;
  //   } else {
  //     try {
  //       let res = await axios.post(
  //         "https://vliv.app/company/forgotpasswordotp",
  //         { email: cred.email }
  //       );
  //       if (res.data.otp) {
  //         setForgotPasswordApiOtp(res.data.otp);
  //         setIsForgotPasswordOtp(true);
  //       } else {
  //         setForgotPasswordApiOtp("");
  //         setIsForgotPasswordOtp(false);
  //       }
  //     } catch (error) {
  //       console.log(error, "error");
  //       notifyError(error.response.data.message);
  //     }
  //   }
  // };

  const sendOtpMail = async () => {
    if (!cred.email) {
      notifyError("Email is required");
      return;
    } else {
      try {
        let res = await axios.post(
          "https://vliv.app/company/forgotpasswordotp",
          { email: cred.email }
        );
        if (res.data.otp) {
          setForgotPasswordApiOtp(res.data.otp);
          setIsForgotPasswordOtp(true);
        } else {
          setForgotPasswordApiOtp("");
          setIsForgotPasswordOtp(false);
        }
      } catch (error) {
        console.log(error, "error");
        notifyError(error.response.data.message);
      }
    }
  };

  return (
    <>
      <Box className="signin-main">
        <Box
          className="signin-left"
          sx={{
            width: { xs: "100%", md: "35%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box textAlign="center">
            <img src={vliv_logo} alt="logo" className="logo_img" />
            <p>Sign in or Create an Account</p>
          </Box>
        </Box>
        <Box
          className="signin-right"
          width={"60%"}
          // height={"100%"}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            width="100%"
            maxWidth="430px"
            p={3}
            boxShadow={3}
            borderRadius={4}
            bgcolor="white"
          >
            {isOtpVerified ? (
              <Box>
                <Typography
                  component="h1"
                  variant="h4"
                  fontWeight={"700"}
                  mb={1.5}
                >
                  Update Your Password
                </Typography>
                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={{ margin: "0.6rem" }}
                  size="small"
                >
                  <FormLabel sx={{ fontSize: "1.45rem", my: 0.7 }}>
                    New Password
                  </FormLabel>
                  <OutlinedInput
                    id="password"
                    placeholder="Enter New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    //   error={newPassword && showEmptyError}
                    sx={{
                      borderRadius: "25px",
                      fontSize: "1.5rem",
                      padding: 0.5,
                    }}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={{ margin: "0.6rem" }}
                  size="small"
                >
                  <FormLabel sx={{ fontSize: "1.45rem", my: 0.7 }}>
                    Confirm New Password
                  </FormLabel>
                  <OutlinedInput
                    id="password"
                    placeholder="Confirm New Password"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    //   error={!confirmNewPassword && showEmptyError}
                    color={
                      newPassword && newPassword == confirmNewPassword
                        ? "success"
                        : "primary"
                    }
                    sx={{
                      borderRadius: "25px",
                      fontSize: "1.5rem",
                      padding: 0.5,
                    }}
                  />
                </FormControl>
                <div
                  className="button-container"
                  style={{
                    margin: "2rem 0rem 1rem 0rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    className="commonBtn"
                    onClick={forgotPasswordFunc}
                    style={{ width: "65%", textAlign: "center" }}
                    // disabled={!confirmNewPassword || !newPassword}
                  >
                    Change Password
                  </button>
                </div>
              </Box>
            ) : isForgotPasswordOtp ? (
              <>
                <Box>
                  <Typography
                    sx={{ fontSize: "1.45rem", my: 0.7 }}
                    className="form-label"
                  >
                    Enter the code we just sent to
                  </Typography>
                  <Typography
                    sx={{ fontSize: "1.45rem", my: 0.7 }}
                    className="form-label"
                  >
                    {cred.email}
                  </Typography>
                  <Box className="otp-input-container" my={2}>
                    <OTPInput
                      value={forgotPasswordOtp}
                      onChange={handleForgotOtpChange}
                      numInputs={6}
                      isInputNum={true}
                      shouldAutoFocus={true}
                      renderInput={(props) => <input {...props} />}
                    />
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      gap={3}
                    >
                      <Typography
                        variant="h5"
                        component={"h1"}
                        className="cursor-pointer"
                        onClick={() => {
                          setForgotPasswordApiOtp("");
                          setForgotPasswordOtp("");
                          setIsForgotPasswordOtp(false);
                        }}
                      >
                        Back
                      </Typography>
                      <Typography
                        variant="h5"
                        component={"h1"}
                        className="cursor-pointer"
                        onClick={resendForgotOtp}
                      >
                        Resend Code
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box>
                  <Typography
                    component="h1"
                    variant="h4"
                    fontWeight={"700"}
                    mb={1.5}
                  >
                    Veriy Email
                  </Typography>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{ margin: "0.6rem" }}
                    size="small"
                  >
                    <FormLabel
                      sx={{ fontSize: "1.45rem", my: 0.7 }}
                      className="form-label"
                    >
                      Email Address
                    </FormLabel>
                    <OutlinedInput
                      name="email"
                      value={cred.email}
                      onChange={(e) =>
                        setCred({ ...cred, email: e.target.value })
                      }
                      placeholder="Enter Your Mail Address"
                      sx={{
                        borderRadius: "25px",
                        fontSize: "1.8rem",
                        padding: 0.5,
                      }}
                      // error={!cred.email && showEmptyError}
                    />
                  </FormControl>
                  <div
                    className="button-container"
                    style={{
                      margin: "2rem 0rem 1rem 0rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      className="commonBtn"
                      onClick={sendOtpMail}
                      style={{ width: "65%", textAlign: "center" }}
                      // disabled={!confirmNewPassword || !newPassword}
                    >
                      Send OTP
                    </button>
                  </div>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ForgotPassword;
