import { Box, Button, Modal, Switch, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Accessmanagement.css";
import axios from "axios";
import ViewModal from "./ViewModal";

function AccessManagement() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null); // State to store selected row data
 const [refresh, setRefresh] = useState(false);

  const handleTrigger = () => {
    setIsOpenModal(!isOpenModal);
  };
  const handleRowClick = (params) => {
    setSelectedRow(params.row); // Store the selected row data
    handleTrigger(); // Open the modal
  };

  useEffect(() => {
    axios
      .post(`https://vliv.app/company/getcompany`)
      .then((res) => {
        
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, [refresh]);

  const columns = [
    {
      field: "id",
      headerName: "Org Id",
      flex: 1,
    },
    {
      field: "company",
      headerName: "Organization Name",
      flex: 1,
    },
    {
      field: "planname",
      headerName: "Plan",
      flex: 1,
      renderCell: (params) => (
        <Box style={{}}>
          <Typography
            sx={{
              backgroundColor:
                params.value === "Premium"
                  ? "#E6DFF7"
                  : params.value === "Basic"
                  ? "#CFE9EB"
                  : params.value === "Standard"
                  ? "#F3E0D5"
                  : "", // Yellow for Premium, Grey for others
              color:
                params.value === "Premium"
                  ? "#8468BD"
                  : params.value === "Basic"
                  ? "#5EA2A5"
                  : params.value === "Standard"
                  ? "#BB6535"
                  : "", // Black text color
              padding: "4px 8px",
              borderRadius: "4px",
              display: "inline-block",
              fontSize: "12px",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "plansubscription",
      headerName: "Plan Type",
      flex: 1,
    },
    {
      field: "purchasedate",
      headerName: "Subscribed Date",
      flex: 1,
    },
    {
      field: "expiredate",
      headerName: "Renewal Date",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        // console.log("Row planstatus:", params.row.planstatus); 
        return (
          <Switch
            checked={params.row.planstatus === "active"}
            onClick={(e) => e.stopPropagation()} // Stop event propagation
            onChange={(e) =>
              handleStatusChange(
                params.row.id,
                e.target.checked ? "active" : "inactive"
              )
            }
            color="primary"
          />
        );
      },
    },
  ];

  // console.log(data, "data")

  const filterData = () => {
    return data.length > 0 && data.filter((item) =>
      searchValue
        ? Object.values(item).some((value) =>
          value && value
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchValue.toLowerCase().trim())
          )
        : true
    );
  };

  const rows = filterData();
  // const rows = data;

  const handleStatusChange = (id, planstatus) => {
    // console.log(id, planstatus);
    axios.post(`https://vliv.app/company/updateplanstatus`, {id, planstatus})
    .then((res)=>{
      // console.log(res.data)
      setRefresh(!refresh)
    })
    .catch((err)=>console.log(err))
  };


  return (
    <Box maxWidth="xl">
      <Typography variant="h6" gutterBottom></Typography>
      <Box style={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}>
        <TextField
          // label="Search"
          placeholder="Search"
          variant="outlined"
          size="md"
          sx={{ marginBottom: 2, width: 400 }}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </Box>
      <Box>
        <DataGrid
          // sx={{
          //   "& .MuiDataGrid-columnHeaders": {
          //     backgroundColor: "rgb(216, 216, 216)",
          //   },
          //   "& .MuiDataGrid-columnHeaderTitle": {
          //     color: "black",
          //     fontWeight: "bold",
          //   },
          //   "& .MuiDataGrid-columnHeader": {
          //     backgroundColor: "rgb(216, 216, 216)",
          //   },
          //   "& .MuiDataGrid-columnHeader--sortable:hover": {
          //     backgroundColor: "rgb(200, 200, 200)",
          //   },
          // }}
          rows={rows}
          columns={columns}
          autoHeight
          onRowClick={handleRowClick} // Trigger modal on row click
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
        />
      </Box>

      <Modal
        open={isOpenModal}
        onClose={handleTrigger}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: "150vh",
            height: "90vh",
          }}
        >
          <ViewModal handleTrigger={handleTrigger} rowData={selectedRow} setRefresh={setRefresh} refresh={refresh}/>
        </Box>
      </Modal>
    </Box>
  );
}

export default AccessManagement;
