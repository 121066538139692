import axios from "axios";
import { PLANS } from "../actionTypes";
import { vlivBackendApi } from "../../constants/api";

//Acion dispatcher for user login
export const plans = (backendData) => async (dispatch) => {
    try {
        const res = await axios.post(`${vlivBackendApi}/user/getloginuser`, backendData, {withCredentials:true});
        if (res.data) {
            dispatch({ type: PLANS, payload: {...res.data.data, daysleft:res.data.daysleft} });

        } else {
            dispatch({ type: PLANS, payload: {...res.data.data, daysleft:res.data.daysleft} });
        }
    } catch (error) {
        console.log(`Error in adding user data ${error}`);
    }
};