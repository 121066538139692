import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import { Box } from "@mui/material";
import Navbar from "./Navbar";
import NavbarBackend from "../Backend/Navbar/NavbarBackend";

function BackendLayout() {
  return (
    <>
      <Box className="root-main">
        <Box>
          {" "}
          {/* <Navbar /> */}
          <NavbarBackend />
        </Box>
        <Box  sx={{marginLeft:3}}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
}

export default BackendLayout;
